export enum UserDetailsLabel {
	lyteIdIdentifier = "Lyte Id Identifier",
	fullName = "Full Name",
	firstName = "First Name",
	lastName = "Last Name",
	mobileCountryCode = "Mobile Country Code",
	mobileNumber = "Mobile Number",
	email = "Email",
	countryCode = "Country Code",
	addressLine1 = "Address Line 1",
	addressLine2 = "Address Line 2",
	postalCode = "Postal Code",
	freelancerRole = "Freelancer Role",
	campaignSource = "Campaign Source",
	referralCode = "Referral Code",
	referrerCode = "Referrer Code",
	referrerCode2 = "Referrer Code 2",
}

export enum LytePayDetailsLabel {
	userType = "User Type",
	fullyOnboarded = "Fully Onboarded",
	isMobileVerified = "Mobile Verified",
	isEmailVerified = "Email Verified",
	isKycApproved = "Kyc Approved",
	hasApprovedBankAccount = "Approved Bank Account",
	invoiceNumberPrefix = "Invoice Number Prefix",
	invoiceNumberSuffix = "Invoice Number Suffix",
}

export enum UserBankAccount {
	accountHolderName = "Account Holder Name",
	accountNumber = "Account Number",
	bankCode = "Bank Code",
	bankName = "Bank Name",
	currencyCode = "Currency Code",
	status = "Status",
}
