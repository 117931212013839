import {
	useMutation,
	UseMutationOptions,
	useQuery,
	useQueryClient,
} from "react-query";
import toast from "react-hot-toast";
import useApi from "../../../hooks/useApi";

export const useGetCashoutByRef = (ref: string) => {
	const api = useApi();
	return useQuery(
		["cashout", ref],
		async () => {
			const { data } = await api({
				method: "get",
				url: `/admin/cashouts/${ref}`,
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api && !!ref,
		}
	);
};

export const usePostCashoutCompleted = (
	opts?: Omit<UseMutationOptions<any, unknown, string, unknown>, "mutationFn">
) => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation(
		async (ref: string) => {
			const promise = api({
				method: "put",
				url: `/admin/cashouts/completed/${ref}`,
				data: { refNumber: ref },
			});

			toast.promise(promise, {
				loading: "Updating status...",
				success: `Cashout ${ref} updated`,
				error: "Error updating status",
			});

			const { data } = await promise;
			return data;
		},
		{
			onSettled: (data, err, ref) => {
				queryClient.invalidateQueries(`["cashout", ${ref}]`);
				queryClient.invalidateQueries(`cashouts`);
			},
			...opts,
		}
	);
};
