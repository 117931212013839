import styled from "styled-components";
import PropTypes from "prop-types";

const Button = styled.button`
	padding: 1.5rem 4rem;
	background-color: var(--lyte-blue);
	color: var(--lyte-white);
	font-weight: 700;
	margin: 0 auto 1.5rem;
	border-radius: 8px;
	width: 100%;
	height: 100%;
`;

const Notification = styled.div`
	border-radius: 50%;
	background-color: red;
	min-width: 50px;
	height: auto;
	color: #fff;
	font-weight: 700;
	padding: 0.5rem 0.75rem;
	border: 5px solid #fff;
	position: absolute;
	right: -20px;
	top: -20px;
	text-align: center;
`;

const RelativeBox = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const LyteMenuButton = ({ children, notification, ...props }) => {
	return (
		<RelativeBox>
			{notification ? <Notification>{notification}</Notification> : null}
			<Button className="btn" {...props}>
				{children}
			</Button>
		</RelativeBox>
	);
};

export default LyteMenuButton;

LyteMenuButton.propTypes = {
	children: PropTypes.any,
	notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
