import EmailLogDetails from "../../views/emaillogs/detail/EmailLogDetails";
import EmailLogSummary from "../../views/emaillogs/EmailLogSummary";

import type { RouteProps } from "react-router-dom";

export const routes: RouteProps[] = [
	{
		path: "/email-logs",
		component: EmailLogSummary,
	},
	{
		path: "/email-logs/:id",
		component: EmailLogDetails,
		exact: true,
	},
];
