import {
	faUserAlt,
	faRocket,
	faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Card, Button, Col } from "react-bootstrap";
import { formatCurrency } from "../../../modules/helpers";
import { useHistory } from "react-router-dom";
import { UseGetSummary } from "../../../hooks/nerveDashboard";

export const DashboardSummary = () => {
    const history = useHistory();
    const { data: summary } = UseGetSummary();

	return !summary ? (
		"Loading..."
	) : (
		<>
			<Row>
				<Col xs={12} md={4} className="p-3 card-summary-container">
					<Card border="secondary" className="p-4">
						<Card.Title>
							<FontAwesomeIcon icon={faUserAlt} /> User
						</Card.Title>
						<Card.Body className="mt-3 mb-3">
							<Row className="p-2">
								<Col xs={6}>
									<p>Total</p>
									<strong>{summary.userSummary.total || 0}</strong>
								</Col>
							</Row>
						</Card.Body>
						<Card.Footer className="text-center">
							<Button
								variant="primary"
								onClick={() => history.push("/nerve/user")}
							>
								View Details
							</Button>
						</Card.Footer>
					</Card>
				</Col>
				<Col xs={12} md={4} className="p-3 card-summary-container">
					<Card border="secondary" className="p-4">
						<Card.Title>
							<FontAwesomeIcon icon={faRocket} /> Boost
						</Card.Title>
						<Card.Body className="mt-3 mb-3">
							<Row className="p-2">
								<Col xs={6}>
									<p>Total</p>
									<strong>{summary.boostSummary.total || 0}</strong>
								</Col>
								<Col xs={6}>
									<p>Value</p>
									<strong>
										{formatCurrency(summary.boostSummary.value) || 0}
									</strong>
								</Col>
							</Row>
							<Row className="p-2">
								<Col xs={6}>
									<p>Ongoing</p>
									<strong>{summary.boostSummary.totalOngoing || 0}</strong>
								</Col>
								<Col xs={6}>
									<p>Value</p>
									<strong>
										{formatCurrency(summary.boostSummary.valueOngoing) || 0}
									</strong>
								</Col>
							</Row>
							<Row className="p-2">
								<Col xs={6}>
									<p>Finished</p>
									<strong>{summary.boostSummary.totalFinished || 0}</strong>
								</Col>
								<Col xs={6}>
									<p>Value</p>
									<strong>
										{formatCurrency(summary.boostSummary.valueFinished) || 0}
									</strong>
								</Col>
							</Row>
						</Card.Body>
						<Card.Footer className="text-center">
							<Button
								variant="primary"
								onClick={() => history.push("/nerve/boost")}
							>
								View Details
							</Button>
						</Card.Footer>
					</Card>
				</Col>
				<Col xs={12} md={4} className="p-3 card-summary-container">
					<Card border="secondary" className="p-4">
						<Card.Title>
							<FontAwesomeIcon icon={faServer} /> Boost Product
						</Card.Title>
						<Card.Body className="mt-3 mb-3">
							<Row className="p-2">
								<Col xs={6}>
									<p>Total</p>
									<strong>{summary.boostProductSummary.total || 0}</strong>
								</Col>
							</Row>
							<Row className="p-2">
								<Col xs={6}>
									<p>Current</p>
									<strong>
										{summary.boostProductSummary.current || 0}
									</strong>
								</Col>
							</Row>
							<Row className="p-2">
								<Col xs={6}>
									<p>Finished</p>
									<strong>
										{summary.boostProductSummary.finished || 0}
									</strong>
								</Col>
							</Row>
						</Card.Body>
						<Card.Footer className="text-center">
							<Button
								variant="primary"
								onClick={() => history.push("/nerve/boostproduct")}
							>
								View Details
							</Button>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</>
	);
};
