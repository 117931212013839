import InvoiceTemplate from "./InvoiceTemplate/InvoiceTemplate";
import InvoiceHeader from "./InvoiceTemplate/InvoiceHeader";
import InvoiceSubheader from "./InvoiceTemplate/InvoiceSubheader";
import InvoiceTable from "./InvoiceTemplate/InvoiceTable";
import InvoiceFooter from "./InvoiceTemplate/InvoiceFooter";

import AssignmentClause from "../clauses/Assignment";
import LatePaymentClause from "../clauses/LatePayment";

const _InvoiceTemplate = ({ invoice }) => {
	return (
		<InvoiceTemplate>
			<InvoiceHeader
				invoiceNumber={invoice.invoiceNumber}
				projectTitle={invoice.projectTitle}
				issuedDateTime={invoice.issuedDateTime}
				invoiceReference={invoice.invoiceReference}
			/>
			<InvoiceSubheader
				invoiceClient={invoice.invoiceClient}
				addressLine1={invoice.addressLine1}
				addressLine2={invoice.addressLine2}
				fullName={invoice.fullName}
				country={invoice.country}
				countryCode={invoice.countryCode}
				postalCode={invoice.postalCode}
				mobileCountryCode={invoice.mobileCountryCode}
				mobileNumber={invoice.mobileNumber}
				email={invoice.email}
			/>
			<InvoiceTable
				invoiceItems={invoice.invoiceItems}
				totalAmount={invoice.totalAmount}
				totalAmountDue={invoice.totalAmountDue}
				totalPaidAmount={invoice.totalPaidAmount}
			/>
			<InvoiceFooter
				issuedDateTime={invoice.issuedDateTime}
				paymentDue={invoice.paymentDue}
			>
				<AssignmentClause />
				<LatePaymentClause invoice={invoice} />
			</InvoiceFooter>
		</InvoiceTemplate>
	);
};

_InvoiceTemplate.Header = InvoiceHeader;
_InvoiceTemplate.Subheader = InvoiceSubheader;
_InvoiceTemplate.Table = InvoiceTable;

export default _InvoiceTemplate;
