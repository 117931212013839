import { useState } from "react";
import { useAsyncDebounce } from "react-table";

const GlobalFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	searchPlaceholder = "Search...",
}) => {
	// const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
		return value;
	}, 200);

	return (
		<span>
			<input
				className="form-control form-control-sm py-1"
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={searchPlaceholder}
			/>
		</span>
	);
};

export default GlobalFilter;
