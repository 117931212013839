export const formatCurrency = (number) => {
	return new Intl.NumberFormat("en-SG", {
		style: "currency",
		currency: "SGD",
	}).format(number);
};

export const formatNumber = (number, minimumFractionDigits = 2) =>
	new Intl.NumberFormat("en-SG", {
		minimumFractionDigits,
	}).format(number);
