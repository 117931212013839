import { AxiosError } from "axios";
import toast from "react-hot-toast";
import {
	useMutation,
	UseMutationOptions,
	useQuery,
	UseQueryResult,
} from "react-query";

import useApi from "../../hooks/useApi";

import type { IQueryOptions, IResponse } from "../../interfaces/Global";
import type { TInvoicesData } from "./Invoices.type";

export const useInvoiceMarkPaid = (
	opts?: Omit<
		UseMutationOptions<
			any,
			unknown,
			{
				invoiceReference: string;
			},
			unknown
		>,
		"mutationFn"
	>
) => {
	const api = useApi();
	return useMutation(
		async ({ invoiceReference }: { invoiceReference: string }) => {
			const promise = api({
				method: "put",
				url: "admin/invoices/paid/" + invoiceReference,
				data: { refNumber: invoiceReference },
			});
			toast.promise(promise, {
				loading: `Updating ${invoiceReference} to Paid`,
				success: `${invoiceReference} updated to Paid`,
				error: `Error updating ${invoiceReference} to Paid`,
			});
			const { data } = await promise;
			return data;
		},
		{
			...opts,
		}
	);
};

export const useInvoices = (
	params: IQueryOptions,
	options?: object
): UseQueryResult<IResponse<TInvoicesData[]>, AxiosError> => {
	const api = useApi();

	return useQuery(
		["invoices", params],
		async () => {
			const { data } = await api.get(`/admin/invoices/v2`, { params });
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...options,
		}
	);
};

export const useExportInvoice = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: "admin/invoices/export/",
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
