import toast from "react-hot-toast";
import {
	useMutation,
	UseMutationOptions,
	useQuery,
	UseQueryOptions,
} from "react-query";
import useApi from "../../../hooks/useApi";

interface UpdateNotesVm {
	lyteId: string;
	notes: string;
	userGroupIds: string[] | null;
}

export const useUserUpdateNotes = (
	opts?: Omit<
		UseMutationOptions<any, unknown, UpdateNotesVm, unknown>,
		"mutationFn"
	>
) => {
	const api = useApi();
	return useMutation(
		async ({ lyteId, notes, userGroupIds }: UpdateNotesVm) => {
			const promise = api({
				method: "put",
				url: `/admin/users/${lyteId}`,
				data: { notes, userGroupIds },
			});

			toast.promise(promise, {
				loading: "Updating user...",
				success: "User updated",
				error: "Error updating user",
			});

			const { data } = await promise;
			return data;
		},
		{
			...opts,
		}
	);
};

export const useUserDetails = (
	lyteId: string,
	opts?: Omit<
		UseQueryOptions<any, unknown, any, string[]>,
		"queryKey" | "queryFn"
	>
) => {
	const api = useApi();
	return useQuery(
		["user", lyteId],
		() => api.get(`/admin/users/${lyteId}`).then((res) => res.data),
		{ ...opts, keepPreviousData: true, enabled: !!lyteId }
	);
};

export const useUserGroups = () => {
	const api = useApi();
	return useQuery(
		`groups`,
		() => api.get("/admin/users/groups").then((res) => res.data),
		{ keepPreviousData: true }
	);
};
