export const STATUS_FILTER_OPTIONS = [
	{
		id: "issued",
		label: "Issued",
		value: "Issued",
	},
	{
		id: "paid",
		label: "Paid",
		value: "Paid",
	},
	{
		id: "partially_paid",
		label: "Partially Paid",
		value: "PartiallyPaid",
	},
	{
		id: "overdue",
		label: "Overdue",
		value: "Overdue",
	},
];
