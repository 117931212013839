import type { RouteProps } from "react-router-dom";
import SmsLogDetails from "../../views/smslogs/detail/SmsLogDetails";
import SmsLogSummary from "../../views/smslogs/SmsLogSummary";

export const routes: RouteProps[] = [
	{
		path: "/sms-logs",
		component: SmsLogSummary,
	},
	{
		path: "/sms-logs/:id",
		component: SmsLogDetails,
		exact: true,
	},
];
