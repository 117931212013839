import * as dayjs from "dayjs";
import { dateFormat } from "../../../utils";

export const columns = [
	{
		accessor: "lyte_global_id",
		Header: "Lyte Id",
	},
	{
		accessor: "first_name",
		Header: "First Name",
		Cell: ({ value }) => (value ? value : "-"),
	},
	{
		accessor: "last_name",
		Header: "Last Name",
		Cell: ({ value }) => (value ? value : "-"),
	},
	{
		accessor: "registration_completed_at",
		Header: "Registration Completed",
		Cell: ({ value }) => (value ? dateFormat(new Date(value)) : "-"),
	},
];
