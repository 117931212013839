import Users from "../../views/users/Users";
import UserDetails from "../../views/users/detail/UserDetails";

import type { RouteProps } from "react-router-dom";

export const routes: RouteProps[] = [
	{
		path: "/users",
		component: Users,
		exact: true,
	},
	{
		path: "/users/:id",
		component: UserDetails,
		exact: true,
	},
];
