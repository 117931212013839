import { Field } from "formik";
import styled from "styled-components";

const LyteFormik = () => {
	return <form></form>;
};

const Label = styled.label`
	font-weight: 800;
	text-overflow: ellipsis;
	margin-bottom: 0.5rem;
`;

const Unit = styled.span`
	position: absolute;
	top: 39px;
	left: 10px;
`;

const GridCol = ({ children, props }) => (
	<div
		className={`
    g-col-12 
    g-col-xs-${props.xs || 12} 
    g-col-sm-${props.sm || 12}  
    g-col-md-${props.md || 6} 
    g-col-lg-${props.lg || 4} 
    g-col-xl-${props.xl || 3}
    `}
		style={{ position: "relative" }}
	>
		{children}
	</div>
);

LyteFormik.Grid = ({ children }) => <div className="grid">{children}</div>;

LyteFormik.Input = ({ children, label, ...props }) => (
	<GridCol>
		{label && <Label htmlFor={props.name}>{label}</Label>}
		<Field className="form-control" name={props.name} {...props} />
	</GridCol>
);

LyteFormik.Currency = ({ children, label, ...props }) => (
	<GridCol {...props}>
		{label && <Label htmlFor={props.name}>{label}</Label>}
		<Field
			className="form-control"
			style={{ paddingLeft: "25px" }}
			type="number"
			name={props.name}
			{...props}
		/>
		<Unit>$</Unit>
	</GridCol>
);

LyteFormik.Upload = ({ children, label, ...props }) => (
	<GridCol>
		{label && <Label htmlFor={props.name}>{label}</Label>}
		<Field className="form-control" type="file" name={props.name} {...props} />
	</GridCol>
);

export default LyteFormik;
