import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Loading } from "../../../components/common";
import PageTitle from "../../../components/common/LyteTitle";
import { Currency, dateFormat } from "../../../utils";
import { useGetBoostByRef } from "./BoostDetails.service";

const Info = ({
	label,
	value,
	...rest
}: {
	label: string;
	value?: React.ReactNode;
}) => (
	<Col xs={12} md={6} className="p-3" {...rest}>
		<strong>{label}: </strong>
		<p>{value ? value : "null"}</p>
	</Col>
);

const BoostDetails = () => {
	const { boostRef } = useParams<{ boostRef: string }>();
	const { data } = useGetBoostByRef(boostRef);

	return !boostRef && !data ? (
		<Loading />
	) : (
		<>
			<PageTitle>Boost Details {boostRef}</PageTitle>
			<Card bg="light" className="my-5">
				<Card.Body>
					<Row>
						<Info label="User Id" value={data?.userId} />
						<Info label="User Name" value={data?.userName} />
						<Info label="Invoice Ref Number" value={data?.invoiceRefNumber} />
						<Info label="Boost Id" value={data?.boostId} />
						<Info label="Boost Ref Number" value={data?.boostRefNumber} />
						<Info
							label="Boost Starting Date"
							value={dateFormat(data?.boostStartingDate, "DD MMM YYYY")}
						/>
						<Info
							label="Boost End Date"
							value={dateFormat(data?.boostEndDate, "DD MMM YYYY")}
						/>
						<Info
							label="Boost Percentage (%)"
							value={data?.boostPercentage + "%"}
						/>
						<Info
							label="Boost Amount"
							value={`${data?.currency} ${Currency(data?.boostAmount || 0, {
								symbol: "",
							})}`}
						/>
						<Info
							label="Auto Reboost"
							value={data?.autoReboost ? "TRUE" : "FALSE"}
						/>
						<Info label="Status" value={data?.status} />
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default BoostDetails;
