import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import {
	Form as BSForm,
	Modal,
	Button,
	Row,
	Col,
	ButtonGroup,
	ListGroup,
} from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { Loading } from "../../../components/common";
import useInvoiceDetails from "../../../hooks/useInvoiceDetails";
import InvoiceTemplate from "../../../components/invoices/InvoiceTemplate";
import StatusBadge from "../../../components/StatusBadge";
import { formatCurrency } from "../../../modules/helpers";
import { PaymentSchema } from "../Invoices.validation";
import { useMutationAddPayment } from "./InvoiceDetails.service";

import type { TInvoicesParams } from "../Invoices.type";
import type {
	TInvoicesDetailsAddPaymentPayload,
	TStatusMode,
} from "./InvoicesDetails.type";

const InvoiceDetails = () => {
	const { invoiceReference } = useParams<TInvoicesParams>();
	const { data: invoice } = useInvoiceDetails(invoiceReference);
	const [showModal, setShowModal] = useState(false);
	const [statusMode, setStatusMode] = useState<TStatusMode>("add_payment");
	const queryClient = useQueryClient();

	const { mutateAsync: addPayment } = useMutationAddPayment(
		{
			invoiceReference,
		},
		{
			onSuccess: () => {
				setShowModal(false);
				return queryClient.invalidateQueries(`invoice${invoiceReference}`);
			},
		}
	);

	return !invoice ? (
		<Loading />
	) : (
		<>
			<Row className="mt-5">
				<Col xs="12" md="7">
					<BSForm>
						<h4 className="mb-3">Invoice Details</h4>
						<ListGroup>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="invoiceCreationType">
									<BSForm.Label column sm="3">
										<strong>Invoice Type</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={invoice.invoiceCreationType || "-"}
											as={({ defaultValue }: { defaultValue: string }) => (
												<StatusBadge
													variant="light"
													text="dark"
													className="w-auto px-4"
													status={defaultValue}
												/>
											)}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="projectTitle">
									<BSForm.Label column sm="3">
										<strong>Project Name</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={invoice.projectTitle || "-"}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="invoiceReference">
									<BSForm.Label column sm="3">
										<strong>Invoice Ref.</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={invoice.invoiceReference || "-"}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="invoiceStatus">
									<BSForm.Label column sm="3">
										<strong>Late Fee Rate</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={
												invoice?.lateFeeRate
													? `${+invoice.lateFeeRate * 100}%`
													: "0%"
											}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="invoiceStatus">
									<BSForm.Label column sm="3">
										<strong>Invoice Status</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={invoice.status || "-"}
											as={({ defaultValue }: { defaultValue: string }) => (
												<StatusBadge
													variant="light"
													text="dark"
													className="w-auto px-4"
													status={defaultValue}
												/>
											)}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="autoReboost">
									<BSForm.Label column sm="3">
										<strong>Overdue</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={invoice.isOverdue ? "yes" : "no"}
											as={({ defaultValue }: { defaultValue: string }) => (
												<StatusBadge
													variant="light"
													text="dark"
													className="w-auto px-4"
													status={defaultValue}
												/>
											)}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							{invoice.statusChangeReason && (
								<ListGroup.Item>
									<BSForm.Group as={Row} controlId="statusChangeReason">
										<BSForm.Label column sm="3">
											<strong>Update Reason</strong>
										</BSForm.Label>
										<Col sm="9">
											<p className="mt-2">{invoice.statusChangeReason}</p>
										</Col>
									</BSForm.Group>
								</ListGroup.Item>
							)}
							{invoice.statusChangeOtherReason && (
								<ListGroup.Item>
									<BSForm.Group as={Row} controlId="statusChangeOtherReason">
										<BSForm.Label column sm="3">
											<strong>Other Reason</strong>
										</BSForm.Label>
										<Col sm="9">
											<p className="mt-2">{invoice.statusChangeOtherReason}</p>
										</Col>
									</BSForm.Group>
								</ListGroup.Item>
							)}
						</ListGroup>
						<h4 className="mt-4 mb-3">Boost Details</h4>
						<ListGroup>
							{invoice.boostAmount !== null && (
								<ListGroup.Item>
									<BSForm.Group as={Row} controlId="boostAmount">
										<BSForm.Label column sm="3">
											<strong>Boost Amount</strong>
										</BSForm.Label>
										<Col sm="9">
											<BSForm.Control
												plaintext
												readOnly
												defaultValue={
													formatCurrency(invoice.boostAmount) || "-"
												}
											/>
										</Col>
									</BSForm.Group>
								</ListGroup.Item>
							)}
							{invoice.boostStatus !== null && (
								<ListGroup.Item>
									<BSForm.Group as={Row} controlId="boostStatus">
										<BSForm.Label column sm="3">
											<strong>Boost Status</strong>
										</BSForm.Label>
										<Col sm="9">
											<BSForm.Control
												plaintext
												readOnly
												defaultValue={invoice.boostStatus || "-"}
												as={({ defaultValue }: { defaultValue: string }) => (
													<StatusBadge
														variant="light"
														text="dark"
														className="w-auto px-4"
														status={defaultValue}
													/>
												)}
											/>
										</Col>
									</BSForm.Group>
								</ListGroup.Item>
							)}
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="boostPercentage">
									<BSForm.Label column sm="3">
										<strong>Boost Percentage</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={`${invoice.boostPercentage * 100}%` || "-"}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="boostDuration">
									<BSForm.Label column sm="3">
										<strong>Boost Duration</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={`${invoice.boostDuration} days` || "-"}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="boostReturn">
									<BSForm.Label column sm="3">
										<strong>Boost Return</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={`${invoice.boostReturn * 100}%` || "-"}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
							<ListGroup.Item>
								<BSForm.Group as={Row} controlId="autoReboost">
									<BSForm.Label column sm="3">
										<strong>Auto-reboost</strong>
									</BSForm.Label>
									<Col sm="9">
										<BSForm.Control
											plaintext
											readOnly
											defaultValue={invoice.autoReboost ? "yes" : "no"}
											as={({ defaultValue }: { defaultValue: string }) => (
												<StatusBadge
													variant="light"
													text="dark"
													className="w-auto px-4"
													status={defaultValue}
												/>
											)}
										/>
									</Col>
								</BSForm.Group>
							</ListGroup.Item>
						</ListGroup>
					</BSForm>
				</Col>
				<Col xs="12" md={{ span: 3, offset: 2 }}>
					<br className="d-block d-md-none" />
					<ButtonGroup vertical className="w-100 mt-md-0 mt-xs-5">
						{invoice.status !== "rejected" &&
							invoice.status !== "cancelled" &&
							invoice.status !== "paid" &&
							invoice.status !== "draft" && (
								<Button
									onClick={() => {
										setShowModal(true);
										setStatusMode("update_to_paid");
									}}
									variant="outline-primary"
									size="lg"
								>
									Update to Paid
								</Button>
							)}

						{invoice.status !== "rejected" &&
							invoice.status !== "cancelled" &&
							invoice.status !== "paid" &&
							invoice.status !== "draft" && (
								<Button
									onClick={() => {
										setShowModal(true);
										setStatusMode("add_payment");
									}}
									variant="outline-primary"
									size="lg"
								>
									{" "}
									Add Payment
								</Button>
							)}
					</ButtonGroup>
				</Col>
			</Row>
			<Modal
				show={showModal}
				onHide={() => {
					setShowModal(false);
				}}
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{statusMode === "add_payment"
							? `Add Payment: ${invoiceReference}`
							: "Update to Paid"}
					</Modal.Title>
				</Modal.Header>
				<Formik
					initialValues={{
						paymentAmount: "",
						refNumber: invoice.invoiceReference,
					}}
					enableReinitialize
					validateOnBlur
					validateOnChange
					validationSchema={PaymentSchema}
					onSubmit={(
						values: TInvoicesDetailsAddPaymentPayload,
						{ setSubmitting }
					) => {
						setSubmitting(true);
						addPayment({
							...values,
							paymentAmount:
								statusMode === "add_payment"
									? values.paymentAmount
									: invoice.totalAmountDue,
						});
					}}
				>
					{({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => (
						<Form>
							<Modal.Body>
								{statusMode === "update_to_paid" &&
									invoice.status !== "partially_paid" && (
										<div>
											Are you sure you want to change the status of this invoice
											with reference number{" "}
											<strong>{invoice.invoiceReference}</strong> from{" "}
											{invoice.status?.replace("_", " ")} to{" "}
											<strong>PAID</strong>.
										</div>
									)}
								{statusMode === "update_to_paid" &&
									invoice.status === "partially_paid" && (
										<div>
											<p>
												Are you sure you want to change the status of this
												invoice with reference number{" "}
												<strong>{invoice.invoiceReference}</strong> from{" "}
												{invoice.status?.replace("_", " ")} to{" "}
												<strong>PAID</strong>?{" "}
											</p>
										</div>
									)}
								{statusMode === "add_payment" && (
									<>
										<div className="form-row row">
											<BSForm.Group controlId="amount" className="col mb-3">
												<BSForm.Label className="d-flex justify-content-between align-items-center">
													<strong>
														<span>Payment Amount</span>
													</strong>
													<Button
														variant="link"
														className="p-0"
														onClick={() => {
															setFieldValue(
																"paymentAmount",
																invoice.totalAmountDue
															);
														}}
													>
														<small>Paid in full</small>
													</Button>
												</BSForm.Label>
												<Field
													id="paymentAmount"
													className="form-control"
													name="paymentAmount"
													type="number"
													placeholder="Enter payment amount"
													defaultValue={
														(statusMode as TStatusMode) === "update_to_paid" &&
														invoice.totalAmountDue
													}
												/>
												<ErrorMessage
													name="paymentAmount"
													component="small"
													className="text-danger"
												/>
											</BSForm.Group>
										</div>
										<div className="form-row">
											<small className="text-small">
												* Paying in full will automatically mark the invoice as
												PAID
											</small>
										</div>
									</>
								)}
							</Modal.Body>
							<Modal.Footer>
								<div className="d-flex justify-space-between">
									<Button
										style={{ background: "transparent", border: "none" }}
										className="text-danger w-50 btn btn-link"
										onClick={() => setShowModal(false)}
										disabled={isSubmitting}
									>
										Cancel
									</Button>
									<Button
										className="w-50"
										onClick={() => {
											handleSubmit();
										}}
										disabled={isSubmitting || values.paymentAmount === 0}
									>
										Confirm
									</Button>
								</div>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
			<InvoiceTemplate invoice={invoice} />
		</>
	);
};

export default InvoiceDetails;
