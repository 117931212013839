import { useQuery } from "react-query";
import useApi from "../../../hooks/useApi";

export const useGetPaymentByRef = (ref: string) => {
	const api = useApi();
	return useQuery(
		["payment", ref],
		async () => {
			const { data } = await api({
				method: "get",
				url: `/admin/payments/${ref}`,
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api && !!ref,
		}
	);
};
