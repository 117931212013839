import React from "react";
import { Page, Text, View, Document, Image, Font } from "@react-pdf/renderer";

import { advanceTableHeadRowArray, paymentTableHeadRowArray } from "./column";
import { PAYMENT_TYPE } from "./constant";
import { styles } from "./style";
import { reportDateFormat } from "../../../utils";
import Color from "../../../utils/colours";
import { formatCurrency } from "../../../modules/helpers";
import HelveticaBold from "../../../assets/fonts/helvetica-bold.ttf";

interface BillPdfProps {
	dataSource: any;
	contents: any;
}

Font.register({ family: "Custom-Bold", src: HelveticaBold });

const BillPdf: React.FC<BillPdfProps> = ({ dataSource, contents }) => {
	const advancesData =
		dataSource?.billLineItems?.filter(
			(data: any) => data.transactionType === PAYMENT_TYPE.ADVANCE
		) || [];
	const paymentsData =
		dataSource?.billLineItems?.filter(
			(data: any) => data.transactionType === PAYMENT_TYPE.PAYMENT
		) || [];
	return (
		<Document style={styles.container}>
			<Page size="A4" orientation="landscape" style={styles.page}>
				<View style={styles.container}>
					<View style={styles.leftHeaderContainer}>
						<Image
							src="https://lyte.co/wp-content/uploads/2023/11/lyte-logo-big.png"
							style={styles.logo}
						/>
						<View>
							<Text style={styles.smallText}>To:</Text>
							<Text style={[styles.fontBold, { padding: "4px 0" }]}>
								{dataSource?.business?.businessName || ""}
							</Text>
							<Text>{`${dataSource?.business?.addressLine1 || ""}, ${
								dataSource?.business?.addressLine2 || ""
							}`}</Text>
							<Text style={{ paddingTop: 2 }}>{`${
								dataSource?.business?.country || ""
							}, ${dataSource?.business?.postalCode || ""}`}</Text>
						</View>
					</View>
					<View style={styles.rightHeaderContainer}>
						<View style={styles.rightHeaderTotalContainer}>
							<View style={styles.rightHeaderDateContainer}>
								<View>
									<Text style={[styles.smallText, { paddingBottom: 4 }]}>
										Bill Date:
									</Text>
									<Text style={styles.fontBold}>
										{reportDateFormat(dataSource?.billDate)}
									</Text>
								</View>
								<View style={styles.rightHeaderDateDivider} />
								<View>
									<Text style={[styles.smallText, { paddingBottom: 4 }]}>
										Bill Due Date:
									</Text>
									<Text style={styles.fontBold}>
										{reportDateFormat(dataSource?.billDueDate)}
									</Text>
								</View>
							</View>
							<View style={styles.rightHeaderTotalEach}>
								<Text>
									{`For the period of ${reportDateFormat(
										dataSource?.billPeriodStartDate
									)} - ${reportDateFormat(dataSource?.billPeriodEndDate)}`}
								</Text>
							</View>
							{/* <View style={styles.rightHeaderTotalEach}>
		        <Text style={{ paddingRight: 16 }}>Outstanding Balance</Text>
		        <Text>{`${dataSource?.billCurrency || ''} ${formatCurrency(
		          dataSource?.billTotalAmount || 0
		        )}`}</Text>
		      </View>
		      <View style={styles.rightHeaderTotalEach}>
		        <Text style={{ paddingRight: 16 }}>Late Fee</Text>
		        <Text>{`${dataSource?.billCurrency || ''} ${formatCurrency(
		          dataSource?.billTotalAmount || 0
		        )}`}</Text>
		      </View> */}
							<View
								style={[
									styles.rightHeaderTotalEach,
									{
										backgroundColor: Color.navi500,
										border: "none",
										color: Color.neutral0,
									},
								]}
							>
								<Text style={styles.fontBold}>Total Bill</Text>
								<Text style={styles.fontBold}>
									{`${dataSource?.billCurrency || ""} ${formatCurrency(
										dataSource?.billTotalAmount || 0
									)}`}
								</Text>
							</View>
						</View>
					</View>
				</View>
				{advancesData?.length > 0 && (
					<View>
						<Text style={styles.bigText}>Advances</Text>
						<View style={styles.table}>
							<View style={styles.thead}>
								<View style={styles.tableRow}>
									{advanceTableHeadRowArray.map((head: any) => (
										<View
											key={Math.random() * 1000}
											style={[styles.tableCell, head.style]}
										>
											<Text style={styles.fontBold}>{head.label}</Text>
										</View>
									))}
								</View>
							</View>
							<View style={styles.tbody}>
								{advancesData?.map((item: any) => (
									<View
										key={Math.random()}
										style={[
											styles.tableRow,
											{ borderBottom: `1px solid ${Color.dark50}` },
										]}
									>
										{advanceTableHeadRowArray.map((head: any) => (
											<View
												key={Math.random() * 1000}
												style={[styles.tableCell, head.style]}
											>
												<Text>{head.render(item)}</Text>
											</View>
										))}
									</View>
								))}
							</View>
							<View style={styles.tfoot}>
								<View style={styles.tableRow}>
									<Text style={styles.fontBold}>{`Total Advances + Fee: ${
										dataSource?.billCurrency
									} ${formatCurrency(
										dataSource?.billTotalAdvanceAmount || 0
									)}`}</Text>
								</View>
							</View>
						</View>
					</View>
				)}
				{paymentsData?.length > 0 && (
					<View>
						<Text style={styles.bigText}>Payments</Text>
						<View style={styles.table}>
							<View style={styles.thead}>
								<View style={styles.tableRow}>
									{paymentTableHeadRowArray.map((head: any) => (
										<View
											key={Math.random() * 1000}
											style={[styles.tableCell, head.style]}
										>
											<Text style={styles.fontBold}>{head.label}</Text>
										</View>
									))}
								</View>
							</View>
							<View style={styles.tbody}>
								{paymentsData?.map((item: any) => (
									<View
										key={Math.random()}
										style={[
											styles.tableRow,
											{ borderBottom: `1px solid ${Color.dark50}` },
										]}
									>
										{paymentTableHeadRowArray.map((head: any) => (
											<View
												key={Math.random() * 1000}
												style={[styles.tableCell, head.style]}
											>
												<Text>{head.render(item)}</Text>
											</View>
										))}
									</View>
								))}
							</View>
							<View style={styles.tfoot}>
								<View style={styles.tableRow}>
									<Text style={styles.fontBold}>{`Total Payments + Fee: ${
										dataSource?.billCurrency
									} ${formatCurrency(
										dataSource?.billTotalPaymentAmount || 0
									)}`}</Text>
								</View>
							</View>
						</View>
					</View>
				)}
			</Page>
			<Page size="A4" orientation="landscape" style={styles.page}>
				<View style={styles.paymentPage}>
					<Text style={[styles.bigText, { paddingBottom: "12px" }]}>
						Payment Terms
					</Text>
					<View style={[styles.paymentClauses, { marginBottom: "12px" }]}>
						<Text>
							(1) The Payor acknowledges that the Supplier is reliant on the
							Payor alone for Payor Data as to the extent the Supplier is to
							process Instructions in furtherance of a Service Transaction on
							the basis of such Payor Data.
						</Text>
						<Text>
							(2) Unless the Supplier otherwise informs, the Supplier shall
							provide a Repayment Statement as an invoice to the Payor twice a
							month on the 7th and 21st day of each month, detailing the
							itemised and total Fees and Payments to be made to the Supplier
							for the Payments made to the Payees by the Supplier.
						</Text>
						<Text>
							(3) Should the 7th or 21st day of the month fall on a non-working
							day, the Supplier shall provide the Repayment Statement as an
							invoice to the Payor on the last working day prior to the 7th or
							the 21st.
						</Text>
						<Text>
							(4) In the event any errors or omissions are made in the Repayment
							Statement, the Supplier shall rectify and invoice any such errors
							or omissions in the next Repayment Statement in the next billing
							cycle or as soon as practicable and the Payor agrees to pay the
							Supplier promptly.
						</Text>
						<Text>
							(5) The Payor agrees that the full payment shall include payment
							towards all Fees and Payments due to the Supplier for all Service
							Transactions made within that invoicing period.
						</Text>
						<Text>
							(6) Any Payment due to the Supplier that is paid in a currency
							other than the agreed currency shall be converted at a conversion
							rate determined in the sole discretion of the Supplier, which
							shall be no more than 50.0% on top of the prevailing exchange rate
							of the Suppliers preferred bank(s), as the case may be.
						</Text>
						<Text>
							(7) Amounts payable to the Supplier shall be paid into the
							following bank account by electronic fund transfer unless
							otherwise notified by the Supplier to the Payor in writing:
						</Text>
					</View>
					<View style={[styles.paymentClauses, { paddingBottom: "12px" }]}>
						<View
							style={{
								display: "flex",
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<View style={styles.paymentBankDetail}>
								<View style={styles.paymentBankDetailRow}>
									<View
										style={[
											styles.paymentBankDetailCol,
											{ borderRight: `1px solid ${Color.dark100}` },
										]}
									>
										<Text style={styles.fontSemiBold}>Bank</Text>
										<Text style={styles.bigText}>
											{contents?.bankInformation?.bankName}
										</Text>
									</View>
									<View
										style={[
											styles.paymentBankDetailCol,
											{ borderRight: `1px solid ${Color.dark100}` },
										]}
									>
										<Text style={styles.fontSemiBold}>Account Holder Name</Text>
										<Text style={styles.bigText}>
											{contents?.bankInformation?.accountHolderName}
										</Text>
									</View>
									<View
										style={[
											styles.paymentBankDetailCol,
											{ borderRight: `1px solid ${Color.dark100}` },
										]}
									>
										<Text style={styles.fontSemiBold}>Swift Code</Text>
										<Text style={styles.bigText}>
											{contents?.bankInformation?.swiftCode}
										</Text>
									</View>
									<View style={[styles.paymentBankDetailCol]}>
										<Text style={styles.fontSemiBold}>Account Number</Text>
										<Text style={styles.bigText}>
											{contents?.bankInformation?.accountNumber}
										</Text>
									</View>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.paymentClauses}>
						<Text>
							(8) The Payor acknowledges that the Supplier shall not be obliged
							to recognise any payments made to bank accounts other than to the
							bank account set out above, unless otherwise notified by the
							Supplier in writing.
						</Text>
						<Text>
							(9) If the Payor fails to make payment in accordance with this
							Agreement, the Supplier shall be entitled to charge interest on
							that unpaid amount that should have been properly paid from the
							final date for payment until the date of actual payment and such
							interest shall be calculated at a compound rate of 1.0% per seven
							(07) calendar days.
						</Text>
						<Text>
							(10) If after having been given three (03) Business Days’ written
							notice by the Supplier of its failure to pay any amount when due
							and payable within the Payment Period, the Payor fails to make
							such payment, then the Supplier may, withhold access to the
							Services or the performance of any of its obligations until such
							failure is remedied.
						</Text>
					</View>
					<View
						style={[
							styles.paymentClauses,
							{ border: "none", marginBottom: 0, paddingBottom: 0 },
						]}
					>
						<Text>
							All capitalised terms shall have the same meaning as in the Payor
							Services Agreement signed between parties. Should there be any
							discrepancy between these terms and those of the Payor Services
							Agreement, the Payor Services Agreement shall prevail.
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default BillPdf;
