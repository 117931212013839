import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import PageTitle from "../../components/common/LyteTitle";
import Table from "../../components/tables/Table";
import { Loading } from "../../components/common";
import { useExportPromoCode, UseGetPromoCodes } from "./PromoCode.service";
import { columns } from "./ViewPromoCode.columns";

import type { IQueryOptions, TfetchData } from "../../interfaces/Global";

const ViewPromoCodes = () => {
	const { push } = useHistory();
	const [page, setPage] = React.useState<number>(1);
	const [pageSize, setPageSize] = React.useState<number>(10);
	const [pageCount, setPageCount] = React.useState<number>(0);
	const [searchKey, setSearchKey] = React.useState("");
	const [filter, setFilter] = React.useState<{ status?: string }>({});
	const [sortColumn, setSortColumn] = React.useState<string | null>(null);
	const [sortDirection, setSortDirection] = React.useState<boolean | null>(
		null
	);

	const urlParams = () => {
		const params: IQueryOptions = {
			page: page || 1,
			rowsPerPage: pageSize || 10,
			searchKey: searchKey || undefined,
			...filter,
		};
		if (sortColumn) params.sortColumn = sortColumn;
		if (sortDirection !== null)
			params.sortDirection = sortDirection ? "Desc" : "Asc";
		return params;
	};

	const { data: promocodes } = UseGetPromoCodes(urlParams());

	const { mutate: mutateExport, isLoading } = useExportPromoCode({
		onSuccess: (result: any) => {
			window.open(
				`${process.env.REACT_APP_API_URL}/public/files/exports/${result}`,
				"_blank"
			);
		},
	});

	const fetchData = React.useCallback(
		({ pageSize, pageIndex, searchKey, sortBy }: TfetchData) => {
			setSortColumn(sortBy.length > 0 ? sortBy[0].id : null);
			setSortDirection(sortBy.length > 0 ? sortBy[0].desc : null);
			setPageSize(+pageSize);
			setPageCount(Math.ceil(promocodes?.total || 1));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[promocodes]
	);
	return (
		<>
			<div className="d-flex justify-content-between gap-2 mb-2">
				<PageTitle>Promo Code</PageTitle>
				<div className="d-flex align-items-center gap-2">
					<Button
						className="my-2"
						variant="danger"
						onClick={() => push("/promocodes/create")}
					>
						Create new code
					</Button>
					<Button
						variant="primary"
						disabled={isLoading}
						onClick={() => mutateExport()}
					>
						{isLoading ? "Loading..." : "Export"}
					</Button>
				</div>
			</div>
			{!promocodes ? (
				<Loading />
			) : (
				<Table
					data={promocodes?.data}
					columns={columns}
					enableControls
					totalData={promocodes?.total || 0}
					disableHover
					tableTitle={undefined}
					rowOnClick={undefined}
					footerComponent={undefined}
					pageCount={pageCount}
					manualPagination
					fetchData={fetchData}
					filterControls={undefined}
					searchPlaceholder={"Search Code..."}
				/>
			)}
		</>
	);
};

export default ViewPromoCodes;
