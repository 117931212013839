import React from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useGetSmsLogById } from "./SmsLogDetails.service";
import { TYPE_FILTER_OPTIONS } from "../constant";
import { dateFormat } from "../../../utils";

const SmsLogDetails = () => {
	const { id } = useParams<{ id: string }>();
	const { data } = useGetSmsLogById(id);
	const { replace } = useHistory();

	return !data ? (
		<>Loading...</>
	) : (
		<Modal show size="lg" onHide={() => replace("/sms-logs")} close>
			<Modal.Header closeButton>
				<Modal.Title>SMS Log {id}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroup.Item>
						<strong>ID</strong>
						<p>{data.id}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Type</strong>
						<p>{TYPE_FILTER_OPTIONS.find((x) => x.id === data.type)?.label}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Subject</strong>
						<p>{data.subject}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>To</strong>
						<p>{data.to}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Is Sent</strong>
						<p>{data.isSent ? "True" : "False"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Number of Retry</strong>
						<p>{data.numberOfRetry}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Last Retry at</strong>
						<p>
							{data.lastRetryAt
								? dateFormat(new Date(data.lastRetryAt), "DD MMM YYYY, HH:mm")
								: "-"}
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Message</strong>
						<section
							className="bg-white p-4 mt-3"
							style={{
								padding: "0px 1.25rem",
								border: "1px solid rgb(0, 0, 0)",
								borderRadius: "10px",
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: data.message }}></div>
						</section>
					</ListGroup.Item>
				</ListGroup>
				<div className="p-3"></div>
			</Modal.Body>
		</Modal>
	);
};

export default SmsLogDetails;
