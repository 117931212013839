export const TYPE_FILTER_OPTIONS = [
	{
		id: "advanceavailable",
		label: "Advance Available",
		value: "AdvanceAvailable",
	},
	{
		id: "advanceexpiringreminder",
		label: "Advance Expiring Reminder",
		value: "AdvanceExpiringReminder",
	},
	{
		id: "billgeneratednotification",
		label: "Bill Generated Notification",
		value: "BillGeneratedNotification",
	},
	{
		id: "boostbeforeendnotification",
		label: "Boost Before End Notification",
		value: "BoostBeforeEndNotification",
	},
	{ id: "boostmatured", label: "Boost Matured", value: "BoostMatured" },
	{
		id: "cashoutcompleted",
		label: "Cashout Completed",
		value: "CashoutCompleted",
	},
	{
		id: "cashoutdisbursementfailed",
		label: "Cashout Disbursement Failed",
		value: "CashoutDisbursementFailed",
	},
	{
		id: "invoiceaccepted",
		label: "Invoice Accepted",
		value: "InvoiceAccepted",
	},
	{
		id: "invoiceacceptedconfirmation",
		label: "Invoice Accepted Confirmation",
		value: "InvoiceAcceptedConfirmation",
	},
	{ id: "invoicecreated", label: "Invoice Created", value: "InvoiceCreated" },
	{ id: "invoicedue", label: "Invoice Due", value: "InvoiceDue" },
	{ id: "invoiceoverdue", label: "Invoice Overdue", value: "InvoiceOverdue" },
	{
		id: "invoiceoverduenotificationtouser",
		label: "Invoice Overdue Notification to User",
		value: "InvoiceOverdueNotificationToUser",
	},
	{ id: "invoicepaid", label: "Invoice Paid", value: "InvoicePaid" },
	{
		id: "invoicepaidpartially",
		label: "Invoice Paid Partially",
		value: "InvoicePaidPartially",
	},
	{
		id: "invoicerejected",
		label: "Invoice Rejected",
		value: "InvoiceRejected",
	},
	{
		id: "invoicerejectedconfirmation",
		label: "Invoice Rejected Confirmation",
		value: "InvoiceRejectedConfirmation",
	},
	{
		id: "invoiceremindersent",
		label: "Invoice Reminder Sent",
		value: "InvoiceReminderSent",
	},
	{
		id: "invoicestatusupdated",
		label: "Invoice Status Updated",
		value: "InvoiceStatusUpdated",
	},
	{
		id: "invoicetestsend",
		label: "Invoice Test Send",
		value: "InvoiceTestSend",
	},
	{
		id: "invoicewaivelatefee",
		label: "Invoice Waive Late Fee",
		value: "InvoiceWaiveLateFee",
	},
	{
		id: "selfcollectinvoicecreated",
		label: "Self-Collect Invoice Created",
		value: "SelfCollectInvoiceCreated",
	},
	{
		id: "sendinvoicelatefee",
		label: "Send Invoice Late Fee",
		value: "SendInvoiceLateFee",
	},
	{
		id: "useremailverification",
		label: "User Email Verification",
		value: "UserEmailVerification",
	},
	{
		id: "yieldmanagementnotification",
		label: "Yield Management Notification",
		value: "YieldManagementNotification",
	},
];
