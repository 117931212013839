import { useQuery } from "react-query";

import useApi from "../../../hooks/useApi";

import type { BoostItem } from "../Boost.type";

export const useGetBoostByRef = (boostRef: string) => {
	const api = useApi();
	return useQuery(
		["boost", boostRef],
		async (): Promise<BoostItem> => {
			const { data } = await api({
				method: "get",
				url: `./admin/boosts/${boostRef}`,
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!boostRef,
		}
	);
};
