export const DRAFT = "draft";
export const PENDING = "pending";
export const PENDING_PAYMENT = "pendingpayment";
export const ACCEPTED = "accepted";
export const REJECTED = "rejected";
export const COMPLETED = "completed";
export const CANCELLED = "cancelled";
export const DELETED = "deleted";
export const PAID = "paid";
export const PARTIALLY_PAID = "partiallypaid";
export const OUTSTANDING = "outstanding";

export const SELECT_FILTER_OPTIONS = [
	{ label: "Pending", value: PENDING },
	{ label: "Pending Payment", value: PENDING_PAYMENT },
	{ label: "Draft", value: DRAFT },
	{ label: "Cancelled", value: CANCELLED },
	{ label: "Accepted", value: ACCEPTED },
	{ label: "Rejected", value: REJECTED },
	{ label: "Deleted", value: DELETED },
	{ label: "Paid", value: PAID },
	{ label: "Completed", value: COMPLETED },
	{ label: "Partially Paid", value: PARTIALLY_PAID },
	{ label: "Outstanding", value: OUTSTANDING },
];
