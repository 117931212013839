import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import {
	createPromoCodeFields,
	createPromoCodeSchema,
} from "./CreatePromoCode.constants";
import { Button } from "react-bootstrap";
import { UsePostNewPromoCode } from "./CreatePromoCode.service";
import PageTitle from "../../../components/common/LyteTitle";

const CreatePromoCode = () => {
	const { mutate: postNewPromoCode } = UsePostNewPromoCode();
	return (
		<>
			<PageTitle>Create Promo Code</PageTitle>
			<Formik
				initialValues={createPromoCodeSchema.cast({}, { stripUnknown: true })}
				onSubmit={(values, formik) => postNewPromoCode(values as any)}
				validateOnMount
				enableReinitialize
				validationSchema={createPromoCodeSchema}
			>
				{({ values, errors, submitForm, isSubmitting, resetForm }) => (
					<>
						<div className="grid my-5">
							{createPromoCodeFields.map((field) => {
								switch (field.as) {
									case "input":
										return (
											<div
												className="g-col-12 g-col-md-6"
												key={field.name + "-input"}
											>
												<label htmlFor={field.name}>
													{field.label === "Value" &&
														(values.type === "Percentage"
															? "Percentage "
															: values.type === "Amount"
															? "Dollar "
															: null)}
													{field.label}
													{field.label === "Value" &&
														(values.type === "Percentage"
															? " (%)"
															: values.type === "Amount"
															? " ($)"
															: null)}
												</label>
												<Field
													id={field.name}
													name={field.name}
													type={field.type}
													as={field.component}
												/>
												<small className="text-danger">
													<ErrorMessage name={field.name} />
												</small>
											</div>
										);
									case "select":
										return (
											<div
												className="g-col-12 g-col-md-6"
												key={field.name + "-select"}
											>
												<label htmlFor={field.name}>{field.label}</label>
												<Field
													id={field.name}
													name={field.name}
													as={field.component}
												>
													{field?.options?.map(({ value, label }) => (
														<option value={value} label={label} key={value} />
													))}
												</Field>
												<small className="text-danger">
													<ErrorMessage name={field.name} />
												</small>
											</div>
										);
									default:
										return null;
								}
							})}
						</div>
						<Button
							onClick={() => submitForm()}
							className="px-5 me-3"
							disabled={!!Object.keys(errors).length || isSubmitting}
						>
							Create code
						</Button>

						<Button
							onClick={() => resetForm()}
							className="px-5 me-3"
							disabled={isSubmitting}
							variant="warning"
						>
							Clear
						</Button>
					</>
				)}
			</Formik>
		</>
	);
};

export default CreatePromoCode;
