import { useMutation, useQuery } from "react-query";

import useApi from "../../hooks/useApi";

import type { IQueryOptions } from "../../interfaces/Global";

export const useBankAccount = (params: IQueryOptions, options?: object) => {
	const api = useApi();
	return useQuery(
		["bank-account", params],
		async () => {
			const { data } = await api.get(`/admin/bank-accounts`, {
				params: {
					...params,
				},
			});
			return data;
		},
		{
			enabled: !!api,
			keepPreviousData: true,
			...options,
		}
	);
};

export const useExportBankAccount = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: "admin/bank-accounts/export/",
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
