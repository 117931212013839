import React from "react";
import { NavLink } from "react-router-dom";
import useBreadCrumbs from "use-react-router-breadcrumbs";
import styled from "styled-components";
import routes from "../../modules/routes";

const CrumbUl = styled.ul`
	list-style: none;
	margin-bottom: 1.5rem;
	padding: 0;
`;

const CrumbLi = styled.li`
	display: inline-block;
	color: var(--lyte-blue);
	margin: 0;
	vertical-align: middle;

	a {
		font-size: 14px;
		margin: 0;
		padding: 0;
		line-height: 24px;
	}

	a.active {
		font-weight: 400;
	}

	:not(:first-child) {
		margin-left: 0.75rem;
	}

	:not(:first-child) {
		:before {
			content: ">";
			font-weight: 500;
			margin-right: 0.25rem;
		}
	}

	a {
		text-decoration: none;
	}
`;

const LyteCrumbs = ({ ...props }) => {
	const breadcrumbs = useBreadCrumbs(routes, {
		excludePaths: ["/dashboard", "/promocodes/update"],
	});
	return (
		breadcrumbs.length > 1 && (
			<CrumbUl {...props}>
				{breadcrumbs.map(({ breadcrumb, match }, idx) => (
					<CrumbLi key={`crumb-${idx}`}>
						<NavLink to={match.url}> {breadcrumb}</NavLink>
					</CrumbLi>
				))}
			</CrumbUl>
		)
	);
};

export default LyteCrumbs;
