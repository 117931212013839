export const ACTIVE = "Active";
export const MATURED = "Matured";
export const PAID = "Paid";
export const PENDING_CASHOUT = "PendingCashout";
export const CLOSED = "Closed";

export const SELECT_FILTER_OPTIONS = {}
SELECT_FILTER_OPTIONS[ACTIVE] = "Active";
SELECT_FILTER_OPTIONS[MATURED] = "Matured";
SELECT_FILTER_OPTIONS[PAID] = "Paid";
SELECT_FILTER_OPTIONS[PENDING_CASHOUT] = "Pending Cashout";
SELECT_FILTER_OPTIONS[CLOSED] = "Closed";