import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/common";
import PageTitle from "../../../components/common/LyteTitle";
import { UseGetBoostDetails } from "../../../hooks/nerveBoost";
import { BoostExportHeaders } from "./BoostExportHeaders";
import { HeaderTypeChecker } from "./components/BoostHeaderUtilities";

const Info = ({
	label,
	value,
	...rest
}: {
	label: string;
	value?: React.ReactNode;
}) => (
	<Col xs={12} md={6} className="p-3" {...rest}>
		<strong>{label}: </strong>
		<p>{value ? value : "-"}</p>
	</Col>
);

const BoostDetails = () => {
	const { boostRef } = useParams<{ boostRef: string }>();
	const { data } = UseGetBoostDetails(boostRef);

	return !data ? (
		<Loading />
	) : (
		<>
			<PageTitle>Boost Details #{data?.boostId}</PageTitle>
			<Card bg="light" className="my-5">
				<Card.Body>
					<Row>
						{BoostExportHeaders.slice(1).map((header) => (
							<Info
								label={header.label}
								value={HeaderTypeChecker(data[header.key], header.type)}
							/>
						))}
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default BoostDetails;
