import { useQuery } from "react-query";
import qs from "qs";
import useNerveApi from "./useNerveApi";

const USER_API_ROUTES = {
	getUsers: (queryParams) => `/admin/user?${queryParams}`,
	getUserDetails: (id) => `/admin/user/${id}`,
};

export const UseGetUsers = ({ query = {}, options } = {}) => {
	const api = useNerveApi();
	const queryParams = qs.stringify(query);

	return useQuery(
		["nerve/users", queryParams],
		async () => {
			const { data } = await api.get(USER_API_ROUTES.getUsers(queryParams));
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...options,
		}
	);
};

export const UseGetUserDetails = (id) => {
	const api = useNerveApi();

	return useQuery(
		["nerve/users", id],
		async () => {
			const { data } = await api.get(USER_API_ROUTES.getUserDetails(id));
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};
