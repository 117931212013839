import * as dayjs from "dayjs";
import { dateFormat } from "../../../utils";
import { SELECT_FILTER_OPTIONS } from "./constants";

export const columns = [
	{
		accessor: "productId",
		Header: "ID",
	},
	{
		accessor: "productName",
		Header: "Name",
	},
	{
		accessor: "productDescription",
		Header: "Description",
	},
	{
		accessor: "productStatus",
		Header: "Status",
		Cell: ({ value }) => SELECT_FILTER_OPTIONS[value],
	},
	{
		accessor: "tenor",
		Header: "Tenor (d)",
		Cell: ({ value }) => value,
	},
	{
		accessor: "rate",
		Header: "Rate",
		Cell: ({ value }) => value + "%",
	},
	{
		accessor: "createdDateTime",
		Header: "Created Date",
		Cell: ({ value }) => dateFormat(new Date(value)),
	},
];
