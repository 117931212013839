import { useMutation, useQuery } from "react-query";
import useApi from "../../hooks/useApi";
import type { IQueryOptions } from "./../../interfaces/Global";

interface QueryOptionsSmsLog extends IQueryOptions {
	type?: string;
}

export const useGetSmsLogs = (
	queryOptions?: QueryOptionsSmsLog,
	extraParams?: object
) => {
	const api = useApi();
	const _queryOptions = !queryOptions
		? { page: 1, rowsPerPage: 5 }
		: queryOptions;
	return useQuery(
		["sms-logs", queryOptions],
		async () => {
			const { data } = await api({
				method: "get",
				url: "/admin/sms-logs",
				params: { ..._queryOptions },
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...extraParams,
		}
	);
};

export const useExportSms = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: "/admin/sms-logs/export/",
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
