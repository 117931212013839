import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { useAuth } from "../../hooks/useAuth";

const Login = () => {
	const { loginHandler, isAuthenticated } = useAuth();
	const { replace } = useHistory();
	if (isAuthenticated) {
		replace("/dashboard");
	}
	return (
		<div
			className="w-100 d-flex align-items-center"
			style={{ height: "100vh" }}
		>
			<div className="container mx-auto text-center">
				<img src={Logo} alt="" width="400" />
				<div className="d-block">
					<Button size="lg" onClick={loginHandler}>
						Login
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Login;
