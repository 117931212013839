import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Title = styled.h2`
	font-weight: 800;
	:first-child {
		margin-bottom: 0rem;
	}
	button {
		float: right;
	}
`;

const PageTitle = ({ children }) => {
	return <Title>{children}</Title>;
};

export default PageTitle;

PageTitle.propTypes = {
	children: PropTypes.any,
};
