import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useGetContents from "../../../hooks/contents";
import { ErrorMessage, Field, Formik } from "formik";
import { simulateBillSchema } from "./schema";
import { useSimulateBills } from "./BillSimulation.service";
import { capitalizeFirstLetter } from "../../../utils";
import BillPdf from "../detail/BillPdf";
import { PDFViewer } from "@react-pdf/renderer";

const BillSimulation = () => {
	const { data: dataContents } = useGetContents();
	const [data, setData] = useState();
	const [successSimulated, setSuccessSimulated] = useState<boolean | null>();
	const { mutate: simulateBills, isLoading } = useSimulateBills({
		onSuccess: (data, variables, context) => {
			setData(data);
			setSuccessSimulated(data != null);
		},
	});
	const { replace } = useHistory();
	const output = data && (
		<PDFViewer
			showToolbar={false}
			width="100%"
			height={695}
			className="border-none"
		>
			<BillPdf dataSource={data} contents={dataContents} />
		</PDFViewer>
	);
	const outputWrapper = isLoading ? (
		<>Simulating Bill</>
	) : successSimulated === false ? (
		<>Failed to simulate. No data found</>
	) : (
		output
	);
	return (
		<Modal size="xl" show onHide={() => replace("/bills")} close>
			<Modal.Header closeButton>
				<Modal.Title>Bill Simulation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{}}
					onSubmit={(values: any) =>
						simulateBills({
							businessId: values.businessId,
							billDate: values.billDate,
						})
					}
					enableReinitialize
					validateOnMount
					validationSchema={simulateBillSchema}
				>
					{({ values, submitForm, errors, isSubmitting }) => (
						<>
							<Row>
								<Col>
									<label htmlFor="businessId">Business</label>
									<Field id="businessId" name="businessId" as={Form.Select}>
										<option value="">Please Select Business</option>
										{dataContents?.businesses?.map(
											(value: any, index: { toString: () => any }) => (
												<option
													key={value?.id + index.toString()}
													value={value?.id}
												>
													{capitalizeFirstLetter(value?.businessName)}
												</option>
											)
										)}
									</Field>
									<small className="text-danger">
										<ErrorMessage name="businessId" />
									</small>
								</Col>
								<Col>
									<label htmlFor="businessId">Bill Date</label>
									<Field
										id="billDate"
										name="billDate"
										type="date"
										as={Form.Control}
									/>
									<small className="text-danger">
										<ErrorMessage name="billDate" />
									</small>
								</Col>
							</Row>
							<Row>
								<Col>
									<Button
										className="my-4"
										onClick={() => submitForm()}
										disabled={!!Object.keys(errors).length || isLoading}
									>
										Simulate
									</Button>
								</Col>
							</Row>
							<Row>
								<Col xs={24} className="text-center">
									{outputWrapper}
								</Col>
							</Row>
						</>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};

export default BillSimulation;
