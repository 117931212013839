import { useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Col } from "react-bootstrap";
import { UseGetGraph } from "../../../hooks/nerveDashboard";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
	},
};

const getGraphDatasets = (data) => {
	let res = {
		labels: [],
		datasets: [
			{
				label: "Users",
				data: [],
				backgroundColor: "rgba(0, 205, 169, 0.7)",
			},
			{
				label: "Boosts",
				data: [],
				backgroundColor: "rgba(53, 162, 235, 0.7)",
			},
		],
	};

	data.graphDetails.map((item) => {
		res.labels.push(item.timeSpan);

		res.datasets[0].data.push(item.totalUsers);
		res.datasets[1].data.push(item.totalBoosts);
	});

	return res;
};

export const DashboardGraph = () => {
	let timeFrame = ["Day", "Week", "Month", "Year"];
	const [radioValue, setRadioValue] = useState("Month");
	const { data } = UseGetGraph(radioValue);

	return !data ? (
		"Loading..."
	) : (
		<Col className="dashboard-graph-container">
			<Col className="button-groups">
				<ButtonGroup>
					{timeFrame.map((item, idx) => (
						<ToggleButton
							key={idx}
							id={`radio-${idx}`}
							type="radio"
							variant="outline-secondary"
							size="sm"
							value={item}
							checked={radioValue === item}
							onChange={(e) => setRadioValue(e.currentTarget.value)}
						>
							{item}
						</ToggleButton>
					))}
				</ButtonGroup>
			</Col>
			<Col xs={12} md={8} className="pt-3 pb-5 graph-inner-container">
				<Bar options={options} data={getGraphDatasets(data)} />
			</Col>
		</Col>
	);
};
