import BoostDetails from "../../../views/nerve/boost/BoostDetails";
import Boost from "../../../views/nerve/boost/Boost";

export const routes = [
	{
		path: "/nerve/boost",
		exact: true,
		component: Boost,
		navLink: true,
	},
	{
		path: "/nerve/boost/:boostRef",
		exact: true,
		component: BoostDetails,
		navLink: true,
	},
];
