import { useCallback } from "react";
import { useAuth } from "./useAuth";
import { useHistory } from "react-router-dom";
import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

const useApi = (preventRedirectWhen401) => {
	const { getAccessTokenSilently } = useAuth();
	const { replace } = useHistory();
	api.interceptors.request.use(
		useCallback(
			async (config) => {
				config.headers.common.Authorization = `Bearer ${
					(await getAccessTokenSilently()) || null
				}`;
				return config;
			},
			[getAccessTokenSilently]
		)
	);

	api.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function (error) {
			if (!preventRedirectWhen401) {
				replace("/unauthorised");
				return Promise.reject(error);
			}
		}
	);

	return api;
};

export default useApi;
