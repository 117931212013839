import currency from "currency.js";
import { ListGroup, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useGetPaymentByRef } from "./PaymentDetails.service";
import { Currency, dateFormat } from "../../../utils";

const PaymentDetails = () => {
	const { ref } = useParams<{ ref: string }>();
	const { data } = useGetPaymentByRef(ref);
	const { replace } = useHistory();

	return !data ? (
		<>Loading...</>
	) : (
		<Modal show onHide={() => replace("/payments")} close>
			<Modal.Header closeButton>
				<Modal.Title>Payment Ref {ref}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroup.Item>
						<strong>Business Name</strong>
						<p className="text-capitalize">{data.businessName}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Payment Reference</strong>
						<p>{data.paymentReference}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Invoice Number</strong>
						<p
							style={{ cursor: "pointer", color: "#3363ff" }}
							onClick={() => replace(`/invoices/${data?.invoiceReference}`)}
						>
							{data.invoiceNumber}
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Invoice Reference</strong>
						<p>{data.invoiceReference}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Created By</strong>
						<p>{data.createdBy}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Email</strong>
						<p>{data.userEmail}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Currency</strong>
						<p>{data.currency}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Amount</strong>
						<p>{Currency(data.amount, { symbol: "" })}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Fee</strong>
						<p>{Currency(data.fee, { symbol: "" })}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Requested Payment Date</strong>
						<p>{dateFormat(new Date(data.paymentDate))}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Actual Payment Date</strong>
						<p>
							{data.actualPaymentDate
								? dateFormat(new Date(data.actualPaymentDate))
								: "-"}
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Created At</strong>
						<p>{dateFormat(new Date(data.createdAt))}</p>
					</ListGroup.Item>
				</ListGroup>
				<div className="p-3"></div>
			</Modal.Body>
		</Modal>
	);
};

export default PaymentDetails;
