import { useState, useMemo, useEffect } from "react";
import {
	useTable,
	usePagination,
	useSortBy,
	useGlobalFilter,
} from "react-table";
import GlobalFilter from "./Table/GlobalFilter";
import { Table, Thead, Th, Tbody, Tr, Td } from "./Table.styled";
import { Form } from "react-bootstrap";

const _Table = ({
	enableControls,
	columns,
	data,
	tableTitle,
	rowOnClick,
	footerComponent,
	disableHover,
	pageCount: controlledPageCount,
	manualPagination,
	fetchData,
	remoteSearchKey = false,
	filterControls,
	rowStyles = () => ({}),
	rowPerPageOptions = [5, 10, 25, 50, 100],
	defaultPageSize = 10,
	searchPlaceholder,
	totalData = 0,
}) => {
	const _columns = useMemo(() => columns, [columns]);

	const _data = useMemo(() => data, [data]);
	const [searchKey, setSearchKey] = useState("");
	const [filter, setFilter] = useState({});

	const defaultColumn = useMemo(
		() => ({
			width: "fit-content",
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		// rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, sortBy },
		// Global Filter props
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			columns: _columns,
			data: _data,
			initialState: { pageIndex: 0, pageSize: defaultPageSize },
			defaultColumn,
			manualSortBy: true,
			manualPagination,
			pageCount: controlledPageCount,
			autoResetPage: false,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	useEffect(() => {
		fetchData &&
			fetchData({
				pageIndex: manualPagination ? pageIndex + 1 : pageIndex,
				pageSize,
				searchKey,
				filter,
				sortBy,
			});
	}, [
		sortBy,
		fetchData,
		pageIndex,
		pageSize,
		searchKey,
		manualPagination,
		filter,
	]);

	return (
		// apply the table props
		<div className="pb-5">
			<h1>{tableTitle}</h1>
			{enableControls && (
				<div className="row d-flex p-0 my-2 gy-2 justify-content-between">
					<div className="d-flex gap-2 col-md-3 col-xxl-2">
						<div className="w-100">
							<GlobalFilter
								preGlobalFilteredRows={preGlobalFilteredRows}
								searchPlaceholder={searchPlaceholder}
								setGlobalFilter={(e) => {
									if (remoteSearchKey) {
										setSearchKey(e);
									} else {
										setGlobalFilter(e);
									}
								}}
							/>
						</div>
					</div>

					{filterControls && (
						<div className="col-md-9 col-xxl-6 gx-md-0">{filterControls}</div>
					)}
					<div className="col-md-12 col-xxl-4 justify-content-end">
						<div className="d-flex gap-2 justify-content-end">
							<div className="">
								<div className="d-flex align-items-center small">
									<span className="label">Show:</span>
									<Form.Select
										value={pageSize}
										onChange={(e) => {
											setPageSize(e.target.value);
										}}
										className="w-auto h-auto my-0 mx-1 py-1"
										style={{ lineHeight: 1.3 }}
									>
										{rowPerPageOptions.map((pageSize) => (
											<option key={pageSize} value={pageSize}>
												{pageSize}
											</option>
										))}
									</Form.Select>
								</div>
							</div>

							<div className=" text-end">
								<small>
									{pageIndex * pageSize + 1} -
									{pageSize * pageIndex + +pageSize < +totalData
										? pageSize * pageIndex + +pageSize
										: totalData}{" "}
									of {+totalData}
								</small>
								<span
									className="btn btn-sm px-1"
									disabled={!canNextPage}
									onClick={() => gotoPage(0)}
								>
									⏪
								</span>
								<span
									className="btn btn-sm px-1"
									disabled={!canPreviousPage}
									onClick={previousPage}
								>
									◀️
								</span>
								<span
									className="btn btn-sm px-1"
									disabled={!canNextPage}
									onClick={nextPage}
								>
									▶️
								</span>
								<span
									className="btn btn-sm px-1"
									disabled={!canNextPage}
									onClick={() => gotoPage(pageCount - 1)}
								>
									⏩
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
			<div style={{ overflowX: "auto" }}>
				<Table className="table w-100" {...getTableProps()}>
					<Thead>
						{
							// Loop over the header rows
							headerGroups.map((headerGroup) => (
								// Apply the header row props
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										// Loop over the headers in each row
										headerGroup.headers.map((column) => (
											// Apply the header cell props
											<Th
												{...column.getHeaderProps(
													column.getSortByToggleProps()
												)}
											>
												<div
													className="d-flex align-items-center"
													style={{ whiteSpace: "nowrap" }}
												>
													{
														// Render the header
														column.render("Header")
													}
													<span>
														{column.isSorted
															? column.isSortedDesc
																? " 🔽"
																: " 🔼"
															: ""}
													</span>
												</div>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>
					{/* Apply the table body props */}
					<Tbody {...getTableBodyProps()} disableHover={disableHover}>
						{!page.length && (
							<Tr>
								<Td colSpan="10000">
									<div className="text-center">No records found</div>
								</Td>
							</Tr>
						)}

						{
							// Loop over the table rows
							page.map((row) => {
								// Prepare the row for display
								prepareRow(row);
								return (
									// Apply the row props
									<Tr
										{...row.getRowProps()}
										onClick={() => rowOnClick && rowOnClick(row)}
										style={rowStyles && rowStyles(row)}
									>
										{
											// Loop over the rows cells
											row.cells.map((cell) => {
												// Apply the cell props
												return (
													<td
														{...cell.getCellProps()}
														style={{ width: cell.column.width }}
													>
														{
															// Render the cell contents
															cell.render("Cell")
														}
													</td>
												);
											})
										}
									</Tr>
								);
							})
						}
					</Tbody>
					<tfoot>{footerComponent && footerComponent()}</tfoot>
				</Table>
			</div>
		</div>
	);
};

export default _Table;
