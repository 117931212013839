import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";

import PageTitle from "../../../components/common/LyteTitle";
import BoostExportCsvButton from "./components/BoostExportCsvButton";
import { UseGetBoosts, UsePushBoostCashouts } from "../../../hooks/nerveBoost";
import Table from "../../../components/tables/Table";
import { columns } from "./Boost.columns";
import { Button, Form, Modal } from "react-bootstrap";
import { PENDING_CASHOUT, SELECT_FILTER_OPTIONS } from "./constants";
import { Checkbox, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { dateFormat } from "../../../utils";
import dayjs from "dayjs";

const PushAction = (params) => {
	const [opened, setOpened] = useState(false);
	const open = () => setOpened(true);
	const close = () => setOpened(false);
	const { mutate: pushBoostCashouts, isLoading: isUpdating } =
		UsePushBoostCashouts({
			onSettled: () => {
				close();
			},
		});

	return (
		<>
			<Button
				variant="info"
				onClick={open}
				disabled={params.boostIds.length <= 0}
			>
				Push {params.boostIds.length > 0 && "(" + params.boostIds.length + ")"}
			</Button>
			<Modal centered show={opened} onHide={close}>
				<Form>
					<Modal.Body>
						<p>Are you sure you want to push boost cashout?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="white"
							className="red"
							onClick={() => {
								close();
							}}
						>
							Cancel
						</Button>
						<Button
							className="btn-sm"
							onClick={() => pushBoostCashouts(params.boostIds)}
							disabled={isUpdating}
						>
							Yes, I confirm
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

const Boost = () => {
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const [filter, setFilter] = useState({
		startdate: dayjs().startOf("month"),
		enddate: dayjs(),
	});
	const [boostIds, setBoostIds] = useState([]);

	const { data: boosts } = UseGetBoosts({
		query: {
			page: page || undefined,
			rowsPerPage: rowsPerPage || undefined,
			searchKey: searchKey || undefined,
			...filter,
			startdate: dateFormat(filter.startdate, "YYYY-MM-DD"),
			enddate: dateFormat(filter.enddate, "YYYY-MM-DD"),
		},
	});

	const fetchData = useCallback(
		({ pageSize, pageIndex, searchKey }) => {
			setRowsPerPage(+pageSize);
			setPageCount(Math.ceil(boosts?.total / pageSize));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[boosts]
	);

	const boostIdCheckboxHandler = useCallback(
		(event, id) => {
			const { checked } = event.target;
			if (checked) {
				setBoostIds((value) => [...value, id]);
			} else {
				setBoostIds((value) => value.filter((it) => it !== id));
			}
		},
		[setBoostIds]
	);

	const checkboxChecked = (id) => {
		return boostIds.indexOf(id) >= 0;
	};

	const newColumns = [
		{
			accessor: "cashoutPushed",
			Header: " ",
			Cell: ({ row }) => (
				<Checkbox
					disabled={
						!(
							!row.original.cashoutPushed &&
							row.original.status == PENDING_CASHOUT
						)
					}
					checked={checkboxChecked(row.original.boostId)}
					onChange={(e) => boostIdCheckboxHandler(e, row.original.boostId)}
				></Checkbox>
			),
			width: 50,
		},
		...columns,
	];

	const dateChecker = useCallback(
		(type, value) => {
			const choosenDate = new Date(value.$d).getTime();
			let startDate = filter.startdate;
			let endDate = filter.enddate;
			switch (type) {
				case "start":
					startDate = choosenDate;
					if (endDate && choosenDate > endDate) endDate = null;
					break;
				default:
					endDate = choosenDate;
					if (startDate && choosenDate < startDate) startDate = null;
					break;
			}
			setFilter({ ...filter, startdate: startDate, enddate: endDate });
		},
		[filter]
	);

	const futurePastDateChecker = (type) => {
		switch (type) {
			case "min":
				return filter.endDate;
			default:
				return filter.startDate;
		}
	};

	return !boosts ? (
		"Loading"
	) : (
		<>
			<div className="d-flex justify-content-between gap-2">
				<PageTitle>Boost</PageTitle>
				<div>
					<BoostExportCsvButton
						filter={{ ...filter, searchKey }}
					></BoostExportCsvButton>
					<PushAction boostIds={boostIds}></PushAction>
				</div>
			</div>
			<div style={{ height: "70vh", width: "100%" }}>
				<Table
					enableControls
					data={boosts.data}
					columns={newColumns}
					fetchData={fetchData}
					manualPagination
					pageCount={pageCount}
					remoteSearchKey
					defaultPageSize={50}
					rowPerPageOptions={[50, 100]}
					searchPlaceholder={"Search Boost Amount..."}
					filterControls={
						<div className="d-flex align-items-center gap-2 flex-wrap">
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										status: e.target.value || undefined,
									});
								}}
								value={filter?.status}
							>
								<option value="">Filter by status</option>
								{Object.entries(SELECT_FILTER_OPTIONS).map(([value, label]) => (
									<option key={value} value={value}>
										{label}
									</option>
								))}
							</Form.Select>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className="date-boost-control"
									label="Start Date"
									value={filter?.startdate || null}
									onChange={(newValue) => {
										dateChecker("start", newValue);
									}}
									maxDate={() => futurePastDateChecker("max")}
									renderInput={(params) => <TextField {...params} />}
									inputFormat="DD-MMM-YYYY"
								/>
								<DatePicker
									className="date-boost-control"
									label="End Date"
									value={filter?.enddate || null}
									onChange={(newValue) => {
										dateChecker("end", newValue);
									}}
									minDate={() => futurePastDateChecker("min")}
									renderInput={(params) => <TextField {...params} />}
									inputFormat="DD-MMM-YYYY"
								/>
							</LocalizationProvider>
						</div>
					}
				/>
			</div>
		</>
	);
};

export default Boost;
