import Invoices from "../../views/invoices/Invoices";
import InvoiceDetails from "../../views/invoices/detail/InvoiceDetails";

export const routes = [
	{
		path: "/invoices",
		component: Invoices,
		exact: true,
		navLink: true,
	},
	{
		path: "/invoices/:invoiceReference",
		component: InvoiceDetails,
		exact: true,
		navLink: true,
	},
];
