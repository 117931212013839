import React from "react";
import "./assets/scss/styles.scss";
import { Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Crumbs from "./components/common/Crumbs";
import routes from "./modules/routes";
import Layout from "./components/layout/Layout";
import Login from "./views/public/Login";
import _401 from "./views/errors/401";
import { AuthProvider } from "./modules/auth";

const SentryRoute = Sentry.withSentryRouting(Route);

const App = () => {
	return (
		<Switch>
			<SentryRoute path="*">
				<SentryRoute exact path="/" component={Login} />
				<SentryRoute exact path="/login" component={Login} />
				<SentryRoute exact path="/unauthorised" component={_401} />
				<AuthProvider>
					<Layout>
						<Crumbs className="d-block d-lg-block" />
						{routes.map(({ path, component, exact }, idx) => (
							<SentryRoute
								key={idx}
								exact={exact}
								path={path}
								component={component}
							/>
						))}
					</Layout>
				</AuthProvider>
			</SentryRoute>
		</Switch>
	);
};

export default App;
