import currency from "currency.js";
import * as dayjs from "dayjs";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { UsePushBoostMatured } from "../../../hooks/nerveBoost";
import { MATURED, SELECT_FILTER_OPTIONS } from "./constants";
import { dateFormat } from "../../../utils";

const ActionButtons = (params) => {
	const [opened, setOpened] = useState(false);
	const open = () => setOpened(true);
	const close = () => setOpened(false);
	const { mutate: pushBoostMatured, isLoading: isUpdating } =
		UsePushBoostMatured({
			onSettled: () => {
				close();
			},
		});

	const data = params.data;

	return (
		<>
			<DropdownButton size="sm" autoClose="outside" title="">
				<Dropdown.Item as={NavLink} to={`/nerve/boost/${data.boostId}`}>
					View
				</Dropdown.Item>
				{data.status == MATURED && (
					<Dropdown.Item onClick={open}>Push</Dropdown.Item>
				)}
			</DropdownButton>
			<Modal centered show={opened} onHide={close}>
				<Form>
					<Modal.Body>
						<p>Are you sure you want to push boost matured?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="white"
							className="red"
							onClick={() => {
								close();
							}}
						>
							Cancel
						</Button>
						<Button
							className="btn-sm"
							onClick={() => pushBoostMatured(data.boostId)}
							disabled={isUpdating}
						>
							Yes, I confirm
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export const columns = [
	{
		accessor: "boostId",
		Header: "ID",
	},
	{
		accessor: "userName",
		Header: "User",
	},
	{
		accessor: "boostPrincipal",
		Header: "Boost Amount",
		Cell: ({ row }) =>
			currency(row.original.boostPrincipal, {
				symbol: "$",
			}).format(),
		width: 150,
	},
	{
		accessor: "tenor",
		Header: "Duration (d)",
		Cell: ({ row }) =>
			dayjs(new Date(row.original.matureDate)).diff(
				new Date(row.original.startDate),
				"days"
			),
	},
	{
		accessor: "rate",
		Header: "Rate",
		Cell: ({ value }) => value + "%",
	},
	{
		accessor: "startDate",
		Header: "Start Date",
		Cell: ({ value }) => dateFormat(new Date(value)),
	},
	{
		accessor: "matureDate",
		Header: "End Date",
		Cell: ({ value }) => dateFormat(new Date(value)),
	},
	{
		accessor: "status",
		Header: "Status",
		Cell: ({ value }) => SELECT_FILTER_OPTIONS[value],
	},
	{
		accessor: "",
		Header: " ",
		Cell: ({ row }) => <ActionButtons data={row.original}></ActionButtons>,
		width: 50,
	},
];
