import { useMutation, useQuery } from "react-query";

import useApi from "../../hooks/useApi";

import type { BoostItem } from "./Boost.type";

interface QueryOptions {
	page: number;
	rowsPerPage: number;
	searchKey?: string;
	status?: string;
	autoReboost?: string;
}

export const useGetBoosts = (queryOptions?: QueryOptions) => {
	const api = useApi();
	const _queryOptions = !queryOptions
		? { page: 1, rowsPerPage: 5 }
		: queryOptions;
	return useQuery(
		["boosts", queryOptions],
		async (): Promise<{ data: BoostItem[]; total: number }> => {
			const { data } = await api({
				method: "get",
				url: "/admin/boosts",
				params: { ..._queryOptions },
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};

export const useExportBoosts = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: "admin/boosts/export/",
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
