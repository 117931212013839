import { useMutation } from "react-query";

import useApi from "../../../hooks/useApi";
import { TInvoicesParams } from "../Invoices.type";

import type { TInvoicesDetailsAddPaymentPayload } from "./InvoicesDetails.type";

export const useMutationAddPayment = (
	params: TInvoicesParams,
	options: object
) => {
	const api = useApi();
	return useMutation(
		async (payload: TInvoicesDetailsAddPaymentPayload) => {
			const { data } = await api.put(
				`admin/invoices/paid/${params.invoiceReference}`,
				payload
			);
			return data;
		},
		{
			...options,
		}
	);
};
