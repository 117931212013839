export const BoostExportHeaders = [
	{
		label: "Boost ID",
		key: "boostId",
		type: "label",
	},
	{
		label: "User ID",
		key: "userId",
		type: "label",
	},
	{
		label: "User Name",
		key: "userName",
		type: "label",
	},
	{
		label: "Reward Rate",
		key: "rate",
		type: "label",
	},
	{
		label: "Duration (d)",
		key: "tenor",
		type: "label",
	},
	{
		label: "Expected Rewards",
		key: "projectedRewardAmount",
		type: "currency",
	},
	{
		label: "Actual Rewards",
		key: "actualRewardAmount",
		type: "currency",
	},
	{
		label: "Boost Amount",
		key: "boostPrincipal",
		type: "currency",
	},
	{
		label: "Total Amount",
		key: "totalAmount",
		type: "currency",
	},
	{
		label: "Cashout Date",
		key: "cashoutRequestDateTime",
		type: "date",
	},
	{
		label: "Cashout Amount",
		key: "cashoutTotal",
		type: "currency",
	},
	{
		label: "Start Date",
		key: "startDate",
		type: "date",
	},
	{
		label: "End Date",
		key: "matureDate",
		type: "date",
	},
	{
		label: "Status",
		key: "status",
		type: "status",
	},
];
