import type { RouteProps } from "react-router-dom";

import BankAccount from "./../../views/bankaccount/BankAccount";
import BankAccountDetails from "./../../views/bankaccount/detail/BankAccountDetails";

export const routes: RouteProps[] = [
	{
		path: "/bank-account",
		component: BankAccount,
		exact: true,
	},
	{
		path: "/bank-account/:id",
		component: BankAccountDetails,
		exact: true,
	},
];
