import { useMutation, useQuery } from "react-query";
import useApi from "../../hooks/useApi";
import type { IQueryOptions } from "./../../interfaces/Global";

interface QueryOptionsEmailLog extends IQueryOptions {
	type?: string;
}

export const useGetEmailLogs = (
	queryOptions?: QueryOptionsEmailLog,
	extraParams?: object
) => {
	const api = useApi();
	const _queryOptions = !queryOptions
		? { page: 1, rowsPerPage: 5 }
		: queryOptions;
	return useQuery(
		["email-logs", queryOptions],
		async () => {
			const { data } = await api({
				method: "get",
				url: "/admin/email-logs",
				params: { ..._queryOptions },
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...extraParams,
		}
	);
};

export const useExportBill = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: "/admin/bills/export/",
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
