import React, { useCallback } from "react";
import { Bar } from "react-chartjs-2";
import LyteTitle from "../../components/common/LyteTitle";
import { useGetDashboard } from "../../hooks/dashboard";
import { Currency } from "../../utils";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";

const Dashboard = () => {
	const { data, isLoading } = useGetDashboard();

	const coloursAmount = ["#152A6B", "#2446B5", "#3363FF", "#7696FF"];
	const coloursCount = ["#214C41", "#38816E", "#4FB59B", "#89CDBC"];
	const secondaryColoursCount = ["#272F38", "#465463", "#63778C", "#96A4B2"];

	const handleCheckTotalValue = (amount) =>
		_.sum(amount.map((value) => +value));

	const handleTotalAmount = handleCheckTotalValue([
		data?.boostInformation?.totalAutoReboost || 0,
		data?.boostInformation?.totalNonAutoReboost || 0,
	]);

	const handleTotalCount = handleCheckTotalValue([
		data?.boostInformation?.countAutoReboost || 0,
		data?.boostInformation?.countNonAutoReboost || 0,
	]);

	const handleCalculateNearestRounding = (amount, rounding = 1000) => {
		const checkRounding = (amount / rounding) % 2 === 0 ? rounding : 0;
		return Math.ceil((amount + checkRounding) / rounding) * rounding;
	};

	const matureBoostData = [
		{
			id: 0,
			name: "30 days and less",
			count: data?.boostInformation?.countBoostMatureIn30Days,
			amount: data?.boostInformation?.totalBoostMatureIn30Days,
		},
		{
			id: 1,
			name: "31 days and 90 days",
			count: data?.boostInformation?.countBoostMatureIn31To90Days,
			amount: data?.boostInformation?.totalBoostMatureIn31To90Days,
		},
		{
			id: 2,
			name: "91 days and 180 days",
			count: data?.boostInformation?.countBoostMatureIn91To180Days,
			amount: data?.boostInformation?.totalBoostMatureIn91To180Days,
		},
		{
			id: 3,
			name: "181 days and more",
			count: data?.boostInformation?.countBoostMatureInMoreThan181Days,
			amount: data?.boostInformation?.totalBoostMatureInMoreThan181Days,
		},
	];

	return (
		<>
			<LyteTitle>LytePay 2.0 Admin Dashboard</LyteTitle>
			<Row className="mt-4">
				<Col className="mb-5" md={6}>
					<h5>Total Ongoing Boost:</h5>
					<h3>{Currency(handleTotalAmount, { symbol: "SGD " })}</h3>
					<h5>Total Ongoing AutoReBoost:</h5>
					<h3>
						{`${Currency(data?.boostInformation?.totalAutoReboost || 0, {
							symbol: "SGD ",
						})} (${Math.round(
							(+data?.boostInformation?.totalAutoReboost / handleTotalAmount) *
								100
						)}
						%)`}
					</h3>
					<h5 className="mt-5">Boost Amount</h5>
					<div>
						{!isLoading && (
							<Bar
								data={{
									labels: data?.boostDistributions?.map(
										(boost) => boost.days + " days"
									),
									datasets: [
										{
											minBarLength: 0,
											backgroundColor: data?.boostDistributions?.map(
												(boost, index) =>
													coloursAmount?.[index] || coloursAmount[0]
											),
											data: data?.boostDistributions?.map((boost) =>
												handleCheckTotalValue([
													boost.totalAutoReboost,
													boost.totalNonAutoReboost,
												])
											),
										},
									],
								}}
								options={{
									responsive: true,
									title: {
										display: true,
										text: "Boost Amount",
									},
									plugins: {
										legend: { display: false },
										tooltip: {
											callbacks: {
												label: (tooltipItem) => {
													return `Amount: ${Currency(tooltipItem?.raw || 0, {
														symbol: "SGD ",
													})}, ${Math.round(
														(+tooltipItem?.raw / handleTotalAmount) * 100
													)}% of Total Amount`;
												},
											},
										},
									},
									scales: {
										y: {
											max: handleCalculateNearestRounding(
												Math.max(
													...data?.boostDistributions?.map((boost) =>
														handleCheckTotalValue([
															boost.totalAutoReboost,
															boost.totalNonAutoReboost,
														])
													)
												)
											),
										},
									},
								}}
							/>
						)}
					</div>
				</Col>
				<Col md={6}>
					<h5>Total Ongoing Boost Count:</h5>
					<h3>{handleTotalCount}</h3>
					<h5>Total Ongoing AutoReBoost Count :</h5>
					<h3>
						{`${data?.boostInformation?.countAutoReboost || 0}
						(${Math.round(
							(+data?.boostInformation?.countAutoReboost / handleTotalCount) *
								100
						)}
						%)`}
					</h3>
					<h5 className="mt-5">Boost Count</h5>
					<div>
						{!isLoading && (
							<Bar
								data={{
									labels: data?.boostDistributions?.map(
										(boost) => boost.days + " days"
									),
									datasets: [
										{
											minBarLength: 0,
											backgroundColor: data?.boostDistributions?.map(
												(boost, index) =>
													coloursCount?.[index] || coloursCount[0]
											),
											data: data?.boostDistributions?.map(
												(boost) => boost.countNonAutoReboost
											),
										},
										{
											minBarLength: 0,
											backgroundColor: data?.boostDistributions?.map(
												(boost, index) =>
													secondaryColoursCount?.[index] ||
													secondaryColoursCount[0]
											),
											data: data?.boostDistributions?.map(
												(boost) => boost?.countAutoReboost
											),
										},
									],
								}}
								options={{
									responsive: true,
									title: {
										display: true,
										text: "Boost Count Chart",
									},
									plugins: {
										legend: { display: false },
										tooltip: {
											callbacks: {
												label: (tooltipItem) => {
													let text = "Total Count";
													if (tooltipItem.datasetIndex) {
														text = "Total AutoReBoost Count";
													}
													return `Ongoing Boost: ${handleCheckTotalValue([
														data?.boostDistributions?.[tooltipItem.dataIndex]
															?.countAutoReboost,
														data?.boostDistributions?.[tooltipItem.dataIndex]
															?.countNonAutoReboost,
													])}, ${text}: ${tooltipItem?.raw || 0} (${Math.round(
														(tooltipItem.raw /
															handleCheckTotalValue([
																data?.boostDistributions?.[
																	tooltipItem.dataIndex
																]?.countAutoReboost,
																data?.boostDistributions?.[
																	tooltipItem.dataIndex
																]?.countNonAutoReboost,
															])) *
															100
													)}%)`;
												},
											},
										},
									},
									scales: {
										x: { stacked: true },
										y: {
											stacked: true,
											max:
												Math.max(
													...data?.boostDistributions?.map((boost) =>
														handleCheckTotalValue([
															boost?.countAutoReboost,
															boost?.countNonAutoReboost,
														])
													)
												) + 2,
										},
									},
								}}
							/>
						)}
					</div>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col md={6}>
					<h5>Aging Boost Amount</h5>
					<Bar
						data={{
							labels: matureBoostData?.map((boost) => boost.name),
							datasets: [
								{
									minBarLength: 0,
									backgroundColor: matureBoostData?.map(
										(boost, index) => coloursAmount?.[index] || coloursAmount[0]
									),
									data: matureBoostData?.map((boost) => boost.amount),
								},
							],
						}}
						options={{
							responsive: true,
							title: {
								display: true,
								text: "Aging Boost Amount Chart",
							},
							plugins: {
								legend: { display: false },
								tooltip: {
									callbacks: {
										label: (tooltipItem) => {
											return `${Currency(tooltipItem.raw, { symbol: "SGD " })}`;
										},
									},
								},
							},
							scales: {
								y: {
									max: handleCalculateNearestRounding(
										Math.max(
											...matureBoostData?.map((boost) =>
												handleCheckTotalValue([boost.amount, boost.amount])
											)
										)
									),
								},
							},
						}}
					/>
				</Col>
				<Col md={6}>
					<h5>Aging Boost Count</h5>
					<Bar
						data={{
							labels: matureBoostData?.map((boost) => boost.name),
							datasets: [
								{
									minBarLength: 0,
									backgroundColor: matureBoostData?.map(
										(boost, index) => coloursCount?.[index] || coloursCount[0]
									),
									data: matureBoostData?.map((boost) => boost.count),
								},
							],
						}}
						options={{
							responsive: true,
							title: {
								display: true,
								text: "Aging Boost Count Chart",
							},
							plugins: {
								legend: { display: false },
								callbacks: {
									label: (tooltipItem) => {
										return `${Currency(tooltipItem.raw, { symbol: "SGD " })}`;
									},
								},
							},
							scales: {
								y: {
									stacked: true,
									max: Math.round(
										Math.max(...matureBoostData?.map((boost) => boost.count)) +
											2
									),
								},
							},
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default Dashboard;
