import { useMutation } from "react-query";
import useApi from "../../../hooks/useApi";
import { useHistory } from "react-router-dom";

const PROMO_CODE_API_ROUTES = {
	postNewPromoCode: "/admin/promocodes",
};

export const UsePostNewPromoCode = () => {
	const { push } = useHistory();
	const api = useApi();
	return useMutation(
		async (values: any) => {
			values.dateStart = new Date(values.dateStart).toISOString();
			values.dateEnd = new Date(values.dateEnd).toISOString();
			if (values.type === "Percentage") {
				values.value = values.value / 100;
			}
			const { data } = await api({
				method: "post",
				url: PROMO_CODE_API_ROUTES.postNewPromoCode,
				data: values,
			});
			return data;
		},
		{
			onSuccess: () => push("/promocodes"),
		}
	);
};
