import { useState, useCallback, useRef } from "react";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";

import { dateFormat } from "../../../../utils";
import { UseGetBoosts } from "../../../../hooks/nerveBoost";
import { BoostExportHeaders } from "../BoostExportHeaders";
import { HeaderTypeChecker } from "./BoostHeaderUtilities";

const BoostExportCsvButton = ({ filter }) => {
	const [canExport, setCanExport] = useState(false);
	const downloadRef = useRef();

	const { data: boosts, refetch } = UseGetBoosts({
		query: {
			rowsPerPage: 5000,
			...filter,
			startdate: dateFormat(filter.startdate, "YYYY-MM-DD"),
			enddate: dateFormat(filter.enddate, "YYYY-MM-DD"),
			sorttype: "Asc",
		},
		options: {
			enabled: canExport,
			refetchOnWindowFocus: false,
			onSuccess: () => {
				setTimeout(() => {
					setCanExport(false);
					downloadRef.current.link.click();
				}, 1000);
			},
		},
	});

	const handleCsvData = useCallback(() => {
		const rows =
			boosts &&
			boosts?.data?.map((row) => {
				const tempObject = {};
				BoostExportHeaders.forEach((column) => {
					tempObject[column?.key] =
						column.key === "totalAmount"
							? HeaderTypeChecker(
									(+row?.boostPrincipal + +row?.actualRewardAmount).toFixed(2),
									"label"
							  )
							: HeaderTypeChecker(
									row[column?.key],
									column.type === "currency" ? "label" : column.type
							  );
				});
				return tempObject;
			});
		return rows || [];
	}, [boosts, canExport]);

	const handleCsvFileName = () => {
		let filename = "export-boost-table";
		if (filter?.startdate)
			filename += `-${dateFormat(filter?.startdate).replaceAll(" ", "-")}`;
		if (filter?.enddate)
			filename += `-${dateFormat(filter?.enddate).replaceAll(" ", "-")}`;
		return filename;
	};

	return (
		<>
			<Button
				variant="primary"
				disabled={canExport}
				onClick={() => {
					setCanExport(true);
					refetch();
				}}
			>
				{canExport ? "Loading..." : "Export"}
			</Button>
			<CSVLink
				separator={";"}
				className="mx-2"
				filename={handleCsvFileName()}
				headers={BoostExportHeaders}
				data={handleCsvData()}
				asyncOnClick={true}
				ref={downloadRef}
			/>
		</>
	);
};

export default BoostExportCsvButton;
