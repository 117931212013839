import React from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Button } from "react-bootstrap";
import img from "../../assets/images/errors/401.png";

const _401 = () => {
	const { replace } = useHistory();
	return (
		<div className="container text-center" style={{ marginTop: "15%" }}>
			<div className="mx-auto my-auto" style={{ width: "500px" }}>
				<h2 className="fw-600 my-4">Error. Page Unauthorised.</h2>
				<Image src={img} alt="401" fluid width={400} />
				<h5 className="my-4">
					You are not authorised to access this page. Please contact the
					administrator if problem continues.
				</h5>
				<Button
					size="lg"
					variant="secondary"
					style={{ width: "250px", padding: "0.75rem 0" }}
					onClick={() => replace("/")}
				>
					Back to Home
				</Button>
			</div>
		</div>
	);
};

export default _401;
