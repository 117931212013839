import { Column } from "react-table";
import { dateFormat } from "../../utils";
import { TYPE_FILTER_OPTIONS } from "./constant";

export const tableColumns: Column[] = [
	{
		Header: "ID",
		accessor: "id",
	},
	{
		Header: "Type",
		accessor: "type",
		Cell: ({ value }: any) => (
			<>{TYPE_FILTER_OPTIONS.find((x) => x.id === value)?.label}</>
		),
	},
	{
		Header: "To",
		accessor: "to",
	},
	{
		Header: "Is Sent",
		accessor: "isSent",
		Cell: ({ value }: any) => <>{value ? "True" : "False"}</>,
	},
	{
		Header: "Number of Retry",
		accessor: "numberOfRetry",
	},
	{
		Header: "Last Retry at",
		accessor: "lastRetryAt",
		Cell: ({ value }: any) => (
			<>{value ? dateFormat(new Date(value), "DD MMM YYYY, HH:mm") : "-"}</>
		),
	},
];
