import { Form } from "react-bootstrap";
import * as yup from "yup";

export const createPromoCodeSchema = yup.object({
	code: yup
		.string()
		.required("Code is required")
		.min(6, "Min length of 6 characters")
		.default(""),
	type: yup
		.string()
		.required()
		.oneOf(["Amount", "Percentage"])
		.default("Amount"),
	value: yup
		.number()
		.required()
		.min(0)
		.when("type", {
			is: (value: string) => value === "Percentage",
			then: (schema) => schema.max(100),
		})
		.default(0),
	maxValue: yup.number().required().default(0),
	currency: yup.string().required().oneOf(["SGD"]).default("SGD"),
	description: yup.string().default(""),
	maxRedemption: yup.number().required().default(9999),
	dateStart: yup
		.string()
		.required()
		.default(new Date().toISOString().split("T")[0]),
	dateEnd: yup
		.string()
		.required()
		.default(new Date().toISOString().split("T")[0]),
	status: yup
		.string()
		.required()
		.oneOf(["Active", "Inactive"])
		.default("Active"),
});

export const createPromoCodeFields = [
	{
		label: "Code",
		name: "code",
		type: "text",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Maximum Value ($)",
		name: "maxValue",
		type: "number",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Type",
		name: "type",
		as: "select",
		component: Form.Select,
		options: [
			{ value: "Percentage", label: "Percentage" },
			{ value: "Amount", label: "Amount" },
		],
	},
	{
		label: "Value",
		name: "value",
		type: "number",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Currency",
		name: "maxValue",
		type: "currency",
		as: "select",
		component: Form.Select,
		options: [{ value: "SGD", label: "SGD" }],
	},
	{
		label: "Description",
		name: "description",
		type: "currency",
		as: "textarea",
		component: Form.Control,
		clcomponentsName: "form-control",
		rows: 5,
		style: { resize: "none" },
	},
	{
		label: "Maximum Redemptions",
		name: "maxRedemption",
		type: "number",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Date Start",
		name: "dateStart",
		type: "date",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Date End",
		name: "dateEnd",
		type: "date",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Status",
		name: "status",
		as: "select",
		component: Form.Select,
		options: [
			{ value: "Active", label: "Active" },
			{ value: "Inactive", label: "Inactive" },
		],
	},
];
