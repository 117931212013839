import styled from "styled-components";
import PropTypes from "prop-types";

const badgeVariant = {
	"blue-inverse": `
  background-color: var(--lyte-blue);
  color: var(--lyte-white);
  `,
	orange: `
  background-color: var(--lyte-bg-orange);
  color: var(--lyte-orange);
  `,
	"orange-inverse": `  background-color: var(--lyte-orange);
  color: var(--lyte-white);`,
	green: `
  background-color: var(--lyte-bg-green);
  color: var(--lyte-green);
  `,
	"green-inverse": `
  background-color: var(--lyte-green);
  color: var(--lyte-white);
  `,
	dark: `
  background-color: var(--lyte-black);
  color: var(--lyte-white);
  `,
	grey: `
  background-color: var(--lyte-grey);
  color: var(--lyte-bg-grey);
  `,
};

const LyteBadge = styled.div`
	display: inline-block;
	padding: 0.35rem 1rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	&:empty {
		display: none;
	}
	margin: 0.25rem 0.75rem;
	background-color: var(--lyte-bg-blue);
	color: var(--lyte-blue);
	${({ variant }) => variant && badgeVariant[variant]}
`;

export default LyteBadge;

LyteBadge.propTypes = {
	variant: PropTypes.string,
};
