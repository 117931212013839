export const STATUS_FILTER_OPTIONS = ["completed", "processing"];
export const CASHOUT_TYPE_FILTER_OPTIONS = [
	{
		label: "Invoice",
		value: "invoice",
	},
	{
		label: "B2B2C Invoice",
		value: "b2b2cinvoice",
	},
	{
		label: "Boost",
		value: "boost",
	},
	{
		label: "Advance",
		value: "advance",
	},
];
