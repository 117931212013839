import currency from "currency.js";
import { Column } from "react-table";
import { Currency, dateFormat } from "../../utils";

export const tableColumns: Column[] = [
	{
		Header: "Business Name",
		accessor: "businessName",
	},
	{
		Header: "Payment Ref",
		accessor: "paymentReference",
	},
	{
		Header: "Invoice Number",
		accessor: "invoiceNumber",
	},
	{
		Header: "Invoice Ref",
		accessor: "invoiceReference",
	},
	{ Header: "User Email", accessor: "userEmail" },
	{
		Header: "Currency",
		accessor: "currency",
	},
	{
		Header: "Amount",
		accessor: "amount",
		Cell: ({ value }: any) => <>{Currency(value, { symbol: "" })}</>,
	},
	{
		Header: "Fee",
		accessor: "paymentFee",
		Cell: ({ value }: any) => <>{Currency(value, { symbol: "" })}</>,
	},
	{
		Header: "Requested Payment Date",
		accessor: "paymentDate",
		Cell: ({ value }: any) => <>{dateFormat(new Date(value))}</>,
	},
	{
		Header: "Actual Payment Date",
		accessor: "actualPaymentDate",
		Cell: ({ value }: any) => (
			<>
				{value ? (
					dateFormat(new Date(value))
				) : (
					<div className="text-center">-</div>
				)}
			</>
		),
	},
];
