import currency from "currency.js";

const Currency = (value: string | number, props?: object) => {
	return currency(value, { symbol: "SGD ", ...props }).format();
};

const CustomCurrency = (value: string | number, props?: object) => {
	return currency(value, { symbol: "SGD ", ...props });
};

export { Currency, CustomCurrency };
