import { routes as dashboardRoutes } from "./dashboard";
import { routes as invoiceRoutes } from "./invoice";
import { routes as userRoutes } from "./user";
import { routes as promocodeRoutes } from "./promocode";
import { routes as boostRoutes } from "./boost";
import { routes as billRoutes } from "./bill";
import { routes as cashoutRoutes } from "./cashout";
import { routes as paymentRoutes } from "./payment";
import { routes as bankAccountRoutes } from "./bankaccount";
import { routes as advanceRoutes } from "./advance";
import { routes as nerveDashboardRoutes } from "./nerve/dashboard";
import { routes as nerveBoostRoutes } from "./nerve/boost";
import { routes as nerveBoostProductRoutes } from "./nerve/boostproduct";
import { routes as nerveUserRoutes } from "./nerve/user";
import { routes as emailLogRoutes } from "./emaillog";
import { routes as smsLogsRoutes } from "./smslogs";

const routes = [
	...dashboardRoutes,
	...invoiceRoutes,
	...userRoutes,
	...promocodeRoutes,
	...boostRoutes,
	...billRoutes,
	...cashoutRoutes,
	...paymentRoutes,
	...bankAccountRoutes,
	...advanceRoutes,
	...nerveDashboardRoutes,
	...nerveBoostRoutes,
	...nerveBoostProductRoutes,
	...nerveUserRoutes,
	...emailLogRoutes,
	...smsLogsRoutes,
];

export default routes;
