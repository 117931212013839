export enum BankAccountDetailsLabel {
	accountHolderName = "Account Holder Name",
	accountNumber = "Account Number",
	bankName = "Bank Name",
	bankCode = "Bank Code",
	countryCode = "Country Code",
	currency = "Currency",
}

export enum UserDetailsLabel {
	id = "Bank Account ID",
	name = "Freelancer Name",
	status = "Verification Status",
}

export enum AdditionalInfo {
	createdAt = "Created At",
	updatedAt = "Updated At",
	verificationAt = "Verification At",
	verificationBy = "Verification By",
	rejectedAt = "Rejected At",
	rejectedBy = "Rejected By",
	rejectReason = "Reject Reason",
}
