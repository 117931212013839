import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const variants = {
	orange: ` background-color: var(--lyte-orange);
  color: var(--lyte-white);`,
	"blue-inverse": `
  background-color: var(--lyte-lightblue);
  color: var(--lyte-blue);
  `,
	green: `
  background-color: var(--lyte-green);
  color: var(--lyte-white);
  `,
	dark: `
  background-color: var(--lyte-black);
  color: var(--lyte-white);
  `,
};

const sizes = {
	lg: `
  padding: 0.75rem 1.75rem;
  border-radius: 8px;
  `,
	sm: `
  padding: 0.1rem 0.5rem;
  font-size: 12px;
  `,
};

const Button = styled.button`
	color: var(--lyte-white);
	background-color: var(--lyte-blue);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	font-weight: 600;

	:hover {
		opacity: 90%;
		color: var(--lyte-white);
	}

	:active {
		transform: scale(0.95);
	}

	${({ variant }) => variant && variants[variant]}
	${({ size }) => size && sizes[size]}
`;

const LyteButton = ({ children, className, ...props }) => {
	return (
		<Button className={"btn " + className} {...props}>
			{children}
		</Button>
	);
};

const netural = "neutral";
const success = "success";
const error = "error";

export const LyteActionButton = ({
	initialState,
	children,
	className,
	onClick,
	onSuccess,
	successMessage,
	onError,
	errorMessage,
	returnToDefault,
	...props
}) => {
	const [state, setState] = React.useState(netural);

	React.useEffect(() => {
		if (initialState) {
			setState(initialState);
		}
	}, [initialState]);

	return (
		<Button
			className={"btn " + className}
			disabled={state === success}
			{...props}
			onClick={async () => {
				try {
					await onClick();
					setState(success);
					onSuccess && (await onSuccess());
				} catch (e) {
					setState(error);
					onError && (await onError());
					returnToDefault && setTimeout(() => setState(netural), 5000);
				}
			}}
		>
			{state === "error"
				? errorMessage
				: state === "success"
				? successMessage
				: children}
		</Button>
	);
};

export default LyteButton;

LyteButton.propTypes = {
	variant: PropTypes.string,
	size: PropTypes.string,
};
