import { useQuery } from "react-query";
import useApi from "./useApi";
import useNerveApi from "./useNerveApi";

const ACCESS_CONTROL_API_ROUTES = {
	getAccessControl: "/admin/accesscontrol"
};

export const UseAllowAccessLP2 = () => {
	const api = useApi(true);
	
	return useQuery("allowaccesslp2", async () => {
		const { data } = await api({
			method: "get",
			url: ACCESS_CONTROL_API_ROUTES.getAccessControl,
		});
		return data;
	}); 
};

export const UseAllowAccessNerve = () => {
	const api = useNerveApi(true);
	
	return useQuery("allowaccessnerve", async () => {
		const { data } = await api({
			method: "get",
			url: ACCESS_CONTROL_API_ROUTES.getAccessControl,
		});
		return data;
	}); 
};