import React from "react";

const InvoiceSubheader = ({
	invoiceClient,
	addressLine1,
	addressLine2,
	fullName,
	country,
	countryCode,
	postalCode,
	mobileCountryCode,
	mobileNumber,
	email,
}) => {
	return (
		<div className="row">
			{/* Left */}
			<div className="col-md-6">
				<p className="text-big mb-2">Invoice to</p>
				<ul className="list-unstyled">
					<li className="mb-1">
						<strong>{invoiceClient.name || ""}</strong>
					</li>
					<li>{invoiceClient.businessName || ""}</li>
					<li>{invoiceClient.addressLine1 || ""}</li>
					<li>
						{invoiceClient.addressLine2 || ""}
						{invoiceClient.addressLine2 && invoiceClient.country && ", "}
						{invoiceClient.country || ""} {invoiceClient.postalCode || ""}
					</li>
					<li className="mt-2">
						{invoiceClient.mobileCountryCode
							? `+${invoiceClient.mobileCountryCode}`
							: ""}{" "}
						{invoiceClient.mobileNumber || ""}
					</li>
					<li>{invoiceClient.email || ""}</li>
				</ul>
			</div>
			{/* Right */}
			<div className="col-md-6 d-flex flex-column justify-content-end text-md-end">
				<p className="text-big mb-2">Invoice from</p>
				<ul className="list-unstyled">
					<li className="mb-1">
						<strong>{fullName || ""}</strong>
					</li>
					<li>{addressLine1 || ""}</li>
					<li>
						{addressLine2 || ""}
						{addressLine2 && countryCode && ", "}
						{countryCode || ""}
					</li>
					<li>
						{countryCode || ""} {postalCode || ""}
					</li>

					<li className="mt-2">
						{mobileCountryCode && mobileCountryCode === "SG"
							? "+65"
							: `+${mobileCountryCode}`}{" "}
						{mobileNumber || ""}
					</li>
					<li>{email || ""}</li>
				</ul>
			</div>
		</div>
	);
};

export default InvoiceSubheader;
