import currency from "currency.js";
import { capitalizeFirstLetter, dateFormat } from "../../../../utils";
import { SELECT_FILTER_OPTIONS } from "../constants";

export const HeaderTypeChecker = (value, type) => {
	switch (type) {
		case "date":
			return value ? dateFormat(value, " DD MMM YYYY") : "";
		case "currency":
			return currency(value || 0, {
				symbol: "$",
			}).format();
		case "status":
			return capitalizeFirstLetter(SELECT_FILTER_OPTIONS[value]);
		default:
			return value;
	}
};
