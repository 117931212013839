import { useMutation, useQuery } from "react-query";

import useApi from "../../hooks/useApi";

import type { IQueryOptions } from "../../interfaces/Global";

export const useUsers = (params: IQueryOptions, options?: object) => {
	const api = useApi();
	return useQuery(
		["users", params],
		async () => {
			const { data } = await api.get(`/admin/users/v2`, {
				params: {
					...params,
				},
			});
			return data;
		},
		{
			enabled: !!api,
			keepPreviousData: true,
			...options,
		}
	);
};

export const useExportUser = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: "admin/users/export/",
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
