import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/common";
import PageTitle from "../../../components/common/LyteTitle";
import { UseGetUserDetails } from "../../../hooks/nerveUser";
import dayjs from "dayjs";

const Info = ({
	label,
	value,
	col_xs,
	col_md,
	...rest
}: {
	label: string;
	value?: React.ReactNode;
	col_xs?: number;
	col_md?: number;
}) => (
	<Col
		xs={col_xs !== undefined ? col_xs : 12}
		md={col_md !== undefined ? col_md : 6}
		className="p-3"
		{...rest}
	>
		<strong>{label}: </strong>
		<p>{value ? value : "-"}</p>
	</Col>
);

const UserDetails = () => {
	const { ref } = useParams<{ ref: string }>();
	const { data } = UseGetUserDetails(ref);

	return !data ? (
		<Loading />
	) : (
		<>
			<PageTitle>User Details - {data?.first_name}</PageTitle>
			<Card bg="light" className="my-5">
				<Card.Body>
					<Row>
						<Info label="Lyte Id" value={data?.lyte_global_id} col_md={12} />
						<Info label="First Name" value={data?.first_name} />
						<Info label="Last Name" value={data?.last_name} />
						<Info
							label="DOB"
							value={data?.date_of_birth ? dayjs(data?.date_of_birth).format("DD MMM YYYY") : "-"}
						/>
						<Info
							label="Registration Completed"
							value={data?.registration_completed_at ? dayjs(data?.registration_completed_at).format("DD MMM YYYY HH:mm") : "-"}
						/>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default UserDetails;
