import toast from "react-hot-toast";
import {
	useMutation,
	UseMutationOptions,
	useQuery,
	UseQueryOptions,
} from "react-query";
import useApi from "../../../hooks/useApi";

interface UpdateNotesVm {
	id: string;
	status: string;
	reason?: string;
}

export const useUpdateBankAccount = (
	opts?: Omit<
		UseMutationOptions<any, unknown, UpdateNotesVm, unknown>,
		"mutationFn"
	>
) => {
	const api = useApi();
	return useMutation(
		async (values: UpdateNotesVm) => {
			const promise = api({
				method: "put",
				url: `/admin/bank-accounts/update-status`,
				data: values,
			});

			toast.promise(promise, {
				loading: "Loading..",
				success: `Bank Account successfully updated`,
				error: `Error update bank account`,
			});

			const { data } = await promise;
			return data;
		},
		{
			...opts,
		}
	);
};

export const useBankAccountDetails = (
	bankId: string,
	opts?: Omit<
		UseQueryOptions<any, unknown, any, string[]>,
		"queryKey" | "queryFn"
	>
) => {
	const api = useApi();
	return useQuery(
		["bank-account-detail", bankId],
		() => api.get(`/admin/bank-accounts/${bankId}`).then((res) => res.data),
		{ ...opts, keepPreviousData: true, enabled: !!bankId }
	);
};

export const useGetBankUploadImage = (
	uploadImage: string,
	opts?: Omit<
		UseQueryOptions<any, unknown, any, string[]>,
		"queryKey" | "queryFn"
	>
) => {
	const api = useApi();
	return useQuery(
		["bank-upload-image", uploadImage],
		() =>
			api
				.get(`/admin/files/${uploadImage}`, {
					responseType: "blob",
				})
				.then((res) => res.data),
		{ ...opts, keepPreviousData: true }
	);
};
