import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi from "../../../hooks/useApi";
import { useHistory } from "react-router-dom";

const PROMO_CODE_API_ROUTES = {
	getPromoCodeById: (id: string) => `/admin/promocodes/id/${id}`,
	updatePromoCode: "/admin/promocodes",
};

export const UseGetPromoCodeById = (id: string) => {
	const api = useApi();
	return useQuery(
		["promocodes", id],
		async () => {
			const { data } = await api({
				method: "get",
				url: PROMO_CODE_API_ROUTES.getPromoCodeById(id),
			});
			data.dateStart = data.dateStart.split("T")[0];
			data.dateEnd = data.dateEnd.split("T")[0];
			if (data.type === "percentage") {
				data.value = data.value * 100;
			}
			return data;
		},
		{
			enabled: !!id,
		}
	);
};

export const UseUpdatePromoCode = () => {
	const api = useApi();
	const { push } = useHistory();
	const queryClient = useQueryClient();
	return useMutation(
		async (values: any) => {
			values.dateStart = new Date(values.dateStart).toISOString();
			values.dateEnd = new Date(values.dateEnd).toISOString();
			if (values.type === "percentage") {
				values.value = values.value / 100;
			}
			const { data } = await api({
				method: "put",
				url: PROMO_CODE_API_ROUTES.updatePromoCode,
				data: values,
			});
			return data;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["promocodes"]);
				push("/promocodes");
			},
		}
	);
};
