import React from "react";
import currency from "currency.js";
import dayjs from "dayjs";
import { Badge, Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
	useGetCashoutByRef,
	usePostCashoutCompleted,
} from "./CashoutDetails.service";

const CashoutDetails = () => {
	const { ref } = useParams<{ ref: string }>();
	const { data } = useGetCashoutByRef(ref);
	const { replace } = useHistory();

	const { mutate, isLoading } = usePostCashoutCompleted({
		onSuccess: () => replace("/cashouts"),
	});
	return !data ? (
		<>Loading...</>
	) : (
		<Modal show onHide={() => replace("/cashouts")} close>
			<Modal.Header closeButton>
				<Modal.Title>Cashout Ref {ref}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroup.Item>
						<strong>Status</strong>
						<Row>
							<Col xs={6}>
								<Badge
									pill
									bg={data.status === "completed" ? "success" : "primary"}
								>
									{data.status}
								</Badge>
							</Col>
							<Col xs={6} className="text-end">
								{data.status === "processing" && (
									<Button
										size="sm"
										variant="outline-dark"
										onClick={() => mutate(ref)}
										disabled={isLoading}
									>
										Mark as disbursed
									</Button>
								)}
							</Col>
						</Row>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>User Name</strong>
						<p>{data.userName}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Cashout Reference</strong>
						<p>{data.cashoutRefNumber}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Cashout Type</strong>
						{data.isB2B2C && data.cashoutType === "invoice" ? (
							<div className="text-capitalize">B2B2C Invoice</div>
						) : (
							<div className="text-capitalize">{data.cashoutType}</div>
						)}
					</ListGroup.Item>

					{data?.boostRefNumber && (
						<ListGroup.Item>
							<strong>Boost Ref</strong>
							<p
								style={{
									cursor: "pointer",
									color: "#3363ff",
									textDecoration: "underline",
								}}
								onClick={() => replace(`/boosts/${data.boostRefNumber}`)}
							>
								{data.boostRefNumber}
							</p>
						</ListGroup.Item>
					)}

					{data?.cashoutType === "invoice" && data?.invoiceRefNumber && (
						<ListGroup.Item>
							<strong>Invoice Ref</strong>
							<p
								style={{
									cursor: "pointer",
									color: "#3363ff",
									textDecoration: "underline",
								}}
								onClick={() => replace(`/invoices/${data.invoiceRefNumber}`)}
							>
								{data.invoiceRefNumber}
							</p>
						</ListGroup.Item>
					)}

					{data?.advanceRefNumber && (
						<ListGroup.Item>
							<strong>Advance Ref</strong>
							<p
								style={{
									cursor: "pointer",
									color: "#3363ff",
									textDecoration: "underline",
								}}
								onClick={() => replace(`/advances/${data.advanceRefNumber}`)}
							>
								{data.advanceRefNumber}
							</p>
						</ListGroup.Item>
					)}

					<ListGroup.Item>
						<strong>Requested Payment Date</strong>
						<p>
							{dayjs(data.createdDateTime).format("DD MMM YYYY, HH:mm:ss a")}
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Actual Payment Date</strong>
						<p>
							{data.paidDateTime
								? dayjs(data.paidDateTime).format("DD MMM YYYY, HH:mm:ss a")
								: "-"}
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Amount &#40;{data.currency}&#41;</strong>
						<p>{currency(data.amount).format()}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Bank Account Holder Name</strong>
						<p>{data.bankAccountHolderName}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Bank Account No.</strong>
						<p>{data.bankAccountNumber}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Bank Code</strong>
						<p>{data.bankCode}</p>
					</ListGroup.Item>
				</ListGroup>
				<div className="p-3"></div>
			</Modal.Body>
		</Modal>
	);
};

export default CashoutDetails;
