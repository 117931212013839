import React from "react";
import styled from "styled-components";

const ClauseOl = styled.ol`
	counter-reset: item;
	margin: 0;
	padding: 0;
`;

const ClauseLi = styled.li`
	margin: 0 0 1rem;
	padding: 0;
	list-style-type: none;

	&::before {
		content: counters(item, ".") " ";
		counter-increment: item;
		margin-right: 1rem;
	}
`;

const Td = styled.td`
	padding-left: 10px;
`;

const Assignment = () => {
	return (
		<>
			<p className="text-uppercase small mb-3 text-decoration-underline">
				Assignment Clause
			</p>
			<ClauseOl>
				<ClauseLi className="text-uppercase">Assignment</ClauseLi>
				<ClauseOl>
					<ClauseLi>
						The Freelancer shall not delegate its obligations under this
						Contract to a third-party, without first receiving the Client’s
						written permission.
					</ClauseLi>

					<ClauseLi>
						The Freelancer shall assign its rights and benefits under this
						Contract to Lyte Ventures Pte Ltd or any of its subsidiaries and
						amounts payable under this Contract shall be paid by either of the
						following:
						<table
							className="table table-sm my-4"
							style={{
								width: "92%",
								margin: "0 auto",
							}}
						>
							<tbody>
								<tr>
									<td colSpan={2}>
										<p className="mb-3 text-decoration-underline">
											Bank Transfer
										</p>
									</td>
								</tr>
								<tr>
									<Td>
										<strong>Bank</strong>
									</Td>
									<Td>Oversea-Chinese Banking Corporation Limited</Td>
								</tr>
								<tr>
									<Td>
										<strong>Account Holder Name</strong>
									</Td>
									<Td>Lytepay Pte Ltd</Td>
								</tr>
								<tr>
									<Td>
										<strong>Swift code</strong>
									</Td>
									<Td>OCBCSGSG</Td>
								</tr>
								<tr>
									<Td>
										<strong>Account number</strong>
									</Td>
									<Td>713-052751-001</Td>
								</tr>
							</tbody>
						</table>
						<div
							style={{
								width: "92%",
								margin: "0 auto",
							}}
						>
							<p className="mb-3 text-decoration-underline">PayNow QR Code</p>
							<img
								src={`${process.env.REACT_APP_CDN}/2021/12/02105014/LytePay-PayNow.png`}
								alt="PayNow QR Code"
							/>
						</div>
					</ClauseLi>
					<ClauseLi>
						The Client may not assign its rights and delegate its obligations
						under this Contract without the Freelancer's permission.
					</ClauseLi>
				</ClauseOl>
			</ClauseOl>
		</>
	);
};

export default Assignment;
