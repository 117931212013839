import styled from "styled-components";

export const Table = styled.table`
	& th:first-child {
		border-radius: 10px 0 0 0px;
		background-color: #122ecd;
	}

	& th:last-child {
		border-radius: 0 10px 0px 0;
		background-color: #122ecd;
	}

	& > :not(:first-child) {
		border-top: 0px solid currentColor;
	}

	& > :not(caption) > * > * {
		border: 0;
		padding: 0.5rem 1rem;
	}
`;

export const Thead = styled.thead`
	font-weight: 600;
	background-color: #122ecd;
	color: #fff;
`;

export const Th = styled.th``;

export const Tbody = styled.tbody`
	& > tr {
		background-color: #f1f4fd;
	}
	${({ disableHover }) =>
		!disableHover &&
		`
    & > tr {
      cursor: pointer;
    }

    & > tr:hover {
      background-color: #ffffff;
    }
  `}
`;

export const Tr = styled.tr``;

export const Td = styled.td``;

export const TotalContainer = styled.div`
	border-top: 1px solid #111;
	border-bottom: 1px solid #111;
`;
