import AdvanceDetails from "../../views/advances/detail/AdvanceDetails";
import AdvanceSummary from "../../views/advances/AdvanceSummary";

import type { RouteProps } from "react-router-dom";

export const routes: RouteProps[] = [
	{
		path: "/advances",
		component: AdvanceSummary,
	},
	{
		path: "/advances/:ref",
		component: AdvanceDetails,
		exact: true,
	},
];
