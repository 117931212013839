import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";

import PageTitle from "../../components/common/LyteTitle";
import Table from "../../components/tables/Table";
import { Button, Form } from "react-bootstrap";
import { columns } from "./Users.columns";
import { useExportUser, useUsers } from "./Users.service";
import { Loading } from "../../components/common";
import { FILTER_OPTIONS } from "./constant";

import type { IQueryOptions, TfetchData } from "../../interfaces/Global";

const Users = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const [filter, setFilter] = useState<{ filter?: string }>({});
	const [sortColumn, setSortColumn] = useState<string | null>(null);
	const [sortDirection, setSortDirection] = useState<boolean | null>(null);

	const { push } = useHistory();

	const urlParams = () => {
		const params: IQueryOptions = {
			page: page || 1,
			rowsPerPage: rowsPerPage || 10,
			searchKey: searchKey || undefined,
			...filter,
		};
		if (sortColumn) params.sortColumn = sortColumn;
		if (sortDirection !== null)
			params.sortDirection = sortDirection ? "Desc" : "Asc";
		return params;
	};

	const { data: users } = useUsers(urlParams());

	const { mutate: mutateExport, isLoading } = useExportUser({
		onSuccess: (result: any) => {
			window.open(
				`${process.env.REACT_APP_API_URL}/public/files/exports/${result}`,
				"_blank"
			);
		},
	});

	const fetchData = useCallback(
		({ pageSize, pageIndex, searchKey, sortBy }: TfetchData) => {
			setSortColumn(sortBy.length > 0 ? sortBy[0].id : null);
			setSortDirection(sortBy.length > 0 ? sortBy[0].desc : null);
			setRowsPerPage(+pageSize);
			setPageCount(Math.ceil(users?.total / pageSize));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[users]
	);

	return !users ? (
		<Loading />
	) : (
		<>
			<div className="d-flex justify-content-between gap-2 mb-2">
				<PageTitle>Users</PageTitle>
				<Button
					variant="primary"
					disabled={isLoading}
					onClick={() => mutateExport()}
				>
					{isLoading ? "Loading..." : "Export"}
				</Button>
			</div>
			<div style={{ height: "70vh", width: "100%" }}>
				<Table
					enableControls
					data={users.data}
					columns={columns}
					rowOnClick={(params: any) =>
						push(`/users/${params.original.lyteIdIdentifier}`)
					}
					fetchData={fetchData}
					totalData={users.total}
					manualPagination
					pageCount={pageCount}
					remoteSearchKey
					tableTitle={undefined}
					footerComponent={undefined}
					disableHover={undefined}
					filterControls={
						<div className="d-flex align-items-center gap-2 flex-wrap">
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										filter: e.target.value || undefined,
									});
								}}
								value={filter?.filter}
							>
								<option value="">Filter</option>
								<>
									{FILTER_OPTIONS.map((option, index) => (
										<option
											key={option.value + index.toString()}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</>
							</Form.Select>
						</div>
					}
					searchPlaceholder={"Search Name..."}
				/>
			</div>
		</>
	);
};

export default Users;
