import CashoutDetails from "../../views/cashouts/detail/CashoutDetails";
import CashoutSummary from "../../views/cashouts/CashoutSummary";

import type { RouteProps } from "react-router-dom";

export const routes: RouteProps[] = [
	{
		path: "/cashouts",
		component: CashoutSummary,
	},
	{
		path: "/cashouts/:ref",
		component: CashoutDetails,
		exact: true,
	},
];
