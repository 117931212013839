export const TYPE_FILTER_OPTIONS = [
	{
		id: "updateuserprofileotp",
		label: "Update User Profile OTP",
		value: "UpdateUserProfileOTP",
	},
	{
		id: "paymentreceived",
		label: "Payment Received",
		value: "PaymentReceived",
	},
	{
		id: "onboardingotp",
		label: "Onboarding OTP",
		value: "OnboardingOTP",
	},
];
