import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";

import PageTitle from "../../../components/common/LyteTitle";
import {
	UseGetBoostProducts,
	UsePushBoostProducts,
} from "../../../hooks/nerveBoostProduct";
import Table from "../../../components/tables/Table";
import { columns } from "./BoostProduct.columns";
import { Button, Form, Modal } from "react-bootstrap";
import { SELECT_FILTER_OPTIONS } from "./constants";

const PushAction = () => {
	const [opened, setOpened] = useState(false);
	const open = () => setOpened(true);
	const close = () => setOpened(false);
	const { mutate: pushBoostProducts, isLoading: isUpdating } =
		UsePushBoostProducts({
			onSettled: () => {
				close();
			},
		});

	return (
		<>
			<Button variant="info" onClick={open}>
				Push
			</Button>
			<Modal centered show={opened} onHide={close}>
				<Form>
					<Modal.Body>
						<p>Are you sure you want to push boost products?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="white"
							className="red"
							onClick={() => {
								close();
							}}
						>
							Cancel
						</Button>
						<Button
							className="btn-sm"
							onClick={() => pushBoostProducts()}
							disabled={isUpdating}
						>
							Yes, I confirm
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

const Boost = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const [filter, setFilter] = useState({});

	const { push } = useHistory();

	const { data: boostProducts } = UseGetBoostProducts({
		query: {
			page: page || undefined,
			rowsPerPage: rowsPerPage || undefined,
			searchKey: searchKey || undefined,
			...filter,
		},
	});

	const fetchData = useCallback(
		({ pageSize, pageIndex, searchKey }) => {
			setRowsPerPage(+pageSize);
			setPageCount(Math.ceil(boostProducts?.total));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[boostProducts]
	);

	return !boostProducts ? (
		"Loading"
	) : (
		<>
			<PageTitle>
				Boost Product <PushAction></PushAction>
			</PageTitle>
			<div style={{ height: "70vh", width: "100%" }}>
				<Table
					enableControls
					data={boostProducts.data}
					columns={columns}
					rowOnClick={(params) =>
						push(`/nerve/boostproduct/${params.values.productId}`)
					}
					fetchData={fetchData}
					manualPagination
					pageCount={pageCount}
					remoteSearchKey
					filterControls={
						<Form.Select
							className="w-auto h-auto small py-1"
							style={{ lineHeight: 1.3 }}
							onChange={(e) => {
								setFilter({
									status: e.target.value || undefined,
								});
							}}
							value={filter?.status}
						>
							{Object.entries(SELECT_FILTER_OPTIONS).map(([value, label]) => (
								<option key={value} value={value}>
									{label}
								</option>
							))}
						</Form.Select>
					}
				/>
			</div>
		</>
	);
};

export default Boost;
