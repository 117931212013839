import type { RouteProps } from "react-router-dom";
import BillDetails from "../../views/bills/detail/BillDetails";
import BillSummary from "../../views/bills/BillSummary";
import BillSimulation from "../../views/bills/simulation/BillSimulation";

export const routes: RouteProps[] = [
	{
		path: "/bills",
		component: BillSummary,
	},
	{
		path: "/bills/simulation/simulate",
		component: BillSimulation,
		exact: true,
	},
	{
		path: "/bills/:id",
		component: BillDetails,
		exact: true,
	},
];
