import PaymentDetails from "../../views/payments/detail/PaymentDetails";
import PaymentSummary from "../../views/payments/PaymentSummary";

import type { RouteProps } from "react-router-dom";

export const routes: RouteProps[] = [
	{
		path: "/payments",
		component: PaymentSummary,
	},
	{
		path: "/payments/:ref",
		component: PaymentDetails,
		exact: true,
	},
];
