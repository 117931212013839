import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "./config";
import { InteractionType } from "@azure/msal-browser";
import { Loading } from "../../components/common";

export const AuthProvider = ({ children }) => (
	<MsalAuthenticationTemplate
		interactionType={InteractionType.Redirect}
		authenticationRequest={loginRequest}
		loadingComponent={Loading}
	>
		{children}
	</MsalAuthenticationTemplate>
);
