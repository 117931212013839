import { format } from "date-fns";

const InvoiceHeader = ({
	invoiceNumber,
	issuedDateTime,
	projectTitle,
	invoiceReference,
	logo,
}) => (
	<header className="grid">
		{/* Left */}
		<div className="g-col-6">
			<ul className="list-unstyled">
				<li className="mb-3">
					<strong>INVOICE</strong>
				</li>
				<li>
					Invoice No.: &nbsp;&nbsp;<strong>{invoiceNumber}</strong>
				</li>
				<li>
					Issued Date:
					<strong>
						&nbsp;&nbsp;
						{format(new Date(issuedDateTime), "dd MMM yyyy")}
					</strong>
				</li>
				{projectTitle && (
					<li>
						Project Title: &nbsp;&nbsp;<strong>{projectTitle}</strong>
					</li>
				)}
			</ul>
		</div>
		{/* Right */}
		<div className="g-col-6 d-flex justify-content-end">
			Logo
			{/* {logo ? <InvoiceTemplate.Logo logo={logo} /> : !disableLogoHint && null} */}
		</div>
	</header>
);

export default InvoiceHeader;
