import { RouteProps } from "react-router";
import CreatePromoCode from "../../views/promocode/create/CreatePromoCode";
import UpdatePromoCode from "../../views/promocode/update/UpdatePromoCode";
import ViewPromoCodes from "../../views/promocode/ViewPromoCodes";

interface CustomRoute extends RouteProps {
	breadcrumb?: string;
}

export const routes: CustomRoute[] = [
	{
		path: "/promocodes",
		component: ViewPromoCodes,
		exact: true,
		breadcrumb: "Promo Codes",
	},
	{
		path: "/promocodes/create",
		component: CreatePromoCode,
		exact: true,
		breadcrumb: "Create Code",
	},
	{
		path: "/promocodes/update/:id",
		component: UpdatePromoCode,
		exact: true,
	},
];
