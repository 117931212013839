import React, { useState } from "react";
import currency from "currency.js";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
	useGetBillById,
	useUpdateBillStatus,
	useExportBillDetails,
} from "./BillDetails.service";
import BillPdf from "./BillPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import useGetContents from "../../../hooks/contents";
import { dateFormat } from "../../../utils";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_FILTER_OPTIONS } from "../constant";

const BillDetails = () => {
	const amountStatuses = ["Paid", "PartiallyPaid"];
	const { id } = useParams<{ id: string }>();
	const { data } = useGetBillById(id, {
		onSuccess: (response: any) => {
			const selectedStatus = STATUS_FILTER_OPTIONS.find(
				(elem) => elem.id === response.billStatus
			);
			const statusData = selectedStatus?.value ?? "";
			setStatus(statusData);
			setAmount(0);
		},
	});
	const [status, setStatus] = useState<string>("");
	const [amount, setAmount] = useState<number>(0);
	const { data: contents } = useGetContents();
	const { mutate: mutateUpdateBillStatus, isLoading } = useUpdateBillStatus();
	const { replace } = useHistory();
	const exportBillDetailsMutation = useExportBillDetails(
		{
			id: id,
		},
		{
			onSuccess: (result: any) => {
				window.open(
					`${process.env.REACT_APP_API_URL}/public/files/exports/${result}`,
					"_blank"
				);
			},
			enabled: !!id,
		}
	);
	if (!data) {
		return <>Loading</>;
	}
	return (
		<Modal size="xl" show onHide={() => replace("/bills")} close>
			<Modal.Header closeButton>
				<Modal.Title>Bill Ref {data.billReference}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col>
						<div className="mb-4">
							<label className="mb-2">Bill Total Amount</label>
							<p>
								{data.billTotalAmount
									? currency(data.billTotalAmount).format()
									: "-"}
							</p>
						</div>
					</Col>
					<Col>
						<div className="mb-4">
							<label className="mb-2">Bill Paid Amount</label>
							<p>
								{data.billPaidAmount
									? currency(data.billPaidAmount).format()
									: "-"}
							</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="mb-4">
							<label className="mb-2">Bill Status</label>
							<Form.Select
								className="w-full h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									const statusData = e.target.value;
									setStatus(statusData);
									if (!amountStatuses.includes(statusData)) {
										setAmount(0);
									}
								}}
								disabled={isLoading}
								value={status}
							>
								{STATUS_FILTER_OPTIONS.map((value, index) => (
									<option
										key={value.value + index.toString()}
										value={value.value}
									>
										{value.label}
									</option>
								))}
							</Form.Select>
						</div>
					</Col>
					<Col>
						<div className="mb-4">
							<label className="mb-2">Amount to Add</label>
							<Form.Control
								type="number"
								min={0}
								max={data.billTotalAmount}
								value={amount}
								onChange={(e) => {
									setAmount(parseFloat(e.target.value));
								}}
								disabled={!amountStatuses.includes(status)}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button
							className="mb-4"
							disabled={isLoading}
							onClick={() => {
								if (isLoading) {
									return;
								}

								mutateUpdateBillStatus({
									id: data.id,
									status: status,
									paidAmount: amount,
								});
							}}
						>
							Save
						</Button>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col className="d-flex justify-content-center gap-3">
						<PDFDownloadLink
							className="btn btn-primary mb-4"
							document={<BillPdf dataSource={data} contents={contents} />}
							fileName={`Bill${data?.billReference}_${dateFormat(
								data?.billDate,
								"DDMMMYY"
							)}-${dateFormat(data?.billDueDate, "DDMMMYY")}`}
						>
							{({ blob, url, loading, error }) =>
								loading ? (
									"Loading document..."
								) : (
									<>
										<FontAwesomeIcon icon={faDownload} className="me-2" />
										Download as PDF
									</>
								)
							}
						</PDFDownloadLink>
						<Button
							className="mb-4"
							disabled={exportBillDetailsMutation.isLoading}
							onClick={() => {
								if (exportBillDetailsMutation.isLoading) {
									return;
								}

								exportBillDetailsMutation.mutate();
							}}
						>
							<FontAwesomeIcon icon={faDownload} className="me-2" />
							Download as CSV
						</Button>
					</Col>
					<Col xs={24} className="text-center">
						<PDFViewer
							showToolbar={false}
							width="100%"
							height={695}
							className="border-none"
						>
							<BillPdf dataSource={data} contents={contents} />
						</PDFViewer>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

export default BillDetails;
