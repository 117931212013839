import React from "react";
import styled from "styled-components";
import { Button, Image } from "react-bootstrap";
import hamburger from "../../assets/hamburger.svg";
import { useMsal } from "@azure/msal-react";
import { useAuth } from "../../hooks/useAuth";

const TopNavBar = styled.div`
	width: 100%;
	border-bottom: 1px solid #e1e1e1;
	height: 55px;
`;

const TopNav = ({ handleToggle }) => {
	const { accounts } = useMsal();
	const { logoutHandler } = useAuth();
	return (
		<TopNavBar>
			<div className="container-fluid py-2 px-4 d-flex justify-content-between align-items-center">
				<div>
					<Button className="d-lg-none" variant="link" onClick={handleToggle}>
						<Image src={hamburger} alt="nav-toggler" width={30} />
					</Button>
				</div>
				<div>
					<img
						src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&size=35&name=${accounts[0].name}`}
						className="rounded-circle me-4"
						alt={accounts[0].name}
					/>
					<button className="btn btn-primary" onClick={logoutHandler}>
						Logout
					</button>
				</div>
			</div>
		</TopNavBar>
	);
};

export default TopNav;
