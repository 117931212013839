import styled from "styled-components";
import PropTypes from "prop-types";

const LyteCard = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	height: auto;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border-radius: 0.25rem;
	margin-bottom: 2rem;
`;

LyteCard.Header = styled.div`
	padding: 0.5rem 0rem;
	margin-bottom: 0;
	font-size: 25px;
	font-weight: 600;
`;

LyteCard.Body = styled.div`
	flex: 1 1 auto;
	padding: 1.5rem 2rem;
	background-color: ${({ bg }) => bg || `#f7f7f7`};
	border-radius: 5px;
	height: auto;
	border: ${({ border }) => border || `none`};
`;

LyteCard.Body.propTypes = {
	bg: PropTypes.string,
	border: PropTypes.string,
};

LyteCard.List = styled.div`
	list-style: none;
	margin: 0rem;
`;

LyteCard.ListGroup = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border: 0;
	border-radius: 5px;
`;

LyteCard.ListGroupItem = styled.div`
	position: relative;
	display: block;
	padding: 1rem 1.5rem;
	background-color: #f7f7f7;
	color: #212529;
	text-decoration: none;
	border-radius: 5px;

	:not(:last-child) {
		margin-bottom: 3px;
	}

	${({ flushed }) =>
		flushed &&
		`
  border-radius: 0;
  :not(:last-child) {
		margin-bottom: 0px;
    border-bottom: 1px solid #e1e1e1;
	}
	:first-child {
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	};
	:last-child {
		border-bottom-right-radius: inherit;
		border-bottom-left-radius: inherit;
	};
  `}
`;

export default LyteCard;
