import { useQuery } from "react-query";
import useApi from "../../../hooks/useApi";

export const useGetEmailLogById = (id: string) => {
	const api = useApi();
	return useQuery(
		["email-logs", id],
		async () => {
			const { data } = await api({
				method: "get",
				url: `/admin/email-logs/${id}`,
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api && !!id,
		}
	);
};
