import { useRef, useState } from "react";
import { Image, Modal } from "react-bootstrap";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useGetBankUploadImage } from "./BankAccountDetails.service";
import styled from "styled-components";

const ImageContainer: any = styled.div`
	position: relative;
	cursor: pointer;
	text-align: center;
	.overlay {
		transition: all 0.3 ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		opacity: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
	}

	&:hover .overlay {
		opacity: 1;
	}
`;

const BankAccountImageHandler = ({ data }: { data: any }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const imageRef = useRef(null);
	const [rotationAngle, setRotationAngle] = useState(0);

	const { data: dataImage } = useGetBankUploadImage(
		data?.bankVerificationUpload,
		{
			enabled: !!data?.bankVerificationUpload,
		}
	);

	const handleImageClick = () => {
		setIsModalVisible(true);
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
	};

	const handleRotateLeft = () => {
		setRotationAngle(rotationAngle - 90);
	};
	const handleRotateRight = () => {
		setRotationAngle(rotationAngle + 90);
	};

	return (
		<div className="p-4">
			<ImageContainer onClick={handleImageClick}>
				<Image
					className="img-fluid"
					style={{ maxHeight: "300px" }}
					src={dataImage?.size > 0 ? URL.createObjectURL(dataImage) : ""}
				/>
				<div className="overlay">Show Image</div>
			</ImageContainer>
			<Modal
				show={isModalVisible}
				onHide={handleModalClose}
				keyboard={false}
				centered
				size="xl"
				backdropClassName={"custom-index"}
				dialogClassName={"custom-dialog"}
			>
				<Modal.Header closeButton />
				<Modal.Body className="pt-2 pb-4" style={{ overflow: "auto" }}>
					<TransformWrapper minScale={0.1} centerZoomedOut={false} centerOnInit>
						{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
							<div
								className="d-flex align-items-center justify-content-between flex-column w-100"
								style={{ height: "90%" }}
							>
								<TransformComponent
									wrapperStyle={{
										width: "90%",
										height: "100%",
										maxHeight: "90%",
										display: "flex",
										justifyContent: "center",
									}}
									contentStyle={{ width: "100%" }}
								>
									<Image
										ref={imageRef}
										style={{
											transform: `rotate(${rotationAngle}deg)`,
										}}
										className="img-fluid"
										src={
											dataImage?.size > 0 ? URL.createObjectURL(dataImage) : ""
										}
									/>
								</TransformComponent>
								<div className="d-flex gap-2 justify-content-center mt-4">
									<button onClick={() => zoomIn()}>Zoom In</button>
									<button onClick={() => zoomOut()}>Zoom Out</button>
									<button onClick={() => handleRotateLeft()}>
										Rotate Left
									</button>
									<button onClick={() => handleRotateRight()}>
										Rotate right
									</button>
									<button
										onClick={() => {
											resetTransform();
											setRotationAngle(0);
										}}
									>
										Reset
									</button>
								</div>
							</div>
						)}
					</TransformWrapper>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default BankAccountImageHandler;
