import React from "react";
import LyteTitle from "../../../components/common/LyteTitle";
import { Loading } from "../../../components/common";
import { DashboardGraph } from "./Dashboard.graph";
import { DashboardSummary } from "./Dashboard.summary";

const Dashboard = () => {
	return (
		<>
			<LyteTitle>Statistics</LyteTitle>
			<DashboardGraph></DashboardGraph>
			<DashboardSummary></DashboardSummary>
		</>
	);
};

export default Dashboard;
