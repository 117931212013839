import UserDetails from "../../../views/nerve/user/UserDetails";
import User from "../../../views/nerve/user/User";

export const routes = [
	{
		path: "/nerve/user",
		exact: true,
		component: User,
		navLink: true,
	},
	{
		path: "/nerve/user/:ref",
		exact: true,
		component: UserDetails,
		navLink: true,
	},
];
