import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs";
import useNerveApi from "./useNerveApi";
import toast from "react-hot-toast";

const BOOST_API_ROUTES = {
	getBoosts: (queryParams) => `/admin/boost?${queryParams}`,
	getBoostDetails: (id) => `/admin/boost/${id}`,
	pushBoostMatured: `/admin/boost/push/boost/matured`,
	pushBoostCashouts: `/admin/boost/push/cashouts`,
};

export const UseGetBoosts = ({ query = {}, options } = {}) => {
	const api = useNerveApi();
	const queryParams = qs.stringify(query);

	return useQuery(
		["nerve/boosts", queryParams],
		async () => {
			const { data } = await api.get(BOOST_API_ROUTES.getBoosts(queryParams));
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...options,
		}
	);
};

export const UseGetBoostDetails = (id) => {
	const api = useNerveApi();

	return useQuery(
		["nerve/boosts", id],
		async () => {
			const { data } = await api.get(BOOST_API_ROUTES.getBoostDetails(id));
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};

export const UsePushBoostMatured = (options) => {
	const api = useNerveApi();
	const queryClient = useQueryClient();

	return useMutation(async (id) => {
		const promise = api({
			method: "post",
			url: BOOST_API_ROUTES.pushBoostMatured,
            data: {
                "id": id
            },
		});

		toast.promise(promise, {
			loading: "Loading..",
			success: `Boost successfully pushed`,
			error: `Error push boost`,
		});

		const { data } = await promise;

		queryClient.invalidateQueries("pushBoostMatured");

		return data;
	}, options);
};

export const UsePushBoostCashouts = (options) => {
	const api = useNerveApi();
	const queryClient = useQueryClient();

	return useMutation(async (ids) => {
		const promise = api({
			method: "post",
			url: BOOST_API_ROUTES.pushBoostCashouts,
            data: {
                "ids": ids
            },
		});

		toast.promise(promise, {
			loading: "Loading..",
			success: `Boost cashout successfully pushed`,
			error: `Error push boost cashout`,
		});

		const { data } = await promise;

		queryClient.invalidateQueries("pushBoostCashout");

		return data;
	}, options);
};