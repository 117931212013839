import { useQuery, useMutation, useQueryClient } from "react-query";
import useApi from "../../../hooks/useApi";
import toast from "react-hot-toast";

export const useGetBillById = (id: string, options: any) => {
	const api = useApi();
	return useQuery(
		["bill", id],
		async () => {
			const { data } = await api({
				method: "get",
				url: `/admin/bills/${id}`,
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api && !!id,
			...options,
		}
	);
};

export const useUpdateBillStatus = (options?: any) => {
	const api = useApi();
	const queryClient = useQueryClient();
	return useMutation(
		async (parameters: any) => {
			const promise = api({
				method: "put",
				url: `/admin/bills/update-status`,
				data: parameters,
			});

			toast.promise(promise, {
				loading: "Loading..",
				success: `Successfully update bill status`,
				error: `Error update bill status`,
			});

			const { data } = await promise;
			queryClient.invalidateQueries("bills");
			return data;
		},
		{
			...options,
		}
	);
};

export const useExportBillDetails = (params: any, options: any) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: `/admin/bills/${params?.id}/export`,
			});
			return data;
		},
		{
			...options,
		}
	);
};
