import { useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../modules/helpers";
import {
	Table,
	Thead,
	Th,
	Tbody,
	Tr,
	Td,
	TotalContainer,
} from "../../tables/Table.styled";

const InvoiceTable = ({
	invoiceItems,
	totalAmount,
	totalAmountDue,
	totalPaidAmount,
}) => {
	const columns = useMemo(
		() => [
			{
				Header: "Item Name",
				accessor: "itemName",
				// width: 180,
			},
			{
				Header: "Description",
				accessor: "description",
				// width: 350,
				Cell: ({ value }) => (
					<div dangerouslySetInnerHTML={{ __html: value }} />
				),
			},
			{
				Header: "Qty",
				accessor: "quantity",
				// width: 100,
				Cell: ({ value }) => <div className="text-center">{value}</div>,
			},
			{
				Header: "Rate",
				accessor: "rate",
				// width: 100,
				Cell: ({ value }) => (
					<div className="text-end">{formatCurrency(value)}</div>
				),
			},
			{
				accessor: "total",
				// width: 200,
				Header: () => <div className="text-end">Total</div>,
				Cell: ({ value }) => (
					<div className="text-end">{formatCurrency(value)}</div>
				),
			},
		],
		[]
	);

	const data = useMemo(() => invoiceItems, [invoiceItems]);

	const defaultColumn = useMemo(
		() => ({
			width: "fit-content",
		}),
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data,
			initialState: { pageIndex: 0 },
			defaultColumn,
		});

	return !data ? (
		"Loading..."
	) : (
		// apply the table props
		<div className={`table-responsive`}>
			<Table className="table w-100" {...getTableProps()}>
				<Thead>
					{
						// Loop over the header rows
						headerGroups.map((headerGroup) => (
							// Apply the header row props
							<Tr {...headerGroup.getHeaderGroupProps()}>
								{
									// Loop over the headers in each row
									headerGroup.headers.map((column) => (
										// Apply the header cell props
										<Th {...column.getHeaderProps()}>
											{
												// Render the header
												column.render("Header")
											}
										</Th>
									))
								}
							</Tr>
						))
					}
				</Thead>
				{/* Apply the table body props */}
				<Tbody disableHover {...getTableBodyProps()}>
					{
						// Loop over the table rows
						rows.map((row) => {
							// Prepare the row for display
							prepareRow(row);
							return (
								// Apply the row props
								<Tr {...row.getRowProps()}>
									{
										// Loop over the rows cells
										row.cells.map((cell) => {
											// Apply the cell props
											return (
												<Td className="td" {...cell.getCellProps()}>
													{
														// Render the cell contents
														cell.render("Cell")
													}
												</Td>
											);
										})
									}
								</Tr>
							);
						})
					}
				</Tbody>
				<tfoot>
					<tr>
						<td colSpan={2}></td>
						<td colSpan={3}>
							<TotalContainer className="mt-4">
								<div className="d-flex justify-content-between pt-2">
									<span>
										<strong>Total:</strong>
									</span>
									<span>{formatCurrency(totalAmount)}</span>
								</div>
								{totalPaidAmount === null && <div className="pb-2"></div>}
								{totalPaidAmount !== null && (
									<>
										<div className="d-flex justify-content-between pt-2">
											<span>
												<strong>Paid to Date</strong>
											</span>
											<span>{formatCurrency(totalPaidAmount)}</span>
										</div>
										<div className="d-flex justify-content-between py-2">
											<span>
												<strong>Total Amount Due</strong>
											</span>
											<span>{formatCurrency(totalAmountDue)}</span>
										</div>
									</>
								)}
							</TotalContainer>
						</td>
					</tr>
				</tfoot>
			</Table>
		</div>
	);
};

export default InvoiceTable;
