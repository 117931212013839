import { useQuery } from "react-query";
import useApi from "./useApi";

export const useGetDashboard = (ref) => {
	const api = useApi();
	return useQuery(
		["dashboard", ref],
		async () => {
			const { data } = await api.get("/admin/dashboard");
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};
