import dayjs from "dayjs";

export type TDateFormat = {
	date?: string | number | dayjs.Dayjs | Date | null;
	format?: string;
};

export const dateFormat = (
	date: TDateFormat["date"],
	format: TDateFormat["format"] = "DD MMM YYYY, h:mm A"
) => {
	if (typeof date === "number" && date?.toString().length === 13) {
		return dayjs.unix(date as number).format(format);
	}
	return dayjs(date).format(format);
};

export const reportDateFormat = (date: TDateFormat["date"]) => {
	return dateFormat(date, "DD MMM YYYY");
};

export const multiDateFormat = (
	date1: TDateFormat["date"],
	date2: TDateFormat["date"],
	format: TDateFormat["format"] = "DD MMM YYYY, h:mm A",
	separator: string = "-"
) => {
	return `${dateFormat(date1, format)} ${separator} ${dateFormat(
		date2,
		format
	)}`;
};
