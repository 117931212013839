import { useQuery } from "react-query";
import useApi from "./useApi";
import PropTypes from "prop-types";

const useInvoiceDetails = (invoiceReference) => {
	const api = useApi();
	return useQuery(
		`invoice${invoiceReference}`,
		() =>
			api.get(`/admin/invoices/${invoiceReference}`).then((res) => res.data),
		{ keepPreviousData: true, enabled: !!invoiceReference }
	);
};

export default useInvoiceDetails;

useInvoiceDetails.propTypes = {
	invoiceReference: PropTypes.string.isRequired,
};
