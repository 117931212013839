export const FILTER_OPTIONS = [
	{
		label: "Fully Onboarded",
		value: "fullyOnboarded",
	},
	{
		label: "Not Fully Onboarded",
		value: "notFullyOnboarded",
	},
	{
		label: "Mobile & Email Verified",
		value: "mobileEmailVerified",
	},
	{
		label: "Mobile & Email Not Verified",
		value: "mobileEmailNotVerified",
	},
	{
		label: "Kyc Approved",
		value: "kycApproved",
	},
	{
		label: "Kyc Not Approved",
		value: "kycNotApproved",
	},
	{
		label: "Bank Account Approved",
		value: "bankAccountApproved",
	},
	{
		label: "Bank Account Not Approved",
		value: "bankAccountNotApproved",
	},
];
