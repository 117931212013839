import { ErrorMessage, Field, Formik } from "formik";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/common";
import {
	UseGetPromoCodeById,
	UseUpdatePromoCode,
} from "./UpdatePromoCode.service";
import {
	updatePromoCodeFields,
	updatePromoCodeSchema,
} from "./UpdatePromoCode.constants";

const UpdatePromoCode = () => {
	const { id }: { id: string } = useParams();
	const { data: promocode } = UseGetPromoCodeById(id);
	const { mutate: updatePromoCode } = UseUpdatePromoCode();

	return !promocode ? (
		<Loading />
	) : promocode.status === "deleted" ? (
		<Redirect to="/promocodes" />
	) : (
		<>
			<h1>Update Promo Code # {id}</h1>
			<Formik
				initialValues={updatePromoCodeSchema.cast(promocode, {
					stripUnknown: true,
				})}
				onSubmit={(values) => updatePromoCode(values as any)}
				enableReinitialize
				validateOnMount
				validationSchema={updatePromoCodeSchema}
			>
				{({ values, submitForm, errors, isSubmitting }) => (
					<>
						<div className="grid my-4">
							{updatePromoCodeFields.map((field) => {
								switch (field.as) {
									case "input":
										return (
											<div className="g-col-12 g-col-md-6">
												<label htmlFor={field.name}>
													{field.label === "Value" &&
														(values.type === "percentage"
															? "Percentage "
															: values.type === "Amount"
															? "Dollar "
															: null)}
													{field.label}
													{field.label === "Value" &&
														(values.type === "percentage"
															? " (%)"
															: values.type === "amount"
															? " ($)"
															: null)}
												</label>
												<Field
													id={field.name}
													name={field.name}
													type={field.type}
													as={field.component}
													disabled={field.disabled}
												/>
												<small className="text-danger">
													<ErrorMessage name={field.name} />
												</small>
											</div>
										);
									case "select":
										return (
											<div className="g-col-12 g-col-md-6">
												<label htmlFor={field.name}>{field.label}</label>
												<Field
													id={field.name}
													name={field.name}
													as={field.component}
												>
													{field?.options?.map(({ value, label }) => (
														<option value={value} label={label} key={value} />
													))}
												</Field>
												<small className="text-danger">
													<ErrorMessage name={field.name} />
												</small>
											</div>
										);
									default:
										return null;
								}
							})}
						</div>
						<Button
							className="px-4"
							onClick={() => submitForm()}
							disabled={!!Object.keys(errors).length || isSubmitting}
						>
							Update
						</Button>
					</>
				)}
			</Formik>
		</>
	);
};

export default UpdatePromoCode;
