import React, { useEffect } from "react";
import {
	Button,
	Col,
	Container,
	Form,
	ListGroup,
	Modal,
	Row,
	Stack,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { Loading } from "../../../components/common";
import {
	useUserUpdateNotes,
	useUserDetails,
	useUserGroups,
} from "./UserDetails.service";

import {
	UserDetailsLabel,
	LytePayDetailsLabel,
	UserBankAccount,
} from "./UserDetails.enum";

interface UserGroup {
	id: string;
	name: string;
}

const UserDetails = () => {
	const { id } = useParams<{ id: string }>();
	const { replace } = useHistory();
	const { data: userGroups } = useUserGroups();
	const [notes, setNotes] = React.useState("");
	const [checkStates, setCheckStates] = React.useState<any>([]);
	const { data, refetch } = useUserDetails(id);

	const { mutate, isLoading } = useUserUpdateNotes();

	const updateUserHandler = () =>
		mutate(
			{
				notes,
				lyteId: id,
				userGroupIds: checkStates
					.filter((value: any) => value.checked)
					.map((value: any) => value.id),
			},
			{ onSuccess: () => refetch() }
		);

	useEffect(() => {
		if (userGroups && data) {
			setNotes(data.notes || "");

			if (data.userGroups) {
				const filled = userGroups.map((group: any) => ({
					id: group.id,
					checked: data.userGroups.some(
						(userGroup: any) => userGroup.id === group.id
					),
				}));

				setCheckStates(filled);
			}
		}
	}, [userGroups, data]);

	if (!userGroups || !data) {
		return <>Loading</>;
	}

	return (
		<Modal show onHide={() => replace("/users")} size="xl">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				{!data ? (
					<div className="text-center">
						<Loading />
					</div>
				) : (
					<Container className="py-4">
						<Row>
							<Col lg={6}>
								<h2>User Details</h2>
								<ListGroup>
									{Object.entries(UserDetailsLabel).map(([key, label]) => (
										<ListGroup.Item className="py-3" key={key}>
											<Row>
												<Col xs={12} md={4}>
													{label}
												</Col>
												<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
													<strong>{data[key]}</strong>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
								<br />
								<h2>Additional Info</h2>
								<ListGroup>
									<ListGroup.Item>
										<Row>
											<Col xs={12} md={4}>
												Notes:
											</Col>
											<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
												<Form.Control
													as="textarea"
													value={notes}
													onChange={(e) => setNotes(e.currentTarget.value)}
												/>
											</Col>
										</Row>
										<Row style={{ marginTop: "10px" }}>
											<Col xs={12} md={4}>
												User Group:
											</Col>
											<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
												{userGroups.map((option: UserGroup, i: number) => (
													<div className="form-check">
														<Form.Check
															id={`cbUserGroup_${i}`}
															name={`cbUserGroup_${i}`}
															value={option.id}
															checked={
																checkStates?.find(
																	(val: any) => val.id === option.id
																)?.checked
															}
															onChange={(e) => {
																const id = parseInt(e.target.value);
																const checked = e.target.checked;
																const newCheckStates = checkStates?.map(
																	(value: any) => {
																		return {
																			id: value.id,
																			checked:
																				value.id === id
																					? checked
																					: value.checked,
																		};
																	}
																);
																setCheckStates(newCheckStates);
															}}
														/>
														<Form.Label htmlFor={`cbUserGroup_${i}`}>
															{option.name}
														</Form.Label>
													</div>
												))}
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={{ span: 4, offset: 4 }}>
												<Stack className="my-3" direction="horizontal" gap={3}>
													<Button
														onClick={updateUserHandler}
														disabled={isLoading}
													>
														Update
													</Button>
												</Stack>
											</Col>
										</Row>
									</ListGroup.Item>
								</ListGroup>
							</Col>
							<Col lg={6}>
								<h2>Lyte Pay Details</h2>
								<ListGroup>
									{Object.entries(LytePayDetailsLabel).map(([key, label]) => (
										<ListGroup.Item className="py-3" key={key}>
											<Row>
												<Col xs={12} md={4}>
													{label}
												</Col>
												<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
													<strong>
														{typeof data[key] === "boolean"
															? data[key].toString().toUpperCase()
															: data[key]}
													</strong>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
									<ListGroup.Item>
										<Row>
											<Col xs={12} md={4}>
												Interested In Lyte Money:
											</Col>
											<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
												<strong>
													{data["interestedInLyteMoney"] === 1 ? "Yes" : "No"}
												</strong>
											</Col>
										</Row>
									</ListGroup.Item>
								</ListGroup>
								<br />
								<h2>User Bank Account</h2>
								<ListGroup>
									{Object.entries(UserBankAccount).map(([key, label]) => (
										<ListGroup.Item className="py-3" key={key}>
											<Row>
												<Col xs={12} md={4}>
													{label}
												</Col>
												<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
													<strong>
														{data.bankAccount
															? typeof data.bankAccount[key] === "boolean"
																? data.bankAccount[key].toString().toUpperCase()
																: data.bankAccount[key]
															: "-"}
													</strong>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
							</Col>
						</Row>
					</Container>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default UserDetails;
