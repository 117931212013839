import { useQuery } from "react-query";
import useApi from "./useApi";

const useGetContents = (options) => {
	const api = useApi();

	return useQuery(
		["contents"],
		async () => {
			const { data } = await api.get(`/admin/contents`);
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...options,
		}
	);
};

export default useGetContents;
