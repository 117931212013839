import React from "react";

const InvoiceTemplate = ({ children }) => (
	<div
		className="bg-white p-4 my-5"
		style={{
			padding: "0 1.25rem",
			border: "1px solid #000",
			borderRadius: "10px",
		}}
	>
		{children}
	</div>
);

export default InvoiceTemplate;
