import React, { useState } from "react";
import {
	Col,
	Container,
	Form,
	ListGroup,
	Modal,
	Row,
	Button,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { Loading } from "../../../components/common";
import {
	useBankAccountDetails,
	useUpdateBankAccount,
} from "./BankAccountDetails.service";

import {
	BankAccountDetailsLabel,
	UserDetailsLabel,
	AdditionalInfo,
} from "./BankAccountDetails.enum";
import { dateFormat } from "../../../utils";
import BankAccountImageHandler from "./BankAccountImageHandler";

const BankAccountDetails = () => {
	const { id } = useParams<{ id: string }>();
	const { replace } = useHistory();
	const [status, setStatus] = useState<string>("approved");
	const [reason, setReason] = useState<string | undefined>("");

	const { data } = useBankAccountDetails(id, {
		onSettled: (data: any) => {},
	});

	const { mutate, isLoading } = useUpdateBankAccount({
		onSettled: () => replace("/bank-account"),
	});

	const handleUpdateApproval = () => {
		mutate({
			id: data.id,
			status,
			reason,
		});
	};

	return (
		<Modal show onHide={() => replace("/bank-account")} size="xl">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				{!data ? (
					<div className="text-center">
						<Loading />
					</div>
				) : (
					<Container className="py-4">
						<Row>
							<Col lg={6}>
								<h2>Bank Account Details</h2>
								<ListGroup>
									{Object.entries(BankAccountDetailsLabel).map(
										([key, label]) => (
											<ListGroup.Item className="py-3" key={key}>
												<Row>
													<Col xs={12} md={4}>
														{label}
													</Col>
													<Col
														xs={12}
														md={8}
														style={{ wordWrap: "break-word" }}
													>
														<strong>{data[key]}</strong>
													</Col>
												</Row>
											</ListGroup.Item>
										)
									)}
								</ListGroup>
								<br />
								<h2>Additional Info</h2>
								<ListGroup>
									{Object.entries(AdditionalInfo).map(([key, label]) => (
										<ListGroup.Item className="py-3" key={key}>
											<Row>
												<Col xs={12} md={4}>
													{label}
												</Col>
												<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
													<strong>
														{key === "rejectReason"
															? data[key]
															: data[key]
															? dateFormat(data[key])
															: data[key]}
													</strong>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
							</Col>
							<Col lg={6}>
								<h2>Freelancer Details</h2>
								<ListGroup>
									{Object.entries(UserDetailsLabel).map(([key, label]) => (
										<ListGroup.Item className="py-3" key={key}>
											<Row>
												<Col xs={12} md={4}>
													{label}
												</Col>
												<Col xs={12} md={8} style={{ wordWrap: "break-word" }}>
													<strong>
														{typeof data[key] === "boolean"
															? data[key].toString().toUpperCase()
															: data[key]}
													</strong>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
								{data?.bankVerificationUpload && (
									<BankAccountImageHandler data={data} />
								)}
								{data?.status === "pending" && (
									<div className="d-flex flex-column gap-2 flex-wrap pt-3">
										<h3>Bank Approval</h3>
										<Form.Select
											className="w-full h-auto small py-1"
											style={{ lineHeight: 1.3 }}
											onChange={(e) => {
												setStatus(e.target.value);
											}}
											value={status}
										>
											<option value="approved">Approve</option>
											<option value="rejected">Reject</option>
										</Form.Select>
										{status === "rejected" && (
											<div className="mt-2">
												<Form.Label>Reason</Form.Label>
												<Form.Control
													type="text"
													placeholder="Input reject reason"
													as="textarea"
													rows={3}
													onChange={(e) => setReason(e.target.value)}
												/>
											</div>
										)}
										<Button
											className="mt-2"
											variant="primary"
											onClick={handleUpdateApproval}
											disabled={isLoading}
										>
											Update
										</Button>
									</div>
								)}
							</Col>
						</Row>
					</Container>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default BankAccountDetails;
