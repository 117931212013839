import type { Column } from "react-table";

export const columns: Column[] = [
	{ accessor: "id", Header: "ID", width: 80 },
	{ accessor: "name", Header: "Full Name", width: 220 },
	{
		accessor: "accountHolderName",
		Header: "Bank Account Holder",
		width: 220,
		Cell: ({ value, row }: { value: any; row: { original: any } }) => (
			<>{`${row?.original?.accountHolderName} - ${row?.original?.accountNumber}`}</>
		),
	},
	{
		accessor: "bankName",
		Header: "Bank",
		width: 220,
		Cell: ({ value, row }: { value: any; row: { original: any } }) => (
			<>{`${row?.original?.bankName} (${row?.original?.bankCode})`}</>
		),
	},
	{
		accessor: "status",
		Header: "Status",
		width: 120,
		Cell: ({ value }) => <div className="text-uppercase">{value}</div>,
	},
];
