import { Form } from "react-bootstrap";
import * as yup from "yup";

export const updatePromoCodeSchema = yup.object({
	id: yup.number().required(),
	code: yup.string().required(),
	type: yup.string().required().oneOf(["amount", "percentage"]),
	value: yup
		.number()
		.required()
		.min(0)
		.when("type", {
			is: (value: string) => value === "Percentage",
			then: (schema) => schema.max(100),
		}),
	maxValue: yup.number().required(),
	currency: yup.string().required().oneOf(["SGD"]).default("SGD"),
	description: yup.string().default(""),
	maxRedemption: yup.number().required(),
	dateStart: yup
		.string()
		.required()
		.default(new Date().toISOString().split("T")[0]),
	dateEnd: yup
		.string()
		.required()
		.default(new Date().toISOString().split("T")[0]),
});

export const updatePromoCodeFields = [
	{
		label: "Code",
		name: "code",
		type: "text",
		as: "input",
		component: Form.Control,
		disabled: true,
	},
	{
		label: "Maximum Value ($)",
		name: "maxValue",
		type: "number",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Type",
		name: "type",
		as: "select",
		component: Form.Select,
		options: [
			{ value: "percentage", label: "percentage" },
			{ value: "amount", label: "amount" },
		],
	},
	{
		label: "Value",
		name: "value",
		type: "number",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Currency",
		name: "maxValue",
		type: "currency",
		as: "select",
		component: Form.Select,
		options: [{ value: "SGD", label: "SGD" }],
	},
	{
		label: "Description",
		name: "description",
		type: "currency",
		as: "textarea",
		component: Form.Control,
		clcomponentsName: "form-control",
		rows: 5,
		style: { resize: "none" },
	},
	{
		label: "Maximum Redemptions",
		name: "maxRedemption",
		type: "number",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Date Start",
		name: "dateStart",
		type: "date",
		as: "input",
		component: Form.Control,
	},
	{
		label: "Date End",
		name: "dateEnd",
		type: "date",
		as: "input",
		component: Form.Control,
	},
];
