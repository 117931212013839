import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";

import PageTitle from "../../../components/common/LyteTitle";
import { UseGetUsers } from "../../../hooks/nerveUser";
import Table from "../../../components/tables/Table";
import { columns } from "./User.columns";

const User = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [searchKey, setSearchKey] = useState("");

	const { push } = useHistory();

	const { data: users } = UseGetUsers({
		query: {
			page: page || undefined,
			rowsPerPage: rowsPerPage || undefined,
			searchKey: searchKey || undefined,
		},
	});

	const fetchData = useCallback(
		({ pageSize, pageIndex, searchKey }) => {
			setRowsPerPage(+pageSize);
			setPageCount(Math.ceil(users?.total));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[users]
	);

	return !users ? (
		"Loading"
	) : (
		<>
			<PageTitle>User</PageTitle>
			<div style={{ height: "70vh", width: "100%" }}>
				<Table
					enableControls
					data={users.data}
					columns={columns}
					rowOnClick={(params) =>
						push(`/nerve/user/${params.values.lyte_global_id}`)
					}
					fetchData={fetchData}
					manualPagination
					pageCount={pageCount}
					remoteSearchKey
					searchPlaceholder={"Search ID..."}
				/>
			</div>
		</>
	);
};

export default User;
