import { useCallback } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../modules/auth/config";

export const useAuth = () => {
	const { instance, accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const loginHandler = useCallback(() => {
		instance.loginRedirect(loginRequest).catch((e) => {
			console.error(e);
		});
	}, [instance]);

	const logoutHandler = useCallback(() => {
		instance.logoutRedirect().catch((e) => {
			console.error(e);
		});
	}, [instance]);

	const getAccessTokenSilently = useCallback(async () => {
		const response = await instance.acquireTokenSilent({
			account: accounts[0],
			scopes: ["User.Read"],
		});
		return response.idToken || null;
	}, [accounts, instance]);

	return {
		loginHandler,
		logoutHandler,
		isAuthenticated,
		getAccessTokenSilently,
	};
};
