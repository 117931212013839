import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/common";
import PageTitle from "../../../components/common/LyteTitle";
import { UseGetBoostProductDetails } from "../../../hooks/nerveBoostProduct";
import dayjs from "dayjs";
import currency from "currency.js";
import { capitalizeFirstLetter } from "../../../utils";
import { string } from "yup/lib/locale";

const Info = ({
	label,
	value,
	...rest
}: {
	label: string;
	value?: React.ReactNode;
}) => (
	<Col xs={12} md={6} className="p-3" {...rest}>
		<strong>{label}: </strong>
		<p>{value ? value : "-"}</p>
	</Col>
);

const BoostProductDetails = () => {
	const { ref } = useParams<{ ref: string }>();
	const { data } = UseGetBoostProductDetails(ref);

	return !data ? (
		<Loading />
	) : (
		<>
			<PageTitle>Boost Product Details</PageTitle>
			<Card bg="light" className="my-5">
				<Card.Body>
					<Row>
						<Info label="Product Name" value={data?.productName} />
						<Info
							label="Product Description"
							value={data?.productDescription}
						/>
						<Info label="Status" value={data?.productStatus} />
						<Info label="Tenor" value={data?.tenor + "d"} />
						<Info label="Rate" value={data?.rate + "%"} />
						<Info
							label="Created Date"
							value={dayjs(data?.createdDateTime).format("DD MMM YYYY")}
						/>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default BoostProductDetails;
