import React from "react";

const LatePayment = ({ invoice }) => {
	return (
		<>
			<p className="text-uppercase small mb-3 text-decoration-underline">
				<u>Late Payment Clause</u>
			</p>
			<p className="mb-3">
				{`All payments and other charges which are not paid on the due date shall
				be subject to a late payment fee of ${
					invoice?.lateFeeRate ? +invoice.lateFeeRate * 100 + "%" : "0%"
				} per month on the outstanding
				amount.`}
			</p>
		</>
	);
};

export default LatePayment;
