import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";

import PageTitle from "../../components/common/LyteTitle";
import Table from "../../components/tables/Table";
import { Loading } from "../../components/common";
import { SELECT_FILTER_OPTIONS } from "./constants";
import { columns } from "./Invoices.columns";
import { useExportInvoice, useInvoices } from "./Invoices.service";

import type { IQueryOptions, TfetchData } from "../../interfaces/Global";
import type { TInvoicesData } from "./Invoices.type";
import { capitalizeFirstLetter } from "../../utils";
import useGetContents from "../../hooks/contents";

const Invoices = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const [filter, setFilter] = useState<any>({});
	const [sortColumn, setSortColumn] = useState<string | null>(null);
	const [sortDirection, setSortDirection] = useState<boolean | null>(null);

	const { push } = useHistory();

	const urlParams = () => {
		const params: IQueryOptions = {
			page: page || 1,
			rowsPerPage: rowsPerPage || 10,
			searchKey: searchKey || undefined,
			...filter,
		};
		if (sortColumn) params.sortColumn = sortColumn;
		if (sortDirection !== null)
			params.sortDirection = sortDirection ? "Desc" : "Asc";
		return params;
	};

	const { data: invoices } = useInvoices(urlParams());
	const { data: dataContents } = useGetContents();

	const { mutate: mutateExport, isLoading } = useExportInvoice({
		onSuccess: (result: any) => {
			window.open(
				`${process.env.REACT_APP_API_URL}/public/files/exports/${result}`,
				"_blank"
			);
		},
	});

	const fetchData = useCallback(
		({ pageSize, pageIndex, searchKey, sortBy }: TfetchData) => {
			setSortColumn(sortBy.length > 0 ? sortBy[0].id : null);
			setSortDirection(sortBy.length > 0 ? sortBy[0].desc : null);
			setRowsPerPage(+pageSize);
			setPageCount(Math.ceil((invoices?.total || 0) / pageSize));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[invoices]
	);

	return !invoices ? (
		<Loading />
	) : (
		<>
			<div className="d-flex justify-content-between gap-2 mb-2">
				<PageTitle>Invoices</PageTitle>
				<Button
					variant="primary"
					disabled={isLoading}
					onClick={() => mutateExport()}
				>
					{isLoading ? "Loading..." : "Export"}
				</Button>
			</div>
			<div style={{ height: "70vh", width: "100%" }}>
				<Table
					enableControls
					data={invoices.data}
					columns={columns}
					rowOnClick={(params: { values: TInvoicesData }) =>
						push(`/invoices/${params.values.invoiceReference}`)
					}
					fetchData={fetchData}
					manualPagination
					pageCount={pageCount}
					remoteSearchKey
					totalData={invoices.total}
					filterControls={
						<div className="d-flex align-items-center gap-2 flex-wrap">
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										businessId: e.target.value || undefined,
									});
								}}
								value={filter?.businessId}
							>
								<option value="">Filter by Business</option>
								<>
									<option key={"0nobusinesses"} value={0}>
										Unattached to any businesses
									</option>
									{dataContents?.businesses?.map(
										(value: any, index: { toString: () => any }) => (
											<option
												key={value?.id + index.toString()}
												value={value?.id}
											>
												{capitalizeFirstLetter(value?.businessName)}
											</option>
										)
									)}
								</>
							</Form.Select>
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										status: e.target.value || undefined,
									});
								}}
								value={filter?.status}
							>
								<option value="">Filter by status</option>
								{SELECT_FILTER_OPTIONS.map(({ label, value }) => (
									<option key={value} value={value}>
										{label}
									</option>
								))}
							</Form.Select>
						</div>
					}
					tableTitle={undefined}
					footerComponent={undefined}
					disableHover={undefined}
					searchPlaceholder={undefined}
				/>
			</div>
		</>
	);
};

export default Invoices;
