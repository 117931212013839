import BoostProductDetails from "../../../views/nerve/boostproduct/BoostProductDetails";
import BoostProduct from "../../../views/nerve/boostproduct/BoostProduct";

export const routes = [
    {
        path: "/nerve/boostproduct",
        exact: true,
        component: BoostProduct,
        navLink: true,
        breadcrumb: "Boost Product",
    },
    {
        path: "/nerve/boostproduct/:ref",
        exact: true,
        component: BoostProductDetails,
        navLink: true,
    },
];