import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs";
import useNerveApi from "./useNerveApi";
import toast from "react-hot-toast";

const BOOST_API_ROUTES = {
	getBoostProducts: (queryParams) => `/admin/boost/products?${queryParams}`,
	getBoostProductDetails: (id) => `/admin/boost/products/${id}`,
	pushBoostProducts: `/admin/boost/push/product`,
};

export const UseGetBoostProducts = ({ query = {}, options } = {}) => {
	const api = useNerveApi();
	const queryParams = qs.stringify(query);

	return useQuery(
		["nerve/boostproducts", queryParams],
		async () => {
			const { data } = await api.get(
				BOOST_API_ROUTES.getBoostProducts(queryParams)
			);
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...options,
		}
	);
};

export const UseGetBoostProductDetails = (id) => {
	const api = useNerveApi();

	return useQuery(
		["nerve/boostproducts", id],
		async () => {
			const { data } = await api.get(
				BOOST_API_ROUTES.getBoostProductDetails(id)
			);
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};

export const UsePushBoostProducts = (options) => {
	const api = useNerveApi();
	const queryClient = useQueryClient();

	return useMutation(async () => {
		const promise = api({
			method: "post",
			url: BOOST_API_ROUTES.pushBoostProducts,
		});

		toast.promise(promise, {
			loading: "Loading..",
			success: `Boost products successfully pushed`,
			error: `Error push boost products`,
		});

		const { data } = await promise;

		queryClient.invalidateQueries("pushBoostProducts");

		return data;
	}, options);
};
