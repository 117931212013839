import { useState } from "react";
import { useClipboard } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import {
	FaCheckCircle,
	FaDollarSign,
	FaPercentage,
	FaSpinner,
	FaTimesCircle,
	FaTrash,
} from "react-icons/fa";

import {
	UseActivatePromoCode,
	UseDeactivatePromoCode,
	UseDeletePromoCode,
} from "./PromoCode.service";
import { Currency, dateFormat } from "../../utils";

import type { CellValue, Column } from "react-table";

export const columns: Column[] = [
	{
		Header: "Id",
		accessor: "id",
	},
	{
		Header: "Code",
		accessor: "code",
		Cell: ({ value }) => <span className="h6 p-0 m- fw-700">{value}</span>,
	},
	{
		Header: "Type",
		accessor: "type",
		Cell: ({ value, row }: { value: CellValue; row: any }) => {
			const cellIsTypePercentage = row.original.type === "percentage";
			const cellIsTypeAmount = row.original.type === "amount";
			if (cellIsTypePercentage) {
				return (
					<>
						<FaPercentage className="me-1 text-info" />
						<span className="text-uppercase">{value}</span>
					</>
				);
			}

			if (cellIsTypeAmount) {
				return (
					<>
						<FaDollarSign className="me-1" />
						<span className="text-uppercase">{value}</span>
					</>
				);
			}
			return value;
		},
	},
	{
		Header: "Value",
		accessor: "value",
		Cell: ({ value, row }: { value: CellValue; row: any }) => {
			const cellIsTypePercentage = row.original.type === "percentage";
			const cellIsTypeAmount = row.original.type === "amount";
			if (cellIsTypePercentage) {
				return (
					<div className="fw-600 text-center">
						{Currency(value * 100, {
							separator: ",",
							symbol: " %",
							precision: 2,
							pattern: `# !`,
						})}
					</div>
				);
			}

			if (cellIsTypeAmount) {
				return (
					<span className="fw-600">
						{Currency(value, {
							separator: ",",
							symbol: row.original.currency + " ",
							precision: 2,
						})}
					</span>
				);
			}
			return value;
		},
	},
	{
		Header: "Max Value",
		accessor: "maxValue",
		width: 200,
		Cell: ({ value, row }: { value: CellValue; row: any }) => (
			<>
				{Currency(value, {
					separator: ",",
					symbol: row.original.currency + " ",
					precision: 2,
				})}
			</>
		),
	},
	{
		Header: "Start",
		accessor: "dateStart",
		Cell: ({ value }) => dateFormat(value, "DD MMM YYYY"),
	},
	{
		Header: "End",
		accessor: "dateEnd",
		Cell: ({ value }) => dateFormat(value, "DD MMM YYYY"),
	},
	{
		Header: "Redeem",
		accessor: "timesRedeemed",
		Cell: ({ value }) =>
			Currency(value, { separator: ",", symbol: "", precision: 0 }),
	},
	{
		Header: "Max Redeem",
		accessor: "maxRedemption",
		Cell: ({ value }) =>
			Currency(value, { separator: ",", symbol: "", precision: 0 }),
	},
	{
		Header: "Status",
		accessor: "status",
		Cell: ({ value }) => {
			switch (value) {
				case "active":
					return (
						<div className="d-flex align-items-center">
							<FaCheckCircle color="green" className="me-2" />
							<span className="text-capitalize fw-600">{value}</span>
						</div>
					);
				case "inactive":
					return (
						<div className="d-flex align-items-center">
							<FaTimesCircle color="orange" className="me-2" />
							<span className="text-capitalize fw-600">{value}</span>
						</div>
					);
				case "deleted":
					return (
						<div className="d-flex align-items-center">
							<FaTrash color="red" className="me-2" />
							<span className="text-capitalize fw-600">cancelled</span>
						</div>
					);

				default:
					return value;
			}
		},
	},
	{
		Header: "",
		accessor: "updatedDateTime",
		Cell: ({ row }: any) => {
			const { mutate: activateCode, isLoading: activateIsLoading } =
				UseActivatePromoCode();
			const { mutate: deactivateCode, isLoading: deactivateIsLoading } =
				UseDeactivatePromoCode();
			const { mutate: deleteCode } = UseDeletePromoCode();
			const [show, setShow] = useState(false);
			const handleShow = () => setShow(true);
			const handleClose = () => setShow(false);

			const clipboard = useClipboard({ timeout: 500 });
			return row.original.status !== "deleted" ? (
				<>
					<Modal
						show={show}
						onHide={handleClose}
						backdrop="static"
						keyboard={false}
					>
						<Modal.Header closeButton>
							<Modal.Title>
								Cancel Promo
								<span className="text-primary"> {row.original.code}</span>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<h5 className="font-weight-bolder">
								This action cannot be reversed
							</h5>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="warning"
								onClick={() => deleteCode(row.original.id)}
							>
								Yes, I'm sure
							</Button>
							<Button variant="danger" onClick={handleClose}>
								Cancel
							</Button>
						</Modal.Footer>
					</Modal>
					<DropdownButton size="sm" autoClose="outside" title="">
						<Dropdown.Item
							as={NavLink}
							to={`/promocodes/update/${row.original.id}`}
						>
							Update
						</Dropdown.Item>
						<Dropdown.Item
							disabled={activateIsLoading}
							onClick={() => activateCode(row.original.id)}
						>
							{activateIsLoading && <FaSpinner />}
							Activate
						</Dropdown.Item>
						<Dropdown.Item
							disabled={deactivateIsLoading}
							onClick={() => deactivateCode(row.original.id)}
						>
							{deactivateIsLoading && <FaSpinner />}
							Deactivate
						</Dropdown.Item>
						<Dropdown.Item onClick={() => clipboard.copy(row.original.code)}>
							{clipboard.copied ? (
								<span className="fw-800">Copied!</span>
							) : (
								"Copy Code"
							)}
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item className="text-danger" onClick={handleShow}>
							Cancel
						</Dropdown.Item>
					</DropdownButton>
				</>
			) : null;
		},
	},
];
