import { useQuery } from "react-query";
import qs from "qs";
import useNerveApi from "./useNerveApi";

const DASHBOARD_API_ROUTES = {
	getGraph: (ref) => `/admin/dashboard/graph?TimeFrame=${ref}`,
	getSummary: `/admin/dashboard/summary`,
};

export const UseGetGraph = (ref) => {
	const api = useNerveApi();

	return useQuery(
		["nerve/dashboard/graph", ref],
		async () => {
			const { data } = await api.get(DASHBOARD_API_ROUTES.getGraph(ref));
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};

export const UseGetSummary = () => {
	const api = useNerveApi();

	return useQuery(
		["nerve/dashboard/summary"],
		async () => {
			const { data } = await api.get(DASHBOARD_API_ROUTES.getSummary);
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
		}
	);
};