import BoostDetails from "../../views/boosts/detail/BoostDetails";
import BoostSummary from "../../views/boosts/BoostSummary";

import type { RouteProps } from "react-router-dom";

export const routes: RouteProps[] = [
	{ path: "/boosts", exact: true, component: BoostSummary },
	{
		path: "/boosts/:boostRef",
		exact: true,
		component: BoostDetails,
	},
];
