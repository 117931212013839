import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

import PageTitle from "../../components/common/LyteTitle";
import { Loading } from "../../components/common";
import Table from "../../components/tables/Table";
import { capitalizeFirstLetter } from "../../utils";
import { tableColumns } from "./AdvanceSummary.columns";
import { useExportAdvance, useGetAdvances } from "./Advance.service";
import useGetContents from "../../hooks/contents";

import type { IQueryOptions, TfetchData } from "../../interfaces/Global";
import { ADVANCE_TYPE_FILTER_OPTIONS, STATUS_FILTER_OPTIONS } from "./constant";

const AdvanceSummary = () => {
	const { push } = useHistory();
	const [page, setPage] = React.useState<number>(1);
	const [pageSize, setPageSize] = React.useState<number>(10);
	const [pageCount, setPageCount] = React.useState<number>(0);
	const [rowCountState, setRowCountState] = React.useState<number>(0);
	const [searchKey, setSearchKey] = React.useState("");
	const [filter, setFilter] = React.useState<{
		status?: string;
		advanceType?: string;
		businessId?: string;
	}>({});
	const [sortColumn, setSortColumn] = React.useState<string | null>(null);
	const [sortDirection, setSortDirection] = React.useState<boolean | null>(
		null
	);

	const urlParams = () => {
		const params: IQueryOptions = {
			page: page || 1,
			rowsPerPage: pageSize || 10,
			searchKey: searchKey || undefined,
			...filter,
		};
		if (sortColumn) params.sortColumn = sortColumn;
		if (sortDirection !== null)
			params.sortDirection = sortDirection ? "Desc" : "Asc";
		return params;
	};

	const { data, refetch } = useGetAdvances(urlParams());
	const { data: dataContents } = useGetContents();

	const { mutate: mutateExport, isLoading } = useExportAdvance({
		onSuccess: (result: any) => {
			window.open(
				`${process.env.REACT_APP_API_URL}/public/files/exports/${result}`,
				"_blank"
			);
		},
	});

	React.useEffect(() => {
		setRowCountState((prevRowCountState) =>
			data?.total !== undefined ? data?.total : prevRowCountState
		);
	}, [data?.total]);

	const fetchData = React.useCallback(
		({ pageSize, pageIndex, searchKey, sortBy }: TfetchData) => {
			setSortColumn(sortBy.length > 0 ? sortBy[0].id : null);
			setSortDirection(sortBy.length > 0 ? sortBy[0].desc : null);
			setPageSize(+pageSize);
			setPageCount(Math.ceil(data?.total / pageSize));
			setPage(pageIndex);
			setSearchKey(searchKey);
		},
		[data]
	);

	React.useEffect(() => {
		refetch();
	}, [filter, searchKey]);

	return !data ? (
		<Loading />
	) : (
		<>
			<div className="d-flex justify-content-between gap-2 mb-2">
				<PageTitle>Advances</PageTitle>
				<Button
					variant="primary"
					disabled={isLoading}
					onClick={() => mutateExport()}
				>
					{isLoading ? "Loading..." : "Export"}
				</Button>
			</div>
			<div style={{ height: "70vh", width: "100%" }}>
				<Table
					enableControls
					data={data.data}
					columns={tableColumns}
					fetchData={fetchData}
					manualPagination
					pageCount={pageCount}
					remoteSearchKey
					defaultPageSize={10}
					rowPerPageOptions={[10, 25, 50, 100]}
					totalData={data.total}
					filterControls={
						<div className="d-flex align-items-center gap-2 flex-wrap">
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										status: e.target.value || undefined,
									});
								}}
								value={filter?.status}
							>
								<option value="">Filter by Status</option>
								<>
									{STATUS_FILTER_OPTIONS.map((value, index) => (
										<option
											key={value.value + index.toString()}
											value={value.value}
										>
											{value.label}
										</option>
									))}
								</>
							</Form.Select>
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										advanceType: e.target.value || undefined,
									});
								}}
								value={filter?.advanceType}
							>
								<option value="">Filter by Type</option>
								<>
									{ADVANCE_TYPE_FILTER_OPTIONS.map((value, index) => (
										<option
											key={value.value + index.toString()}
											value={value.value}
										>
											{value.label}
										</option>
									))}
								</>
							</Form.Select>
							<Form.Select
								className="w-auto h-auto small py-1"
								style={{ lineHeight: 1.3 }}
								onChange={(e) => {
									setFilter({
										...filter,
										businessId: e.target.value || undefined,
									});
								}}
								value={filter?.businessId}
							>
								<option value="">Filter by Business</option>
								<>
									{dataContents?.businesses?.map(
										(value: any, index: { toString: () => any }) => (
											<option
												key={value?.id + index.toString()}
												value={value?.id}
											>
												{capitalizeFirstLetter(value?.businessName)}
											</option>
										)
									)}
								</>
							</Form.Select>
						</div>
					}
					tableTitle={undefined}
					rowOnClick={({ values }: any) => {
						push(`/advances/${values.referenceNumber}`);
					}}
					footerComponent={undefined}
					disableHover={undefined}
					searchPlaceholder={"Search..."}
				/>
			</div>
		</>
	);
};

export default AdvanceSummary;
