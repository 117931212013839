import { UseMutationOptions, useMutation } from "react-query";
import useApi from "../../../hooks/useApi";
import { queryString } from "../../../utils/helper";

interface BillSimulationVm {
	businessId: string;
	billDate: string;
}

export const useSimulateBills = (
	opts?: Omit<
		UseMutationOptions<any, unknown, BillSimulationVm, unknown>,
		"mutationFn"
	>
) => {
	const api = useApi();
	return useMutation(
		async (values: BillSimulationVm) => {
			const promise = api({
				method: "post",
				url: `/admin/bills/simulation?${queryString(values)}`,
			});

			const { data } = await promise;
			return data;
		},
		{
			...opts,
		}
	);
};
