import {
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from "react-query";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom";

import type { IPromoCodeData } from "./PromoCode.type";
import type { IQueryOptions, IResponse } from "../../interfaces/Global";

const PROMO_CODE_API_ROUTES = {
	getAllPromoCodes: "/admin/promocodes/v2",
	getPromoCodeById: (id: string) => `/admin/promocodes/id/${id}`,
	postNewPromoCode: "/admin/promocodes",
	updatePromoCode: "/admin/promocodes",
	activatePromoCode: (id: string) => `/admin/promocodes/activate/${id}`,
	deactivatePromoCode: (id: string) => `/admin/promocodes/deactivate/${id}`,
	deletePromoCode: (id: string) => `/admin/promocodes/delete/${id}`,
	exportPromoCode: "admin/promocodes/export",
};

export const UseGetPromoCodes = (
	params: IQueryOptions,
	options?: object
): UseQueryResult<IResponse<IPromoCodeData[]>> => {
	const api = useApi();
	return useQuery(
		["promocodes", params],
		async () => {
			const { data } = await api({
				method: "get",
				url: PROMO_CODE_API_ROUTES.getAllPromoCodes,
				params,
			});
			return data;
		},
		{
			keepPreviousData: true,
			enabled: !!api,
			...options,
		}
	);
};

export const UseGetPromoCodeById = (id: string) => {
	const api = useApi();
	return useQuery(
		["promocodes", id],
		async () => {
			const { data } = await api({
				method: "get",
				url: PROMO_CODE_API_ROUTES.getPromoCodeById(id),
			});
			data.dateStart = data.dateStart.split("T")[0];
			data.dateEnd = data.dateEnd.split("T")[0];
			if (data.type === "percentage") {
				data.value = data.value * 100;
			}
			return data;
		},
		{
			enabled: !!id,
		}
	);
};

export const UsePostNewPromoCode = () => {
	const { push } = useHistory();
	const api = useApi();
	return useMutation(
		async (values: any) => {
			values.dateStart = new Date(values.dateStart).toISOString();
			values.dateEnd = new Date(values.dateEnd).toISOString();
			if (values.type === "Percentage") {
				values.value = values.value / 100;
			}
			const { data } = await api({
				method: "post",
				url: PROMO_CODE_API_ROUTES.postNewPromoCode,
				data: values,
			});
			return data;
		},
		{
			onSuccess: () => push("/promocodes"),
		}
	);
};

export const UseUpdatePromoCode = () => {
	const api = useApi();
	const { push } = useHistory();
	const queryClient = useQueryClient();
	return useMutation(
		async (values: any) => {
			values.dateStart = new Date(values.dateStart).toISOString();
			values.dateEnd = new Date(values.dateEnd).toISOString();
			if (values.type === "percentage") {
				values.value = values.value / 100;
			}
			const { data } = await api({
				method: "put",
				url: PROMO_CODE_API_ROUTES.updatePromoCode,
				data: values,
			});
			return data;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["promocodes"]);
				push("/promocodes");
			},
		}
	);
};

export const UseActivatePromoCode = () => {
	const api = useApi();
	const queryClient = useQueryClient();
	return useMutation(
		async (id: string) => {
			const { data } = await api({
				method: "put",
				url: PROMO_CODE_API_ROUTES.activatePromoCode(id),
			});
			return data;
		},
		{
			onSuccess: () => queryClient.invalidateQueries(["promocodes"]),
		}
	);
};

export const UseDeactivatePromoCode = () => {
	const api = useApi();
	const queryClient = useQueryClient();
	return useMutation(
		async (id: string) => {
			const { data } = await api({
				method: "put",
				url: PROMO_CODE_API_ROUTES.deactivatePromoCode(id),
			});
			return data;
		},
		{
			onSuccess: () => queryClient.invalidateQueries(["promocodes"]),
		}
	);
};

export const UseDeletePromoCode = () => {
	const api = useApi();
	const queryClient = useQueryClient();
	return useMutation(
		async (id: string) => {
			const { data } = await api({
				method: "put",
				url: PROMO_CODE_API_ROUTES.deletePromoCode(id),
			});
			return data;
		},
		{
			onSuccess: () => queryClient.invalidateQueries(["promocodes"]),
		}
	);
};

export const useExportPromoCode = (extraParams?: object) => {
	const api = useApi();
	return useMutation(
		async () => {
			const { data } = await api({
				method: "post",
				url: PROMO_CODE_API_ROUTES.exportPromoCode,
			});
			return data;
		},
		{
			...extraParams,
		}
	);
};
