import { Badge } from "react-bootstrap";
import PropTypes from "prop-types";

const StatusBadge = ({ status, className, variant, text }) => {
	const statusVariant = (status) => {
		switch (status) {
			case "pending":
				return "secondary";
			case "rejected":
				return "danger";
			case "accepted":
				return "success";
			default:
				return "primary";
		}
	};

	return (
		<Badge
			variant="outline"
			bg={variant || statusVariant(status)}
			pill
			className={`w-100 ${className}`}
			text={text}
		>
			<p
				className="p-0 m-0 py-1 fw-500"
				style={{
					fontSize: "0.85rem",
					letterSpacing: "0.02rem",
					textTransform: "uppercase",
				}}
			>
				{status.replace(/_/g, " ").replace(/-/g, " ")}
			</p>
		</Badge>
	);
};

StatusBadge.defaultProps = {
	className: "",
	variant: "primary",
	text: "",
};

StatusBadge.propTypes = {
	status: PropTypes.string.isRequired,
	className: PropTypes.string,
	variant: PropTypes.string,
	text: PropTypes.string,
};

export default StatusBadge;
