export const STATUS_FILTER_OPTIONS = [
	{
		label: "Available",
		value: "Available",
	},
	{
		label: "Cashout Pending",
		value: "CashoutPending",
	},
	{
		label: "Completed",
		value: "Completed",
	},
	{
		label: "Expired",
		value: "Expired",
	},
];

export const ADVANCE_TYPE_FILTER_OPTIONS = [
	{
		label: "Sponsored",
		value: "Sponsored",
	},
	{
		label: "Non Sponsored",
		value: "NonSponsored",
	},
];
