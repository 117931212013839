import React, { useState } from "react";
import styled from "styled-components";
import SideNav from "./SideNav";
import TopNav from "./TopNav";

/* ------------------------ */
/*    Layout Component      */
/* ------------------------ */

const MainGrid = styled.main`
	display: grid;
	grid-template-columns: 242px auto;
`;

const Col1 = styled.div`
	grid-column: 1/2;
	width: 242px;
`;

const Col2 = styled.div`
	grid-column: 1/-1;

	@media (min-width: 1024px) {
		grid-column: 2/3;
	}
`;

const Layout = ({ children }) => {
	const [show, setShow] = useState(false);

	const handleToggle = () => {
		setShow((p) => !p);
	};
	return (
		<MainGrid>
			<Col1>
				<SideNav show={show} handleToggle={handleToggle} />
			</Col1>
			<Col2>
				<TopNav handleToggle={handleToggle} />
				<div
					className="container-fluid py-5 px-5 p-lg-5"
					style={{ maxWidth: "1600px" }}
				>
					{children}
				</div>
			</Col2>
		</MainGrid>
	);
};

export default Layout;
