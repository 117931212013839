import { NavLink, useLocation } from "react-router-dom";
import { Image, Nav } from "react-bootstrap";
import logo from "../../assets/logo.svg";
import { ProSidebar, SidebarContent, SidebarHeader } from "react-pro-sidebar";
import { UseAllowAccessLP2 } from "../../hooks/accesscontrol";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const NAV_MENU_LP2 = [
	{ url: "invoices", name: "Invoices" },
	{ url: "users", name: "Users" },
	{ url: "promocodes", name: "Promo Codes" },
	{ url: "boosts", name: "Boosts" },
	{ url: "cashouts", name: "Cashouts" },
	{
		name: "B2B2C",
		subNav: [
			{
				url: "bills",
				name: "Bills",
			},
			{
				url: "payments",
				name: "Payments",
			},
			{
				url: "advances",
				name: "Advances",
			},
		],
	},
	{
		name: "Logs",
		subNav: [
			{
				url: "email-logs",
				name: "Email",
			},
			{
				url: "sms-logs",
				name: "SMS",
			},
		],
	},
	{ url: "bank-account", name: "Bank Account" },
];

const NavMenu = ({ menu, depth }) => {
	const [subNav, setSubNav] = useState(true);

	const nav = menu.subNav ? (
		<>
			<div className="nav-link" style={{ marginLeft: depth * 10 + "px" }}>
				{menu.name}
				<button
					className="btn float-end p-0"
					onClick={() => setSubNav(!subNav)}
				>
					<FontAwesomeIcon icon={subNav ? faChevronUp : faChevronDown} />
				</button>
			</div>
			{subNav &&
				menu.subNav.map((menu, i) => (
					<NavMenu key={i} menu={menu} depth={depth + 1} />
				))}
		</>
	) : (
		<NavLink
			className="nav-link"
			style={{ marginLeft: depth * 10 + "px" }}
			activeClassName="active"
			isActive={(match, location) => {
				return location?.pathname.includes(match?.url);
			}}
			to={`/${menu.url}`}
		>
			{menu.name}
		</NavLink>
	);

	return nav;
};

const getNavMenu = (navMenu, show) => {
	if (!show) return null;

	return (
		<>
			<Nav className="flex-column px-2">
				{navMenu.map((menu, i) => (
					<NavMenu key={i} menu={menu} depth={1} />
				))}
			</Nav>
		</>
	);
};

const SideNav = ({ show, handleToggle }) => {
	const { data: allowAccessLp2 } = UseAllowAccessLP2();

	return (
		<ProSidebar
			width="242px"
			breakPoint="lg"
			style={{ height: "100vh", position: "fixed" }}
			toggled={show}
			onToggle={handleToggle}
		>
			<SidebarHeader style={{ border: "0px" }}>
				<NavLink to="/">
					<Image
						src={logo}
						alt="lp2-admin-logo"
						style={{
							marginLeft: "15px",
							marginTop: "10px",
							marginBottom: "10px",
							height: "50px",
						}}
					/>
				</NavLink>
			</SidebarHeader>
			<SidebarContent>
				{getNavMenu(NAV_MENU_LP2, allowAccessLp2)}
			</SidebarContent>
		</ProSidebar>
	);
};
export default SideNav;
