const Color = {
	neutral0: "#ffffff",
	neutral100: "#1c1c1c",
	navi50: "#EBEFFF",
	navi100: "#C0CFFF",
	navi200: "#A1B7FF",
	navi300: "#7696FF",
	navi400: "#5C82FF",
	navi500: "#3363ff",
	navi600: "#2E5AE8",
	navi700: "#2446B5",
	navi800: "#1C368C",
	navi900: "#152A6B",
	dark50: "#F4F5F6",
	dark100: "#CDD3D9",
	dark200: "#B5BEC7",
	dark300: "#93A0AD",
	dark400: "#7E8D9D",
	dark500: "#5E7185",
	dark600: "#566779",
	dark700: "#43505E",
	dark800: "#343E49",
	dark900: "#272F38",
	empathy50: "#FCEBF1",
	empathy100: "#F5C1D4",
	empathy200: "#F1A3BF",
	empathy300: "#EA79A1",
	empathy400: "#E65F8F",
	empathy500: "#E03773",
	empathy600: "#CC3269",
	empathy700: "#9F2752",
	empathy800: "#7B1E3F",
	empathy900: "#5E1730",
	foresight50: "#FEF8E6",
	foresight100: "#FBEAB1",
	foresight200: "#F9DF8B",
	foresight300: "#F6D156",
	foresight400: "#F5C835",
	foresight500: "#F2BA03",
	foresight600: "#DCA903",
	foresight700: "#AC8402",
	foresight800: "#856602",
	foresight900: "#664E01",
	integrity50: "#EDF8F5",
	integrity100: "#C8E8E0",
	integrity200: "#AEDDD1",
	integrity300: "#89CDBC",
	integrity400: "#72C4AF",
	integrity500: "#4FB59B",
	integrity600: "#48A58D",
	integrity700: "#38816E",
	integrity800: "#2B6455",
	integrity900: "#214C41",
};

export default Color;
