import React from "react";
import { format, addDays } from "date-fns";

const InvoiceFooter = ({ issuedDateTime, paymentDue, children }) => {
	return (
		<div className="bg-light p-3">
			<p className="mb-1">
				<strong>Payment Information and Terms</strong>
			</p>
			<p>
				{" "}
				Payment due on{" "}
				<strong>
					{issuedDateTime
						? format(
								addDays(new Date(issuedDateTime), paymentDue),
								"dd MMM yyyy"
						  )
						: null}
				</strong>
				.
			</p>
			{children}
		</div>
	);
};

export default InvoiceFooter;
