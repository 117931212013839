import { StyleSheet } from "@react-pdf/renderer";
import Color from "../../../utils/colours";

export const styles = StyleSheet.create({
	page: {
		backgroundColor: Color.neutral0,
		padding: "24px 32px",
		color: Color.dark900,
		fontSize: 10,
		width: "100%",
		fontFamily: "Helvetica",
	},
	container: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		position: "relative",
		width: "100%",
		height: 110,
		marginBottom: 24,
	},
	leftHeaderContainer: {
		width: "40%",
		flexDirection: "column",
		justifyContent: "space-between",
		paddingRight: 20,
	},
	rightHeaderContainer: {
		paddingleft: 20,
		flexDirection: "column",
		alignItems: "flex-end",
		width: "60%",
		fontSize: 8,
	},
	rightHeaderDateContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		padding: "4px 8px",
	},
	rightHeaderDateDivider: {
		height: "100%",
		width: 1,
		backgroundColor: Color.dark100,
		margin: "0 16px",
	},
	rightHeaderTotalEach: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		borderBottom: `1px solid ${Color.dark100}`,
		padding: "4px 8px",
	},

	table: {
		display: "table",
		border: "none",
		width: "100%",
		marginTop: 8,
		marginBottom: 32,
	},
	thead: {
		display: "table-header-group",
		backgroundColor: Color.dark50,
		verticalAlign: "middle",
		fontWeight: "bold",
		fontFamily: "Custom-Bold",
		fontSize: 10,
	},
	tbody: {
		display: "table-row-group",
		backgroundColor: Color.neutral0,
		verticalAlign: "middle",
		fontSize: 10,
	},
	tfoot: {
		display: "table-footer-group",
		backgroundColor: Color.navi50,
		verticalAlign: "middle",
		padding: "4px 8px",
		textAlign: "right",
		fontSize: 10,
		borderTop: `1px solid ${Color.navi400}`,
	},
	tableRow: {
		display: "table-row",
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	tableCell: {
		display: "table-cell",
		padding: "4px 8px",
	},
	logo: {
		width: 78,
		height: 48,
		marginTop: "-12px",
		marginLeft: "-12px",
	},
	bigText: {
		fontSize: 12,
		fontWeight: 700,
		fontFamily: "Custom-Bold",
	},
	smallText: {
		fontSize: 8,
	},
	fontBold: { fontWeight: 700, fontFamily: "Custom-Bold" },
	fontSemiBold: { fontWeight: 500 },
	fontUppercase: { textTransform: "uppercase" },

	paymentPage: {
		padding: "16px",
		borderRadius: "8px",
		backgroundColor: Color.navi50,
	},
	paymentClauses: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		paddingBottom: "12px",
		marginBottom: "12px",
		borderBottom: `1px solid ${Color.dark100}`,
	},
	paymentBankDetail: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		alignItems: "flex-start",
		width: "100%",
	},
	paymentBankDetailRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	paymentBankDetailCol: {
		width: "25%",
		display: "flex",
		alignItems: "center",
	},
	paymentQRContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	paymentQR: {
		marginTop: "8px",
		width: "160px",
		height: "160px",
	},
});
