import React from "react";
import lyteWink from "../../assets/lyte-wink-blue.gif";
import { Image } from "react-bootstrap";

const Loading = () => {
	return (
		<div className="bg-white mx-auto">
			<Image
				fluid
				className="d-block mx-auto"
				src={lyteWink}
				alt="lyte-wink-blue-loader"
			/>
			<div className="text-center text-color-blue">
				<h4>Loading...</h4>
			</div>
		</div>
	);
};

export default Loading;
